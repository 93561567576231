import { useRef, useContext } from 'preact/hooks';
import classNames from 'classnames';
import { Transition } from 'react-transition-group';

import PollOption from './PollOption/pollOption.component';

import styleSheet from './poll.module.scss';
import styleSheetH2H from '../H2H/h2h.module.scss';

import { getVotePercentages, getFreeText } from '../../polls/poll.helpers';
import { PollContext } from '../../polls/poll.component';
import { POLL_TYPES } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';

const MAX_SELECTION = 1;
const styles = styleSheet.locals || {};
const stylesH2H = styleSheetH2H.locals || {};

/**
 * Component for showing the poll options
 *
 * @returns {Function} <PollOptionsList />
 */
function PollOptionsList() {
    const {
        poll,
        selectedOptions,
        setSelectedOptions,
        pollOpen,
        pollUserAnswer = null,
        startEmbedAnimation,
        submittingVote = false,
        showVotesCounter = false,
        hasOptionImages,
        isSignedIn,
        isGated
    } = useContext(PollContext);

    const { t } = useTranslation();
    const isTrivia = poll?.type === POLL_TYPES.TRIVIA ?? false;
    const isFreeText = poll?.type === POLL_TYPES.FREE_TEXT ?? false;
    const isVotingPoll = poll?.type === POLL_TYPES.POLL ?? false;

    const maxSelection = isTrivia
        ? poll?.questions?.[0]?.correct_options.length
        : isVotingPoll
        ? poll?.questions?.[0]?.options_selection_limit
        : MAX_SELECTION;

    const question = poll.questions[0];

    // If the poll is gated and the user is signed out we never want to show the results as they could
    // be seen under the sign in gate so we always force the 'open' view.
    const showingResults =
        isGated && !isSignedIn
            ? false
            : (!pollOpen || pollUserAnswer) &&
              poll.questions[0]?.options.length > 0;

    const nodeRef = useRef(null);

    const onSelect = (option) => {
        if (!pollOpen || pollUserAnswer || submittingVote) {
            return;
        }

        const found = selectedOptions.findIndex(
            (opt) => opt.text === option.text
        );
        if (found === -1) {
            updateSelectedOptions(option);
        } else {
            const options = [...selectedOptions];
            options.splice(found, 1);
            setSelectedOptions(options);
        }
    };

    /**
     * Sets the selected option as selected.
     * If only max 1 option is allowed replece current selected
     * option with newly selected one.
     *
     * @param {object} option selected poll option
     */
    const updateSelectedOptions = (option) => {
        if (selectedOptions.length >= maxSelection) {
            if (maxSelection === 1) {
                setSelectedOptions([option]);
            }
            return;
        }
        const options = [...selectedOptions, option];
        setSelectedOptions(options);
    };

    /**
     * Returns grid css class depending on how many options there are
     *
     * @param {number} numberOfOptions number of answer options
     * @returns {string} grid class
     */
    const getGridCls = (numberOfOptions) => {
        /* eslint-disable no-magic-numbers */
        if (numberOfOptions < 4) {
            return '';
        }

        if (numberOfOptions === 6 || numberOfOptions === 9) {
            return styles.pollOptionsGridThree;
        }

        if (numberOfOptions % 4 === 1 || numberOfOptions % 4 === 2) {
            return styles.pollOptionsGridFourThreeTwo;
        }

        return styles.pollOptionsGrid;
    };

    let optionsToRender;
    if (showingResults) {
        optionsToRender = isFreeText
            ? getFreeText(poll)
            : getVotePercentages(poll);
    } else {
        optionsToRender = question.options;
    }

    const duration = 300;
    const transitionStyles = {
        entered: { opacity: 1, transform: 'translateY(0)' }
    };
    const versusLabelStyles = {
        entered: { opacity: 1, transform: 'translate(-50%, -50%)' }
    };
    const transitionDelay = 100;
    const transitionLength = 300;
    const optionsVisibleDelay =
        optionsToRender.length * transitionDelay + transitionLength;
    const gridCls = hasOptionImages ? getGridCls(optionsToRender.length) : '';
    const containerCls = classNames(styles.pollOptions, gridCls, {
        [styles.pollOptionsImages]:
            hasOptionImages && poll.type !== POLL_TYPES.H2H,
        [stylesH2H.h2HOptionsContainer]: poll.type === POLL_TYPES.H2H
    });
    const isH2H = poll.type === POLL_TYPES.H2H;
    // eslint-disable-next-line no-magic-numbers
    const optionImageWidth = optionsToRender.length > 3 ? 412 : 840;

    return (
        <ul className={containerCls}>
            {optionsToRender.map((option, index) => {
                const isSelected = !isFreeText
                    ? selectedOptions?.find((opt) => opt.text === option.text)
                    : false;

                const isAnswered = option.option_id === Number(pollUserAnswer);
                const delay = transitionDelay * (index + 1);
                const optionCls = classNames(styles.pollOption, {
                    [styles.pollOptionNoHover]: !pollOpen || pollUserAnswer,
                    [styles.pollOptionSelected]: isSelected || isAnswered,
                    [styles.pollOptionDisabled]:
                        !pollUserAnswer &&
                        !isSelected &&
                        selectedOptions.length === maxSelection &&
                        !isH2H,
                    [styles.pollOptionEnded]: !pollOpen || pollUserAnswer,
                    [styles.pollOptionResults]: showingResults,
                    [styles.pollOptionWinner]:
                        !pollOpen && showingResults && option.winner,
                    [styles.pollOptionBig]:
                        ((pollUserAnswer && pollOpen) || !pollOpen) &&
                        showVotesCounter &&
                        !hasOptionImages,
                    [styles.pollOptionImage]: hasOptionImages && !isH2H
                });

                return (
                    <Transition
                        nodeRef={nodeRef}
                        timeout={duration}
                        in={startEmbedAnimation}
                        key={option.text}
                    >
                        {(state) => (
                            <li
                                className={classNames(styles.pollOptionItem, {
                                    [styles.pollOptionItemH2H]: isH2H,
                                    [styles.pollOptionItemH2HImages]:
                                        isH2H && hasOptionImages,
                                    [styles['poll__option-item--h2h-no-img']]:
                                        isH2H && !hasOptionImages
                                })}
                                ref={nodeRef}
                                style={{
                                    ...transitionStyles[state],
                                    transitionDelay: `${delay}ms`
                                }}
                            >
                                <PollOption
                                    option={option}
                                    optionCls={optionCls}
                                    isSelected={isSelected}
                                    isAnswered={isAnswered}
                                    showingResults={showingResults}
                                    transitionStyles={transitionStyles}
                                    transitionState={state}
                                    optionsVisibleDelay={optionsVisibleDelay}
                                    onSelect={onSelect}
                                    optionImageWidth={optionImageWidth}
                                />
                            </li>
                        )}
                    </Transition>
                );
            })}
            {poll.type === POLL_TYPES.H2H && (
                <Transition
                    nodeRef={nodeRef}
                    timeout={duration}
                    in={startEmbedAnimation}
                >
                    {(state) => (
                        <li
                            className={classNames(stylesH2H.h2HVersus, {
                                [stylesH2H.h2HVersusSmall]: !hasOptionImages
                            })}
                            style={versusLabelStyles[state]}
                        >
                            {t('common.vs')}
                        </li>
                    )}
                </Transition>
            )}
        </ul>
    );
}

PollOptionsList.propTypes = {};

export default PollOptionsList;
