/* eslint-disable id-length */
import PropTypes from 'prop-types';
import styleSheet from '../../scss/input.module.scss';
import { HelperTextBottom } from '.';
import { HelperTextPropTypes } from './helper-text/prop-types';

/**
 * @param {object} props -
 * @returns {Element} -
 */
export function InputWrapperBelow(props) {
    const styles = styleSheet.locals || {};
    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div>
                {props.validationMessage && !props.isValid && (
                    <div
                        className={styles.inputErrorMessage}
                        data-cy="input-wrapper__validation-message"
                    >
                        {props.validationMessage}
                    </div>
                )}

                {props.customElementBottom}
                {props.helperText && (
                    <HelperTextBottom
                        helperText={props.helperText}
                        helperTextPosition={props.helperTextPosition}
                    />
                )}
            </div>
        </>
    );
}

InputWrapperBelow.propTypes = {
    validationMessage: PropTypes.string,
    isValid: PropTypes.bool,
    customElementBottom: PropTypes.node,
    ...HelperTextPropTypes
};
