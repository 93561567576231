/**
 * Dispatch an event to the GTM data layer if available
 *
 * @param {string} eventName event name
 * @param {object} data poll, trivia or prediction data
 * @param {object} extraData any other data
 */
export const sendGTMEvent = (eventName, data, extraData = {}) => {
    if (typeof window.dataLayer === 'undefined' || !window.dataLayer) {
        return;
    }

    window.dataLayer.push({
        event: eventName,
        playbookId: data.prediction_id || data.trivia_id || data.poll_id,
        playbookTitle: data.text,
        playbookType: data.type,
        playbookStatus: data.state,
        ...extraData
    });
};
