/* eslint-disable camelcase */
import { useState } from 'react';
import PropTypes from 'prop-types';
import { TextInput } from '../../components/shared-components/text-input/TextInput';
import { CheckboxInput } from '../../components/shared-components/checkbox-input/CheckboxInput';
import { DateInput } from '../../components/shared-components/date-input/DateInput';
import { NumberInput } from '../../components/shared-components/number-input/NumberInput';
import { EmailInput } from '../../components/shared-components/email-input';
import { getAgeInDateFormat } from '../../components/shared-components/date-input/helper';
import i18nInstance from '../../i18n/index';

import { SelectDropdown } from '../../components/shared-components/select-dropdown/SelectDropdown';

// Hardcoded date validation: greater than 18 years.
// The minimum valid date should come from BE in the future.
const MIN_VALID_AGE = 18;

// Mapper from BE fields to required FE fields data.
const fieldsMapper = {
    consent: {
        type: 'checkbox'
    },
    dob: {
        type: 'date',
        hook_validations: {
            validate: (value) => {
                // Validates if selected birthdate is greather than the valid age.
                return (
                    new Date(value) <=
                    new Date(getAgeInDateFormat(MIN_VALID_AGE))
                );
            }
        },
        input_validations: {
            min: getAgeInDateFormat(MIN_VALID_AGE)
        },
        validationMessage: i18nInstance.t('dataCapture.ageValidationMessage')
    },
    name: { type: 'text' },
    email: {
        type: 'email',
        validationMessage: i18nInstance.t('dataCapture.emailValidationMessage')
    },
    text: { type: 'text' },
    'single select dropdown': { type: 'select' },
    'date picker': { type: 'date' },
    'phone number': {
        type: 'number',
        validationMessage: i18nInstance.t('dataCapture.phoneValidationMessage')
    },
    country: { type: 'select' }
};
/**
 * Transforms the API Fan data form fields into
 * a FE form fields format to be used by the useForm library.
 *
 * @param {Array} fields - list of form fields
 * @returns {object} formFields - Object with the fields data in the required FE format
 */
export function getFromFieldsToViewFormat(fields) {
    return fields.reduce(
        (formFields, field) => ({
            ...formFields,
            [field.view_order]: {
                label: field.label,
                type: fieldsMapper[field.field_name].type ?? 'text',
                fullWidth: true,
                rules: {
                    required: !field.optional,
                    ...(fieldsMapper[field.field_name].hook_validations && {
                        ...fieldsMapper[field.field_name].hook_validations
                    })
                },
                ...(field.placeholder && { placeholder: field.placeholder }),
                ...(field.hint && { helperText: field.hint }),
                ...(field.links?.length && { hyperLinks: field.links }),
                ...(field.options?.length && { options: field.options }),
                ...(fieldsMapper[field.field_name].validationMessage && {
                    validationMessage:
                        fieldsMapper[field.field_name].validationMessage
                }),
                ...(fieldsMapper[field.field_name].input_validations && {
                    validate: fieldsMapper[field.field_name].input_validations
                })
            }
        }),
        {}
    );
}

getFromFieldsToViewFormat.propTypes = {
    fields: PropTypes.shape({
        id: PropTypes.number.isRequired,
        field_name: PropTypes.oneOf([
            'email',
            'dob',
            'name',
            'consent',
            'text',
            'single select dropdown',
            'date picker',
            'phone number',
            'country'
        ]).isRequired,
        label: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        hint: PropTypes.string,
        type: PropTypes.string,
        links: PropTypes.arrayOf(
            PropTypes.shape({
                link_text: PropTypes.string.isRequired,
                link_destinatination: PropTypes.string.isRequired
            })
        ),
        options: PropTypes.arrayOf(
            PropTypes.shape({
                option_text: PropTypes.string.isRequired,
                option_value: PropTypes.string.isRequired
            })
        )
    }).isRequired
};

/**
 * Transforms the FE form fields object into the API Fan data fields format
 *
 * @param {object} form - form object
 * @param {object} formData - formData
 * @returns {Array} fields - List of fields object within the id and value.
 */
export function getFromFieldsToAPIFormat(form, formData) {
    // Define Fields array which will be returned as the payload
    let fields = [];
    const formFields = formData?.fields;

    //  Add value from 'form' to fields Array
    for (let [key, value] of Object.entries(form)) {
        fields = [
            ...fields,
            {
                field_id: key,
                field_label: '',
                value
            }
        ];
    }

    // Add field_type_id & label from 'formData' to fields array
    for (let i = 0; i < fields.length; i++) {
        fields[i].field_id = formFields[i].field_type_id;
        fields[i].field_label = formFields[i].label;

        // If value is a date converts to UTC format YYYY-MM-DDTHH:mm:ssZ
        if (
            (formFields[i].field_name === 'dob' ||
                formFields[i].field_name === 'date picker') &&
            fields[i].value
        ) {
            fields[i].value = new Date(fields[i].value)
                .toISOString()
                .replace(/\.\d+Z/, 'Z');
        }

        // Remove 0 from the start of phone number and any spaces. + - ( ) . are allowed in the input but removed from submission.
        if (formFields[i].field_name === 'phone number' && fields[i].value) {
            fields[i].value = fields[i].value
                .replace(/^0+/, '')
                .replace(/\s/g, '')
                .replace(/-/g, '')
                .replace(/\(/g, '')
                .replace(/\)/g, '')
                .replace(/\./g, '');
        }
    }
    // Removes the unfilled (not required) fields to avoid BE error.
    return fields.filter((field) => field.value !== null);
}

getFromFieldsToAPIFormat.propTypes = {
    form: PropTypes.object.isRequired
};

/**
 * Returns the FE component to render depend on the field type.
 *
 * @param {object} props -
 * @param {string} props.id - field ID
 * @param {Function} props.onChange - onChange callback
 * @param {Function} props.onFocus - onFocus callback
 * @param {Function} props.onBlur - onBlur callback
 * @param {string} props.type - field type
 * @param {object} props.rules - validation rules
 * @param {object} props.control - useForm library object
 * @returns {Element} - Input
 */
export const CustomInput = ({
    id,
    onChange,
    onFocus,
    onBlur,
    type,
    rules,
    control,
    ...rest
}) => {
    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };

    switch (type) {
        case 'text':
            return (
                <TextInput
                    id={id}
                    onChange={(value) => onChange(value)}
                    required={rules?.required}
                    isValid={({ value }) => {
                        if (rest.invalid) {
                            // External validation.
                            return false;
                        }
                        if (value.match(rules.pattern)) {
                            return true;
                        }
                        return false;
                    }}
                    {...rest}
                />
            );

        case 'checkbox':
            return (
                <CheckboxInput
                    id={id}
                    onChange={(value) => onChange(value)}
                    required={rules?.required}
                    {...rest}
                />
            );

        case 'date':
            return (
                <DateInput
                    id={id}
                    onChange={(value) => onChange(value)}
                    onReset={onChange}
                    required={rules?.required}
                    {...rest}
                />
            );

        case 'select':
            return (
                <SelectDropdown
                    id={id}
                    onChange={(value) => onChange(value)}
                    required={rules?.required}
                    {...rest}
                />
            );

        case 'number':
            return (
                <NumberInput
                    id={id}
                    onChange={(value) => onChange(value)}
                    required={rules?.required}
                    isValid={({ value }) => {
                        if (!isFocused) {
                            if (
                                /^[+]*[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s\./0-9]*$/g.test(
                                    value
                                )
                            ) {
                                return true;
                            }
                            return false;
                        }
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    {...rest}
                />
            );

        case 'email':
            return (
                <EmailInput
                    id={id}
                    onChange={(value) => onChange(value)}
                    required={rules?.required}
                    isValid={({ value }) => {
                        if (!isFocused) {
                            if (
                                /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/.test(value)
                            ) {
                                return true;
                            }
                            return false;
                        }
                    }}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    {...rest}
                />
            );
        default:
            return null;
    }
};

CustomInput.propTypes = {
    id: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string.isRequired,
    onFocus: PropTypes.func.isRequired,
    onBlur: PropTypes.func.isRequired,
    rules: PropTypes.shape({
        required: PropTypes.bool.isRequired,
        pattern: PropTypes.instanceOf(RegExp),
        min: PropTypes.number,
        max: PropTypes.number,
        minLength: PropTypes.number,
        maxLength: PropTypes.number,
        validate: PropTypes.oneOfType([PropTypes.func, PropTypes.object])
    }),
    /*
     *  Nest this input inside the parent field defined but only for a certain parent value, where:
     *  - field: parent input name
     *  - value: parent input value that have to match to be displayed
     */
    parent: PropTypes.shape({
        field: PropTypes.string.isRequired,
        value: PropTypes.string.isRequired
    }),
    /*
     *  control object provided by useForm hook. https://react-hook-form.com/docs/useform/control
     */
    control: PropTypes.object,
    /*
     *  If true, will force text input as invalid. Can be used for an external validation.
     */
    invalid: PropTypes.bool
};
