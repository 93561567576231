import { fingerprintAsHash } from './fingerprint';

/**
 * @typedef {object} UserCredentials
 * @property {string} fingerprint - fingerprint hash
 * @property {string} entropyCompnents - base64 encoded source of fingerprint
 */

/**
 * Get User Creds
 *
 * @returns {UserCredentials} User's credentials
 */
export async function getUserCreds() {
    // TODO: remove this method, related files and unistall @fingerprintjs/fingerprintjs package
    const fingerprintInfo = await fingerprintAsHash();

    return {
        ...fingerprintInfo
    };
}

/**
 * Generates or retrieves a UUID from local storage
 *
 * @returns {string} - UUID to be used as the XUserIDHeader
 */
export const getOrCreateEncodedUUID = () => {
    const uuidKey = 'XUserIDHeader';

    // Check if encoded UUID already exists in local storage
    let encodedUserId = localStorage.getItem(uuidKey);

    if (!encodedUserId) {
        // Generate a new UUID if it doesn't exist
        const userId = self.crypto.randomUUID();
        // Encode the UUID to base64
        encodedUserId = btoa(userId);
        // Save the encoded UUID in local storage
        localStorage.setItem(uuidKey, encodedUserId);
    }

    return encodedUserId;
};
