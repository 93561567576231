const TickIcon = (props) => (
    <svg
        width="17"
        height="12"
        viewBox="0 0 17 12"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M5.43942 9.69781L2.12578 6.38648C1.75336 6.01431 1.15175 6.01431 0.77932 6.38648C0.406893 6.75865 0.406893 7.35984 0.77932 7.73201L4.77096 11.7209C5.14339 12.093 5.745 12.093 6.11743 11.7209L16.2207 1.62465C16.5931 1.25249 16.5931 0.651292 16.2207 0.279125C15.8483 -0.0930417 15.2466 -0.0930417 14.8742 0.279125L5.43942 9.69781Z" />
    </svg>
);

export default TickIcon;
