import { getClientHost } from '../helpers/client';

/**
 * GET /forms/{form_id}
 * Fetches Fan Form data from API
 *
 * @param {string} formId - FEK Fan form ID
 * @returns {Promise} -
 */
export const getFormData = (formId) => {
    const DATA_CAPTURE_HOST = `${getClientHost()}${
        process.env.API_PATH_DATA_CAPTURE
    }`;
    const endpoint = `https://${DATA_CAPTURE_HOST}/forms/${formId}`;

    return new Promise((resolve, reject) => {
        fetch(endpoint)
            .then((response) => {
                if (!response.ok) {
                    console.warn(
                        'Playbook - Data Capture form embed unsuccessful',
                        formId
                    );
                    return null;
                }

                return response.json();
            })
            .then((formData) => {
                if (formData && formData.fields) {
                    // Sort fields by view_order
                    formData.fields.sort((a, b) => a.view_order - b.view_order);
                }
                resolve(formData);
            })
            .catch((error) => {
                console.error(
                    'Playbook - Data Capture form embed unsuccessful',
                    error
                );
                reject(error);
            });
    });
};

/**
 * POST /profile
 * Creates fan profile
 *
 * @param {string} payload - form user data
 * @param {string} isGated - is the dc form gated
 * @param {string} authHeader - auth header
 * @returns {Promise} -
 */
export const createProfile = (payload, isGated, authHeader) => {
    const DATA_CAPTURE_HOST = `${getClientHost()}${
        process.env.API_PATH_DATA_CAPTURE
    }`;
    const endpoint = isGated
        ? `https://${DATA_CAPTURE_HOST}/gated/profiles`
        : `https://${DATA_CAPTURE_HOST}/profiles`;

    const headers = {
        'Content-Type': 'application/json'
    };

    if (isGated && authHeader) {
        headers.Authorization = authHeader;
    }

    return new Promise((resolve, reject) => {
        fetch(endpoint, {
            method: 'POST',
            headers,
            body: JSON.stringify(payload)
        })
            .then((response) => {
                return response.json();
            })
            .then((profile) => {
                if (
                    profile.status &&
                    !profile.status.toString().match(/2\d{2}/g)
                ) {
                    // Non sucessful response
                    reject(profile);
                    return;
                }
                resolve(profile);
            })
            .catch((error) => {
                console.error(
                    'Playbook - Data Capture Profile creation unsuccessful.',
                    error
                );
                reject(error);
            });
    });
};
