/* eslint-disable no-undefined */
import { createRef } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18nInstance from '../../i18n';

import styleSheet from './styles/prediction.module.scss';

import PollHero from '../../components/Polling/pollHero.component';

const styles = styleSheet.locals || {};

/**
 * Component for the prediction header
 *
 * @param {object} props prediction option props
 * @param {object} props.prediction prediction option data
 * @returns {Function} <PredictionHeader />
 */
function PredictionHeader({ prediction }) {
    // const predictionContext = useContext(PollContext);
    const question = prediction.questions[0];
    // const editableAttr = predictionContext.isPreview ? { contentEditable: true } : {};
    const titleRef = createRef();

    // const changeEditableData = (field, value) => {
    //     predictionContext.onPreviewEditChange?.({
    //         [field]: value,
    //         questions: [
    //             {
    //                 [field]: value,
    //                 options: []
    //             }
    //         ]
    //     });
    // };

    // useEffect(() => {
    //     if (predictionContext.isPreview) {
    //         enableEditableContent(titleRef.current, 'text', changeEditableData);
    //         enableEditableContent(
    //             descrRef.current,
    //             'description',
    //             changeEditableData
    //         );
    //     }
    // }, []);

    return (
        <div className={styles.predictionHeader}>
            {prediction.hero_image_url && <PollHero poll={prediction} />}

            <div
                className={classNames(styles.predictionProductHeader, {
                    [styles.predictionProductHeaderHero]:
                        prediction.hero_image_url
                })}
            >
                <span
                    className={classNames(
                        styles.predictionLabel,
                        styles.predictionStatus
                    )}
                >
                    {prediction && statusInfo(prediction)}
                </span>
            </div>

            <div
                className={styles.predictionQuestion}
                ref={titleRef}
                data-fek-preview="text"
                // {...editableAttr}
            >
                {question.text}
            </div>
        </div>
    );
}

PredictionHeader.propTypes = {
    prediction: PropTypes.object.isRequired
};

export default PredictionHeader;

const statusInfo = (prediction) => {
    const open = prediction.state === 'open';
    const scheduled = prediction.state === 'scheduled';
    const notOpenYet = prediction.state === 'draft';
    const closed = prediction.state === 'closed';
    const resolved = prediction.state === 'resolved';

    const isScheduledOpen = () => {
        const now = new Date();
        const openDate = new Date(prediction.opens_at);
        return openDate > now;
    };
    const isScheduledClose = () => {
        const now = new Date();
        const closeDate = new Date(prediction.closes_at);
        return closeDate > now;
    };

    if (notOpenYet) {
        return i18nInstance.t('predictions.openingSoon');
    } else if (scheduled && isScheduledOpen()) {
        return `${i18nInstance.t('predictions.openingDate')}: ${new Date(
            prediction.opens_at
        ).toLocaleString(undefined, {
            timeStyle: 'short',
            dateStyle: 'short'
        })}`;
    } else if (open && !isScheduledClose()) {
        return i18nInstance.t('predictions.open');
    } else if (open && isScheduledClose()) {
        return `${i18nInstance.t('predictions.openUntil')}: ${new Date(
            prediction.closes_at
        ).toLocaleString(undefined, {
            timeStyle: 'short',
            dateStyle: 'short'
        })}`;
    } else if (closed) {
        return i18nInstance.t('predictions.closed');
    } else if (resolved) {
        return i18nInstance.t('predictions.resolved');
    }

    return '';
};
