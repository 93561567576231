const RefreshIcon = (props) => (
    <svg
        width="12"
        height="12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M9.7667 2.2333C8.68 1.1467 7.14.52 5.4467.6933 3 .94.9867 2.9266.7134 5.3733c-.3667 3.2333 2.1333 5.96 5.2866 5.96 2.1267 0 3.9534-1.2466 4.8067-3.04.2133-.4466-.1067-.96-.6-.96-.2467 0-.48.1333-.5867.3533-.7533 1.62-2.56 2.6467-4.5333 2.2067C3.6067 9.5666 2.4134 8.36 2.1 6.88 1.54 4.2933 3.5067 2 6 2c1.1067 0 2.0934.46 2.8134 1.1867L7.8067 4.1932c-.42.42-.1267 1.14.4667 1.14h2.3933c.3667 0 .6667-.3.6667-.6667V2.2733c0-.5933-.72-.8933-1.14-.4733l-.4267.4333Z" />
    </svg>
);
export default RefreshIcon;
