const LOCALSTORAGE_FORM_KEY = 'fek-form-id';

/**
 * Save local storage when user has sent form data
 *
 * @param {number} formId ID of the form sent
 */
export const saveFormSubmitted = (formId) => {
    localStorage.setItem(`${LOCALSTORAGE_FORM_KEY}-${formId}`, true);
};

/**
 * Checks localstorage whether user has submitted the form
 *
 * @param {string} formId form ID to check in localstorage
 * @returns {boolean} form submitted
 */
export const isFormSubmitted = (formId) => {
    return !!localStorage.getItem(`${LOCALSTORAGE_FORM_KEY}-${formId}`);
};
