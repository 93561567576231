/* eslint-disable eqeqeq */
/* eslint-disable no-magic-numbers */
/* eslint-disable complexity */
import { useState, useEffect } from 'preact/hooks';
import { createContext } from 'preact';
import PropTypes from 'prop-types';

import styleSheet from './styles/prediction.module.scss';
import SubmitButton from '../../components/shared-components/button/SubmitButton';
import { DataCaptureFormComponent as Form } from 'playbook-data-capture-sdk-web';
import { getVotePercentages } from '../../polls/poll.helpers';
import ShareUrlBox from '../../components/Share/ShareUrlBox.component';
import { getPredictionSubmittedOptions } from '../predictions.helper';
import PredictionVoteScreen from './predictionVoteScreen';
import PredictionSubmittedScreen from './predictionSubmittedScreen';
import PredictionResolvedScreen from './predictionResolvedScreen';
import { useTranslation } from 'react-i18next';

import { getAuthHeader, checkForSignIn } from '../../helpers/authentication';

const styles = styleSheet.locals || {};
export const PredictionContext = createContext({});

/**
 * Component which allows fan to vote for an option
 *
 * @param {object} props prediction option props
 * @param {string} props.prediction prediction data
 * @param {boolean} [props.isPreview] embed is a preview in admin UI
 * @param {string} props.transitionState transition state
 * @param {object} props.settingsData settings object
 * @param {boolean} props.embeddedFormData form object
 * @returns {Function} <PredictionContent />
 */
function PredictionContent({
    prediction,
    isPreview = false,
    transitionState,
    settingsData,
    embeddedFormData
}) {
    const { t } = useTranslation();
    // use the above to control what happens on the results screen of predictions

    const ssoTokenType = settingsData?.token_name;
    const isGated = prediction?.gated;

    const predictionNotOpen =
        prediction.state === 'draft' || prediction.state === 'scheduled';
    const predictionClosed = prediction.state === 'closed';
    const predictionOpen = prediction.state === 'open';
    const predictionResolved = prediction.state === 'resolved';

    const [selectedOptions, setSelectedOptions] = useState([]);
    const [isSubmitted, setIsSubmitted] = useState(false);

    const predictionOptions = getVotePercentages(prediction);
    const transitionStyles = {
        entered: { opacity: 1, transform: 'rotate(0deg)' }
    };

    const submittedOptions = getPredictionSubmittedOptions(
        prediction.prediction_id
    );

    const [isSignedIn, setIsSignedIn] = useState(false);

    const authHeader = getAuthHeader(ssoTokenType);

    const [dataCaptureFormSubmitted, setDataCaptureFormSubmitted] =
        useState(false);

    const [goToPredictionSubmittedOnVote, setGoToPredictionSubmittedOnVote] =
        useState(false);

    const [revealResult, setRevealResult] = useState(false);

    const hasDataCaptureForm = embeddedFormData?.status === 'open';

    if (!hasDataCaptureForm) {
        setGoToPredictionSubmittedOnVote(true);
    }

    const showPredictionResolved = hasDataCaptureForm
        ? revealResult && predictionResolved
        : predictionResolved;

    const userPrediction = localStorage.getItem(
        `fek-prediction-id-${prediction?.prediction_id}`
    );

    const showDataCaptureForm =
        predictionResolved || predictionClosed
            ? embeddedFormData?.status === 'open' &&
              hasDataCaptureForm &&
              !userPrediction &&
              !revealResult
            : embeddedFormData?.status === 'open' &&
              hasDataCaptureForm &&
              isSubmitted;

    useEffect(() => {
        setIsSignedIn(checkForSignIn(ssoTokenType));
    }, []);

    useEffect(() => {
        if (!isSubmitted && submittedOptions) {
            setIsSubmitted(true);
            setSelectedOptions(submittedOptions.split(','));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (isPreview && prediction.previewVoted) {
            setIsSubmitted(true);
            setSelectedOptions([prediction.questions[0].options[1].option_id]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreview, prediction.previewVoted]);

    return (
        <PredictionContext.Provider
            value={{
                prediction,
                predictionOptions,
                predictionOpen,
                correctOptions: prediction.questions[0]?.correct_options || [],
                selectedOptions,
                setSelectedOptions,
                isSubmitted,
                setIsSubmitted,
                transitionStyles,
                isPreview,
                isSignedIn,
                setIsSignedIn,
                authHeader,
                isGated
            }}
        >
            {predictionNotOpen && (
                <div className={styles.predictionMessage}>
                    {t('predictions.openSoonMessage')}
                </div>
            )}

            {predictionClosed && !isSubmitted && (
                <div className={styles.predictionMessage}>
                    {t('predictions.closedMessage')}
                </div>
            )}

            {showDataCaptureForm && !revealResult && (
                <>
                    <Form
                        formData={embeddedFormData}
                        submittedCallback={() => {
                            setDataCaptureFormSubmitted(true);
                        }}
                        predictionId={prediction.prediction_id}
                    />

                    {dataCaptureFormSubmitted && (
                        <SubmitButton
                            onSubmit={() => {
                                setGoToPredictionSubmittedOnVote(true);
                                setRevealResult(true);
                            }}
                            buttonText={
                                revealResult
                                    ? t('predictions.formContinueToResult')
                                    : t('predictions.formContinue')
                            }
                            title="View prediction results"
                        />
                    )}
                </>
            )}

            {predictionOpen && !isSubmitted && (
                <PredictionVoteScreen transitionState={transitionState} />
            )}

            {isSubmitted &&
                !predictionResolved &&
                goToPredictionSubmittedOnVote && <PredictionSubmittedScreen />}

            {showPredictionResolved && <PredictionResolvedScreen />}

            {predictionOpen && (
                <div className={styles.predictionShareContainer}>
                    {t('predictions.sharePrediction')}
                    <ShareUrlBox
                        pollId={prediction.prediction_id}
                        style={{
                            ...transitionStyles[transitionState],
                            transitionDelay: '100ms'
                        }}
                        hideUrlMobile={true}
                    />
                </div>
            )}
        </PredictionContext.Provider>
    );
}

PredictionContent.propTypes = {
    prediction: PropTypes.object.isRequired,
    isPreview: PropTypes.bool,
    transitionState: PropTypes.string.isRequired,
    settingsData: PropTypes.object,
    embeddedFormData: PropTypes.object
};

export default PredictionContent;
