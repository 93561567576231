import { InputWrapper, InputWrapperPublicPropTypes } from '../input-wrapper';
import { InputPropTypes, useInput } from '../hooks/useInput';

export const EmailInput = (props) => {
    const { inputProps, inputWrapperProps } = useInput(props);
    return (
        <InputWrapper {...inputWrapperProps}>
            <input
                type="email"
                {...inputProps}
                data-cy="email-input"
                data-testid="email-input"
                className={inputProps.className}
            />
        </InputWrapper>
    );
};

EmailInput.propTypes = {
    ...InputPropTypes,
    ...InputWrapperPublicPropTypes
};
