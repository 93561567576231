/* eslint-disable eqeqeq */
/* eslint-disable no-magic-numbers */
import { useContext, useMemo, useState } from 'preact/hooks';
import Confetti from 'react-confetti';

import PredictionSelected from './predictionSelected';
import { PredictionContext } from './predictionContent';

import {
    getCorrectPercentage,
    getPercentageAsString
} from '../predictions.helper';
import Button from '../../components/shared-components/button/Button';
import { useTranslation } from 'react-i18next';

import styleSheet from './styles/prediction.module.scss';

const styles = styleSheet.locals || {};

/**
 * Component which shows the prediction results
 *
 * @returns {Function} <PredictionResolvedScreen />
 */
function PredictionResolvedScreen() {
    const { t } = useTranslation();
    const {
        prediction,
        predictionOptions,
        selectedOptions,
        correctOptions,
        isSubmitted
    } = useContext(PredictionContext);
    const [revealResults, setRevealResults] = useState(false);
    const [confettiPlaying, setConfettiPlaying] = useState(false);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isUserCorrect = () => {
        if (selectedOptions.length > 0) {
            let correct = 0;
            selectedOptions.forEach((selectedOption) => {
                if (
                    correctOptions.find(
                        (option) =>
                            option.option_id.toString() === selectedOption
                    )
                ) {
                    correct++;
                }
            });
            return correct === selectedOptions.length;
        }

        return false;
    };

    const userPredictionCorrect = useMemo(
        () => isUserCorrect(),
        [isUserCorrect]
    );

    /**
     * Gets the correct options the user hasn't voted for
     *
     * @returns {Array} other correct options
     */
    const getOtherCorrectOptions = () => {
        const noUserOptions = predictionOptions.filter(
            (option) => !selectedOptions.includes(option.option_id.toString())
        );
        return noUserOptions.filter((option) =>
            correctOptions.some(
                (correct) => correct.option_id === option.option_id
            )
        );
    };

    const onRevealClick = () => {
        setRevealResults(true);
        setConfettiPlaying(true);
        setTimeout(() => setConfettiPlaying(false), 2500);
    };

    return (
        <>
            {!isSubmitted && (
                <div className={styles.predictionResults}>
                    <p className={styles.predictionSelectionText}>
                        {t('predictions.result')}
                    </p>

                    {correctOptions.map((item, i) => {
                        return (
                            <div key={i}>
                                <PredictionSelected
                                    option={item}
                                    isOpen={false}
                                />
                            </div>
                        );
                    })}

                    <p className={styles.predictionResultsFooterText}>
                        <span>
                            {getPercentageAsString(
                                getCorrectPercentage(prediction)
                            )}
                        </span>{' '}
                        {t('predictions.numFansPredicted')}
                    </p>
                </div>
            )}

            {isSubmitted && !revealResults && (
                <div className={styles.predictionResults}>
                    <p className={styles.predictionSelectionText}>
                        {t('predictions.selectionText')}
                    </p>

                    {selectedOptions.map((item, i) => {
                        const selectedIndex = predictionOptions.findIndex(
                            (opt) =>
                                opt.option_id == item ||
                                opt.option_id === item.option_id
                        );

                        return (
                            <div key={i}>
                                <PredictionSelected
                                    option={predictionOptions[selectedIndex]}
                                    isOpen={false}
                                />
                            </div>
                        );
                    })}

                    <div className={styles.predictionResultsButton}>
                        <Button
                            text={t('predictions.resultButton')}
                            theme="secondary"
                            onClick={onRevealClick}
                        />
                    </div>
                </div>
            )}

            {isSubmitted && revealResults && (
                <div>
                    {userPredictionCorrect && (
                        <Confetti
                            numberOfPieces={confettiPlaying ? 500 : 0}
                            tweenDuration={100}
                            initialVelocityY={20}
                            colors={[
                                '#FD06FF',
                                '#7701FF',
                                '#FE7F82',
                                '#380097'
                            ]}
                        />
                    )}
                    <div className={styles.predictionResultsColumns}>
                        <div>
                            <p className={styles.predictionSelectionText}>
                                {t('predictions.selectionText')}
                            </p>
                            {selectedOptions.map((item, i) => {
                                const selectedIndex =
                                    predictionOptions.findIndex(
                                        (opt) =>
                                            opt.option_id == item ||
                                            opt.option_id === item.option_id
                                    );

                                return (
                                    <div key={i}>
                                        <PredictionSelected
                                            option={
                                                predictionOptions[selectedIndex]
                                            }
                                            isOpen={false}
                                            icon={
                                                userPredictionCorrect
                                                    ? 'winner'
                                                    : 'cross'
                                            }
                                        />
                                    </div>
                                );
                            })}
                        </div>
                        {!userPredictionCorrect && (
                            // if user prediction is incorrect, show correct options
                            <div>
                                <p className={styles.predictionSelectionText}>
                                    {t('predictions.correctAnswer')}
                                </p>
                                {getOtherCorrectOptions().map((item, i) => {
                                    return (
                                        <div key={i}>
                                            <PredictionSelected
                                                option={item}
                                                isOpen={false}
                                                icon={'tick'}
                                            />
                                        </div>
                                    );
                                })}
                            </div>
                        )}
                        {userPredictionCorrect &&
                            selectedOptions.length !==
                                correctOptions.length && (
                                // if user prediction is correct and there are more correct options than the user selected
                                <div>
                                    <p
                                        className={
                                            styles.predictionSelectionText
                                        }
                                    >
                                        {t('predictions.otherCorrect')}
                                    </p>
                                    {getOtherCorrectOptions().map((item, i) => {
                                        return (
                                            <div key={i}>
                                                <PredictionSelected
                                                    option={item}
                                                    isOpen={false}
                                                    icon={'tick'}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                    </div>

                    <p className={styles.predictionResultsFooterText}>
                        {userPredictionCorrect
                            ? `${t(
                                  'predictions.correctAnswerMessage_1'
                              )} ${getPercentageAsString(
                                  getCorrectPercentage(prediction)
                              )} ${t('predictions.correctAnswerMessage_2')}`
                            : `${getPercentageAsString(
                                  getCorrectPercentage(prediction)
                              )} ${t('predictions.wrongAnswerMessage')}`}
                    </p>
                </div>
            )}
        </>
    );
}

export default PredictionResolvedScreen;
