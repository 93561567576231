/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getImageUrl } from '../../helpers/images';

import styleSheet from './styles/prediction.module.scss';
import styleSheetPoll from '../../components/Polling/poll.module.scss';
import EditIcon from '../../assets/icons/EditIcon';
import WinnerIcon from '../../assets/icons/WinnerIcon';
import TickIcon from '../../assets/icons/TickIcon';
import CrossIcon from '../../assets/icons/CrossIcon';

const styles = styleSheet.locals || {};
const stylesPoll = styleSheetPoll.locals || {};

const ICON_TYPES = {
    winner: WinnerIcon,
    tick: TickIcon,
    cross: CrossIcon
};

/**
 * Component for a single prediction option selected
 *
 * @param {object} props poll option props
 * @param {object} props.option poll option data
 * @param {boolean} props.isOpen if prediction is still open
 * @param {Function} props.onEdit callback when option has been selected by user
 * @param {string} props.icon icon type to display
 * @returns {Function} <PredictionSelected />
 */
function PredictionSelected({ option, isOpen, onEdit, icon }) {
    const hasOptionImages = option.image_url || option.image_data_preview;

    if (!option) {
        return '';
    }

    const Icon = icon && ICON_TYPES[icon];

    return (
        <button
            className={classNames(
                styles.predictionSelectedOption,
                stylesPoll.pollOption,
                {
                    [styles.predictionSelectedOptionImage]: hasOptionImages,
                    [styles.predictionSelectedOptionClosed]: !isOpen
                }
            )}
            title={option.text}
            onClick={isOpen && onEdit}
        >
            {hasOptionImages && (
                <img src={getImageUrl(option.image_url, 840)} />
            )}

            <div className={styles.predictionSelectedOptionContent}>
                <p className={styles.predictionSelectedOptionText}>
                    {option.text}
                </p>
                {isOpen && <EditIcon />}
                {icon && (
                    <div className={styles.predictionOptionIcon}>
                        <Icon />
                    </div>
                )}
            </div>
        </button>
    );
}

PredictionSelected.propTypes = {
    option: PropTypes.object.isRequired,
    isOpen: PropTypes.bool,
    onEdit: PropTypes.func,
    icon: PropTypes.string
};

export default PredictionSelected;
