export const RESULTS_VISIBILITY = {
    AFTER_SUBMISSION: 'after_fan_submission',
    AFTER_CLOSING: 'after_poll_closes',
    NEVER: 'never'
};

/**
 * Checks if given poll is open to vote
 *
 * @param {object} poll poll data
 * @returns {boolean} poll is open
 */
export const isOpen = (poll) => {
    return poll.state === 'open';
};

/**
 * Check is given poll is not open for voting yet
 *
 * @param {object} poll poll data
 * @returns {boolean} poll is scheduled
 */
export const isScheduled = (poll) => {
    const now = new Date();
    const openDate = new Date(poll.opens_at);
    return poll.state === 'draft' || openDate > now;
};

/**
 * Checks localstorage whether user has voted
 *
 * @param {number} pollId ID of the poll
 * @returns {boolean} user vote
 */
export const getUserVote = (pollId) => {
    const saved = localStorage.getItem(pollId);

    return saved || null;
};

/**
 * Counts the votes for each option to get the total.
 *
 * @param {object} poll - poll data
 * @returns {number} total amount of votes
 */
export const getTotalVotes = (poll) => {
    return poll.questions[0].options.reduce((prev, next) => {
        return prev + next.times_selected;
    }, 0);
};

/**
 * Calculates the vote percentage for each option
 * and looks for the winner option.
 *
 * @param {object} poll poll data
 * @returns {Array} options with percentage and winner added
 */
export const getVotePercentages = (poll) => {
    const ONE_HUNDRED = 100;
    const options = poll.questions[0].options;
    const totalVotes = getTotalVotes(poll);
    const highestVote = options.reduce((prev, current) => {
        return prev.times_selected > current.times_selected ? prev : current;
    });
    const allWinners = options.filter(
        (option) => option.times_selected === highestVote.times_selected
    );
    allWinners.forEach((option) => {
        option.winner = true;
    });

    return options.map((option) => {
        if (option.times_selected) {
            // eslint-disable-next-line no-magic-numbers
            option.percentage = (
                (ONE_HUNDRED / totalVotes) *
                option.times_selected
            ).toFixed(0);
        } else {
            option.percentage = 0;
        }
        return option;
    });
};

/**
 * Calculates the vote percentage for each option
 * and looks for the winner option.
 *
 * @param {object} poll poll data
 * @returns {Array} array containing the correct answer
 */
export const getFreeText = (poll) => {
    const ONE_HUNDRED = 100;
    const options = poll.questions[0].options;
    const totalVotes = getTotalVotes(poll);
    const highestVote = options.reduce((prev, current) => {
        return prev.times_selected > current.times_selected ? prev : current;
    });
    const allWinners = options.filter(
        (option) => option.times_selected === highestVote.times_selected
    );
    allWinners.forEach((option) => {
        option.winner = true;
    });

    const correctOptions = options.filter(
        (option) => option.text !== 'incorrect'
    );

    return correctOptions.map((option) => {
        if (option.times_selected) {
            // eslint-disable-next-line no-magic-numbers
            option.percentage = (
                (ONE_HUNDRED / totalVotes) *
                option.times_selected
            ).toFixed(0);
        } else {
            option.percentage = 0;
        }
        return option;
    });
};

/**
 * Gets the option of a poll by its ID
 *
 * @param {object} poll poll data
 * @param {number} optionId poll option ID
 * @returns {object} poll option
 */
export const getOptionById = (poll, optionId) => {
    return poll.questions[0].options.find(
        (option) => option.option_id === Number(optionId)
    );
};

/**
 * Checks if the poll options are images
 *
 * @param {object} poll poll data
 * @returns {boolean} has images as option
 */
export const pollHasOptionImages = (poll) => {
    const options = poll?.questions[0]?.options;
    if (!options) {
        return false;
    }
    return (
        options.filter((opt) => opt.image_url || opt.image_data_preview)
            .length > 0
    );
};

/**
 * Sort poll/trivia options by view_order property as they are not
 * always returned in correct order
 *
 * @param {object} poll - poll data
 */
export const sortPollOptions = (poll) => {
    poll.questions?.[0]?.options?.sort((a, b) => a.view_order - b.view_order);
};
