import PropTypes from 'prop-types';
import classNames from 'classnames';

import styleSheet from '../../scss/input.module.scss';

/**
 * @param {object} props -
 * @returns {Element} - <Label>
 */
export function Label(props) {
    const styles = styleSheet.locals || {};

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <label
                htmlFor={props.id}
                className={classNames(styles.inputLabel, {
                    [styles.inputLabelHidden]: props.hideLabel
                })}
                data-cy="inputLabel"
            >
                {props.label}
                {props.required && !props.hideLabel && (
                    <span className={styles.inputLabelRequired}> *</span>
                )}
            </label>
        </>
    );
}

export const LabelPropTypes = {
    id: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    required: PropTypes.bool,
    hideLabel: PropTypes.bool
};

Label.propTypes = LabelPropTypes;
