import PropTypes from 'prop-types';

import { useTranslation } from 'react-i18next';

import styleSheet from '../../Polling/poll.module.scss';
import SignInIcon from '../icons/SignInIcon';
import RefreshIcon from '../icons/RefreshIcon';
const styles = styleSheet.locals || {};
/**
 * SignInGate component
 *
 * @param {object} props
 * @param {string} props.type transition state
 * @param {boolean} props.isResults is it being rendered in results state
 * @returns {Function} <SignInGate />
 */
function SignInGate({ type, isResults = false }) {
    const { t } = useTranslation();

    const getTitleKey = () => {
        if (isForm) {
            return 'signInGate.signInForm';
        }
        if (isPrediction) {
            return 'signInGate.signInPrediction';
        }
        if (isTrivia) {
            return 'signInGate.signInTrivia';
        }
        if (isResults) {
            return 'signInGate.signInPollResults';
        }
        return 'signInGate.signInPoll';
    };

    const handleRefreshClick = () => {
        window.location.reload();
    };

    const handleBtnClick = () => {
        if (window?.Playbook?.setSSORedirect) {
            window.Playbook.setSSORedirect();
        }

        if (window?.Playbook?.RegisterLink) {
            window.location.href = window.Playbook.RegisterLink;
        } else if (window?.Playbook?.SignInLink) {
            window.location.href = window.Playbook.SignInLink;
        } else {
            // fallback to the wta login page
            window.location.href = '/login';
        }
    };

    const isPrediction = type === 'prediction' ?? false;
    const isForm = type === 'form' ?? false;
    const isTrivia = type === 'trivia' ?? false;

    const isWTA = window.location.host.includes('wta');
    const signInMessage = t(getTitleKey());

    return (
        <div className={isForm ? styles.pollGateForm : styles.pollGate}>
            <div className={styles.pollGateText}>
                <div className={styles.pollGateIcon}>
                    <SignInIcon />
                </div>

                {isWTA ? (
                    <button
                        className={styles.pollGateBtn}
                        onClick={handleBtnClick}
                        title={signInMessage}
                    >
                        {signInMessage}
                    </button>
                ) : (
                    <div className={styles.pollGateTitle}>{signInMessage}</div>
                )}

                <div className={styles.pollGateSubtitle}>
                    {t('signInGate.alreadySignedIn')}
                    <div
                        onClick={handleRefreshClick}
                        className={styles.pollGateRefreshWrapper}
                    >
                        <span className={styles.pollGateRefresh}>
                            {t('signInGate.refreshBrowser')}
                        </span>
                        <span className={styles.pollGateRefreshIcon}>
                            <RefreshIcon />
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
}
SignInGate.propTypes = {
    type: PropTypes.string,
    isResults: PropTypes.bool
};
export default SignInGate;
