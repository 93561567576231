import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import TickIcon from '../../shared-components/icons/TickIcon';
import CrossIcon from '../../shared-components/icons/CrossIcon';

import { getLetterForOption } from '../../../helpers/utils';

import styleSheet from '../poll.module.scss';
import { PollContext } from '../../../polls/poll.component';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for a single poll option
 *
 * @param {object} props poll option props
 * @param {object} props.option poll option data
 * @param {boolean} props.showingResults poll is showing results
 * @param {object} props.transitionStyles transition styles
 * @param {string} props.transitionState transition state
 * @param {number} props.optionsVisibleDelay delay for visibility transition
 * @param {object} props.optionTextRef option text node reference
 * @param {boolean} props.showCrossIcon cross icon
 * @returns {Function} <TriviaTextOption />
 */
function TriviaTextOption({
    option,
    showingResults = false,
    transitionStyles,
    transitionState,
    optionsVisibleDelay,
    optionTextRef,
    showCrossIcon = false
}) {
    const { t } = useTranslation();
    const { poll, showVotesCounter, isPreview } = useContext(PollContext);
    const editableAttr = isPreview ? { contentEditable: true } : {};
    const barTransitionStyles = {
        entered: { maxWidth: '100%' }
    };

    let letter;

    poll?.questions?.[0]?.options.forEach((optionItem, index) => {
        if (optionItem?.text === option?.text) {
            letter = getLetterForOption(index);
        }
    });

    const isCorrectOption = poll?.questions?.[0]?.correct_options?.some(
        (correctOption) => correctOption.option_id === option.option_id
    );

    return (
        <div
            className={classNames(styles.pollOptionText, {
                [styles.pollOptionTextLeftJustify]: !showingResults,
                [styles.pollOptionBorder]: showCrossIcon
            })}
        >
            {showingResults && (
                <div
                    className={styles.pollOptionBar}
                    style={{
                        width: `${option.percentage}%`,
                        ...barTransitionStyles[transitionState],
                        transitionDelay: `${optionsVisibleDelay}ms`
                    }}
                />
            )}
            {!showingResults && !showCrossIcon && (
                <div className={styles.pollTriviaLetter}>{letter}</div>
            )}
            <span
                className={classNames(
                    styles.pollOptionLabel,
                    styles.pollOptionLabelTrivia
                )}
                {...editableAttr}
                ref={optionTextRef}
            >
                {showingResults && isCorrectOption && (
                    <span className={styles.pollIconWrap}>
                        <TickIcon />
                    </span>
                )}

                {(showingResults && !isCorrectOption) || showCrossIcon ? (
                    <span className={styles.pollIconWrap}>
                        <CrossIcon />
                    </span>
                ) : null}

                {option.text}
            </span>
            {showingResults && (
                <div className={styles.pollOptionRight}>
                    <div
                        className={styles.pollOptionData}
                        style={{
                            ...transitionStyles[transitionState],
                            transitionDelay: `${optionsVisibleDelay}ms`
                        }}
                    >
                        <div className={styles.pollOptionPercentage}>
                            {option.percentage}%
                        </div>
                        {showVotesCounter && (
                            <div className={styles.pollOptionCounter}>
                                {option.times_selected} {t('trivia.fans')}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
}

TriviaTextOption.propTypes = {
    option: PropTypes.object.isRequired,
    showingResults: PropTypes.bool,
    transitionStyles: PropTypes.object,
    transitionState: PropTypes.string,
    optionsVisibleDelay: PropTypes.number,
    isSelected: PropTypes.bool,
    isAnswered: PropTypes.bool,
    optionTextRef: PropTypes.object,
    showCrossIcon: PropTypes.bool
};

export default TriviaTextOption;
