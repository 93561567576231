import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import enTranslations from './languages/en.json';
import arTranslations from './languages/ar.json';

const resources = {
    en: {
        translation: enTranslations
    },
    ar: {
        translation: arTranslations
    }
};
const i18nInstance = i18n.createInstance();

i18nInstance.use(initReactI18next).init({
    resources,
    lng: Object.hasOwn(resources, document.documentElement.lang)
        ? document.documentElement.lang
        : 'en', // default language
    interpolation: {
        escapeValue: false // react already safes from xss
    }
});
export default i18nInstance;
