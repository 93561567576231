import { HelperTextPropTypes } from './prop-types';
import styleSheet from '../../../scss/input.module.scss';

/**
 * @param {object} props -
 * @returns {Element} - <HelperTextTop />
 */
export function HelperTextTop(props) {
    const styles = styleSheet.locals || {};
    if (
        !props.helperTextPosition?.startsWith('top-') ||
        props.customElementTop
    ) {
        return null;
    }

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <span
                className={styles.inputHelperText}
                data-cy={`input-wrapper__helper-text input-wrapper__helper-text-position--${props.helperTextPosition}`}
            >
                {props.helperText}
            </span>
        </>
    );
}

HelperTextTop.propTypes = HelperTextPropTypes;
