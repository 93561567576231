import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InputWrapper, InputWrapperPropTypes } from '../input-wrapper';
import { InputPropTypes, useInput } from '../hooks/useInput';
import styleSheet from '../scss/input.module.scss';
import { isValidDateInRange } from './helper';
import { useTranslation } from 'react-i18next';

export const DateInput = (props) => {
    const styles = styleSheet.locals || {};
    const { t } = useTranslation();

    const { inputProps, inputWrapperProps, reset } = useInput({
        ...props,
        isValid: props.validate
            ? ({ value }) =>
                  isValidDateInRange(
                      value,
                      props.validate.min,
                      props.validate.max
                  )
            : null
    });

    return (
        <InputWrapper
            {...inputWrapperProps}
            isValid={inputProps.value === '' ? true : inputWrapperProps.isValid}
            className={classNames(
                inputWrapperProps.className,
                styles.inputDate,
                inputProps.value === '' ? styles.inputEmpty : ''
            )}
            customElementTop={
                <a
                    href=""
                    onClick={(event) => {
                        event.preventDefault();
                        reset();
                    }}
                    className={styles.inputDateReset}
                >
                    {t('common.dateInputClear')}
                </a>
            }
        >
            <input type="date" pattern="\d{4}-\d{2}-\d{2}" {...inputProps} />
        </InputWrapper>
    );
};

DateInput.propTypes = {
    ...InputPropTypes,
    ...InputWrapperPropTypes,
    /**
     * The minimum value displayed in the picker.
     */
    min: PropTypes.string,
    /**
     * The maximum value displayed in the picker.
     */
    max: PropTypes.string,
    /**
     * Validation values
     */
    validate: PropTypes.shape({
        // Minimum valid value
        min: PropTypes.string,
        // Maximum valid value
        max: PropTypes.string
    }),
    /**
     * Validation message to show when input is invalid.
     */
    validationMessage: PropTypes.string
};
