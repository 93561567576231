import { useContext, useRef } from 'preact/hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { noop } from '../../helpers/noop';

import Countdown from '../Countdown/Countdown.component';
import { TopThreePollContext } from './TopThreePoll.component';

import styleSheet from './styles/TopThreeOption.module.scss';
import SubmitButton from '../shared-components/button/SubmitButton';
import TopThreeSelection from './TopThreeSelection.component';
import { CarouselControls } from '../shared-components/carousel-controls/CarouselControls.component';
import { TopThreeOption } from './TopThreeOption.component';
import { pollHasOptionImages } from '../../polls/poll.helpers';
import PollTotalVotes from '../PollTotalVotes/pollTotalVotes.component';
import SignInGate from '../shared-components/gate/SignInGate.component';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component which allows fan to vote for an option
 *
 * @param {object} props poll option props
 * @param {string} props.transitionState transition state
 * @returns {Function} <TopThreeVoteScreen />
 */
export function TopThreeVoteScreen({ transitionState }) {
    const {
        poll,
        selectedThreeOptions,
        submittingVote = false,
        transitionStyles,
        transitionDelay,
        onVoteSubmit = noop,
        totalVotes = 0,
        onCountdownEnd,
        showVotesCounter,
        isSignedIn,
        isGated
    } = useContext(TopThreePollContext);
    const { t } = useTranslation();

    const elementRef = useRef(null);
    const nodeRef = useRef(null);

    const pollOptions = poll?.questions[0].options;
    const hasImages = pollHasOptionImages(poll);

    const isClosed = poll?.state === 'closed';

    const indexToOrdinalNumber = (index) => {
        switch (index) {
            case 0:
                return t('common.firstAbbreviation');
            case 1:
                return t('common.secondAbbreviation');
            case 2:
                return t('common.thirdAbbreviation');
            default:
                return '';
        }
    };

    const hasEmptyOption = () => {
        return selectedThreeOptions?.includes(null);
    };

    return (
        <div
            className={
                isGated
                    ? styles.optionsSectionWrapperGated
                    : styles.optionsSectionWrapper
            }
        >
            {isGated && !isSignedIn && <SignInGate isResults={isClosed} />}
            <TopThreeSelection hasImages={hasImages} />

            <div className={styles.optionsSectionContainer} ref={elementRef}>
                {pollOptions.map((item, i) => {
                    const hasOptionImages =
                        item.image_url || item.image_data_preview;

                    let selectedIndex;
                    const isSelected = selectedThreeOptions?.find(
                        (selectedOpt) =>
                            selectedOpt &&
                            (selectedOpt.option_id === item.option_id ||
                                // eslint-disable-next-line eqeqeq
                                selectedOpt == item.option_id)
                    );

                    if (isSelected) {
                        selectedIndex = selectedThreeOptions.findIndex(
                            (opt) => opt?.option_id === isSelected.option_id
                        );
                    }
                    return (
                        <div
                            key={i}
                            className={classNames(styles.optionsSectionItem, {
                                [styles.optionsSectionItemImage]:
                                    hasOptionImages
                            })}
                            ref={nodeRef}
                        >
                            <TopThreeOption
                                option={item}
                                transitionState={transitionState}
                                transitionStyles={transitionStyles}
                                // optionsVisibleDelay={optionsVisibleDelay}
                                isSelected={!!isSelected}
                                selectedIndex={selectedIndex}
                                isDisabled={!hasEmptyOption}
                            />
                        </div>
                    );
                })}
            </div>
            <CarouselControls elementRef={elementRef} />

            {hasEmptyOption() && (
                <div className={styles.optionsSectionRemainingText}>
                    {t('polling.select')}{' '}
                    <span className={styles.optionsSectionRemainingTextBold}>
                        {selectedThreeOptions
                            .map((item, index) => {
                                return item === null
                                    ? indexToOrdinalNumber(index)
                                    : '';
                            })
                            .filter((item) => item)
                            .join(', ')}
                    </span>{' '}
                    {t('polling.selectToContinue')}{' '}
                </div>
            )}

            <div
                className={styles.pollSubmitContainer}
                style={{
                    ...transitionStyles?.[transitionState],
                    transitionDelay: `${transitionDelay}ms`
                }}
            >
                <SubmitButton
                    onSubmit={onVoteSubmit}
                    disabled={hasEmptyOption()}
                    title="Submit Vote"
                    buttonText={t('polling.saveButton')}
                    loading={submittingVote}
                />

                {showVotesCounter && (
                    <PollTotalVotes
                        votes={totalVotes}
                        style={{
                            ...transitionStyles[transitionState]
                        }}
                    />
                )}
            </div>

            {poll?.closes_at && (
                <Countdown
                    text="Voting closes in"
                    datetime={poll.closes_at}
                    onCountdownEnd={onCountdownEnd}
                    closes={true}
                />
            )}
        </div>
    );
}

TopThreeVoteScreen.propTypes = {
    transitionState: PropTypes.string.isRequired
};
