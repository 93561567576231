const ErrorIcon = (props) => (
    <svg
        width="16"
        height="16"
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                d="M0 0L16 0 16 16 0 16z"
                transform="translate(-1284 -200) translate(156 136) translate(974 30) translate(154 34)"
            />
            <path
                fill="#DE350D"
                d="M8 1.333C4.32 1.333 1.333 4.32 1.333 8c0 3.68 2.987 6.667 6.667 6.667 3.68 0 6.667-2.987 6.667-6.667 0-3.68-2.987-6.667-6.667-6.667zm0 7.334c-.367 0-.667-.3-.667-.667V5.333c0-.366.3-.666.667-.666.367 0 .667.3.667.666V8c0 .367-.3.667-.667.667zm.667 2.666H7.333V10h1.334v1.333z"
                transform="translate(-1284 -200) translate(156 136) translate(974 30) translate(154 34)"
            />
        </g>
    </svg>
);

export default ErrorIcon;
