import PropTypes from 'prop-types';
import { useState } from 'preact/hooks';

import styleSheet from './scss/share-url-box.module.scss';
import TickIcon from '../shared-components/icons/TickIcon';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};
const COPY_MESSAGE_TIMEOUT = 2000;

/**
 * Renders a shareable URL and copy button.
 *
 * @param {object} props - component props
 * @param {string} props.pollId - poll ID
 * @param {object} [props.style] - css styles
 * @param {boolean} [props.lightTheme] - use light theme colours
 * @param {boolean} [props.hideUrlMobile] - hide the URL on mobile
 * @returns {Function} <Share />
 */
const ShareUrlBox = function ({
    pollId,
    style = {},
    lightTheme = false,
    hideUrlMobile = false
}) {
    const { t } = useTranslation();
    const copyUrl = `${window.location.origin}${window.location.pathname}#fek-${pollId}`;
    const [hasCopied, setHasCopied] = useState(false);

    const handleCopyClick = () => {
        navigator.clipboard.writeText(copyUrl).then(() => {
            setHasCopied(true);
            setTimeout(() => {
                setHasCopied(false);
            }, COPY_MESSAGE_TIMEOUT);
        });
    };

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div
                className={classNames(styles.share, {
                    [styles.shareLight]: lightTheme,
                    [styles.shareHideUrlMobile]: hideUrlMobile
                })}
                style={style}
            >
                <div className={styles.shareUrl}>{copyUrl}</div>

                {!hasCopied && (
                    <button
                        className={styles.shareButton}
                        onClick={handleCopyClick}
                    >
                        {t('button.copyLink')}
                    </button>
                )}

                {hasCopied && (
                    <div className={styles.shareSuccess}>
                        <TickIcon /> {t('button.shareCopied')}
                    </div>
                )}
            </div>
        </>
    );
};

ShareUrlBox.propTypes = {
    pollId: PropTypes.string.isRequired,
    style: PropTypes.object,
    lightTheme: PropTypes.bool,
    hideUrlMobile: PropTypes.bool
};

export default ShareUrlBox;
