import { getMappedClass, getClass } from '../../../helpers/styles';
import styleSheet from '../button/scss/button.module.scss';
const styles = styleSheet.locals || {};

const upperCase = (str) => {
    return str ? str.toUpperCase() : str;
};

const lowerCase = (str) => {
    return str ? str.toLowerCase() : str;
};

const titleCase = (str) => {
    const firstLetterRx = /(^|\s)[a-z]/g;
    return str ? str.replace(firstLetterRx, upperCase) : str;
};

const lowerFirstLetter = (str) => {
    return str ? `${lowerCase(str.charAt(0))}${str.slice(1)}` : str;
};

const sizes = {
    large: 'Lg',
    medium: 'Md',
    small: 'Sm',
    extraSmall: 'Xs'
};

const requireWhiteAccent = (theme) => {
    const matches = ['primary', 'danger', 'dark'];
    return !theme || matches.some((match) => match === lowerCase(theme));
};

const getClasses = (props, whiteAccent) => {
    return {
        buttonIcon: !props.text ? styles.btnIcon : '',
        noText: !props.text && !props.children ? styles.btnNoText : '',
        buttonSize: getMappedClass(
            styles,
            sizes,
            lowerFirstLetter(props.size),
            'large',
            'btn'
        ),
        buttonTheme:
            getClass(styles, `btn${titleCase(props.theme)}`) ||
            styles.btnPrimary,
        displayFlex:
            props.LeftIconComponent ||
            props.RightIconComponent ||
            props.caret ||
            !props.text
                ? styles.btnFlex
                : '',
        caretWhite: whiteAccent ? styles.btnCaretWhite : '',
        className: props.className || ''
    };
};

export { getClasses, requireWhiteAccent };
