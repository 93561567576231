/* eslint-disable camelcase */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { InputPropTypes, useInput } from '../hooks/useInput';
import ExternalLinkIcon from '../icons/ExternalLinkIcon';

import styleSheet from '../scss/input.module.scss';

export const CheckboxInput = (props) => {
    const styles = styleSheet.locals || {};

    const { inputProps } = useInput(
        {
            ...props,
            inputClassName: classNames(
                props.inputClassName,
                styles.inputElement
            ),
            onChange: (checked) => {
                props.onChange && props.onChange(checked);
            }
        },
        'checked'
    );

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div
                className={classNames(styles.input, styles.inputCheckbox, {
                    [styles.inputNoMaxWidth]: props.fullWidth
                })}
            >
                <input {...inputProps} type="checkbox" value="" />
                <label
                    htmlFor={inputProps.id}
                    className={classNames(
                        styles.inputLabel,
                        styles.inputLabelFontNormal
                    )}
                    onClick={(evt) => {
                        if (props.readOnly) {
                            evt.preventDefault();
                        }
                    }}
                >
                    <span className={styles.inputCustomCheckbox} />
                    <div className={styles.inputCheckboxLabel}>
                        <span className={styles.inputCheckboxLabelText}>
                            {props.label}
                        </span>
                        {props.subText && (
                            <span className={styles.inputCheckboxLabelSubtext}>
                                {props.subText}
                            </span>
                        )}
                        {props.hyperLinks && (
                            <>
                                {props.hyperLinks.map((item, index) => (
                                    <a
                                        key={index}
                                        className={
                                            styles.inputCheckboxLabelLinkText
                                        }
                                        href={item.link_destination}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        <span>{item.link_text}</span>
                                        <ExternalLinkIcon
                                            fill="#0059FF"
                                            width="16"
                                            height="16"
                                            alignment-baseline="center"
                                        />
                                    </a>
                                ))}
                            </>
                        )}
                    </div>
                </label>
            </div>
        </>
    );
};

CheckboxInput.propTypes = {
    ...InputPropTypes,
    /**
     * Sub text under label
     */
    subText: PropTypes.string,
    /**
     * Hyperlinks list under label
     */
    hyperLinks: PropTypes.arrayOf(
        PropTypes.shape({
            link_text: PropTypes.string.isRequired,
            link_destinatination: PropTypes.string.isRequired
        })
    )
};
