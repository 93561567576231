import { render } from 'preact';

import { namespace } from '../helpers/namespaces';
import { hexToRGB } from '../helpers/colours';
import { getFormData } from './apiRequests';
import { isFormSubmitted } from './form.helper';
import Form from './form.component';
import { determineTenant } from '../helpers/client';
import { getSsoConfig } from '../helpers/authentication';
import { I18nextProvider } from 'react-i18next';
import i18nInstance from '../i18n';

const ID_DATA_ATTRIBUTE = `data-${namespace}form`;
const INIT_ATTR = 'data-fek-initialised';
const TENANT_DATA_ATTRIBUTE = `data-${namespace}tenant`;
const NO_INIT_SELECTOR = `:not([${INIT_ATTR}])`;

/**
 * Class which goes through page and finds FEK forms to embed
 */
export default class Forms {
    constructor() {
        this.init();
        this.observeDOM();
    }

    init() {
        document
            .querySelectorAll(`[${ID_DATA_ATTRIBUTE}]${NO_INIT_SELECTOR}`)
            .forEach(this.initNewForm.bind(this));
    }

    initNewForm(formEmbed) {
        const embedTenant = formEmbed.getAttribute(TENANT_DATA_ATTRIBUTE);

        determineTenant(embedTenant);

        const formId = formEmbed.getAttribute(ID_DATA_ATTRIBUTE);

        formEmbed.setAttribute(INIT_ATTR, '');

        getFormData(formId).then((formData) => {
            if (!formData) {
                return;
            }

            // Fetch settings data after prediction data is fetched
            getSsoConfig().then((settingsData) => {
                this.renderEmbed(formEmbed, formData, settingsData);
            });
        });
    }

    static initEmbeddableNewForm(embeddedFormId) {
        if (isFormSubmitted(embeddedFormId)) {
            // Skips form already submitted
            return Promise.reject(null);
        }

        return getFormData(embeddedFormId).then((formData) => {
            if (!formData) {
                return null;
            }
            return formData;
        });
    }

    /**
     * Sets up a MutationObserver to check the DOM
     * if new form embeds have been dynamically added
     */
    observeDOM() {
        const observer = new MutationObserver((mutationList) => {
            mutationList.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        const formEmbed = node.querySelector(
                            `[${ID_DATA_ATTRIBUTE}]${NO_INIT_SELECTOR}`
                        );
                        if (formEmbed) {
                            this.initNewForm(formEmbed);
                        }
                    }
                });
            });
        });

        observer.observe(document.body, { subtree: true, childList: true });
    }

    /**
     * Renders embeds to DOM
     *
     * @param {Element} container - embed container
     * @param {object} formData - form data
     * @param {object} settingsData - sso settings configuration
     */
    renderEmbed(container, formData, settingsData) {
        const parentElement = container?.parentElement;

        if (parentElement) {
            if (
                parentElement.hasAttribute('data-fek-poll') ||
                parentElement.hasAttribute('data-fek-prediction') ||
                parentElement.hasAttribute('data-fek-trivia')
            ) {
                return;
            }
        }

        // Set BG colour and gradient overrides.
        const hexBgColour = formData?.background_colour;
        const rgbBgColour = hexBgColour ? hexToRGB(hexBgColour) : null;

        const colourOverrides = {
            hex: hexBgColour,
            rgb: rgbBgColour
        };

        const shadow =
            container.shadowRoot || container.attachShadow({ mode: 'open' });
        render(
            <I18nextProvider i18n={i18nInstance}>
                <Form
                    formData={formData}
                    container={container}
                    settingsData={settingsData}
                    colourOverrides={colourOverrides}
                />
            </I18nextProvider>,
            shadow
        );
    }
}
