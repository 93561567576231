/* eslint-disable no-undefined */
/* eslint-disable no-magic-numbers */
import PropTypes from 'prop-types';
import { useContext } from 'preact/hooks';

import { getImageUrl } from '../../helpers/images';

import styleSheet from './styles/TopThreeResults.module.scss';
import { TopThreePollContext } from './TopThreePoll.component';
import WinnerIcon from '../shared-components/icons/WinnerIcon';
import { PositionLabel } from './shared-components/PositionLabel.component';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for a single top three option result
 *
 * @param {object} props prediction option props
 * @param {object} props.option prediction option data
 * @param {number} props.selectedIndex if selected index
 * @param {number} props.index - current option index
 * @returns {Function} <TopThreeResultsItem />
 */
function TopThreeResultsItem({ option, selectedIndex, index }) {
    const { t } = useTranslation();
    const { totalVotes = 0 } = useContext(TopThreePollContext);

    const hasOptionImages = option.image_url || option.image_data_preview;

    const getVotePercentage = (votes, totalVotes) => {
        if (!totalVotes) {
            return '0%';
        }
        const hundred = 100;
        return `${((hundred / totalVotes) * votes)?.toFixed(0)}%`;
    };

    return (
        <div className={styles.resultsItem}>
            <style>{styleSheet.toString()}</style>

            {index < 3 ? (
                <div className={styles.resultsPositionWinner}>
                    <WinnerIcon width="32" height="32" fill="white" />
                    <span className={styles.resultsPositionWinnerNumber}>
                        {index + 1}
                    </span>
                </div>
            ) : (
                <div className={styles.resultsPositionCircle}>
                    <span className={styles.resultsPositionNumber}>
                        {index + 1}
                    </span>
                </div>
            )}

            {hasOptionImages && (
                <div className={styles.resultsImage}>
                    {selectedIndex !== undefined ? (
                        <PositionLabel selectedIndex={selectedIndex} />
                    ) : (
                        ''
                    )}
                    <img src={getImageUrl(option.image_url, 840)} />
                </div>
            )}

            <div className={styles.resultsOverview}>
                <div className={styles.resultsName}>
                    {!hasOptionImages && selectedIndex !== undefined ? (
                        <div className={styles.resultsLabelContainer}>
                            <PositionLabel selectedIndex={selectedIndex} />
                        </div>
                    ) : (
                        ''
                    )}
                    <p>{option.text}</p>
                </div>
                <div className={styles.resultsBar}>
                    <div
                        className={styles.resultsBarProgressFirst}
                        style={{
                            width: getVotePercentage(
                                option.first_times_selected,
                                totalVotes
                            )
                        }}
                    />
                    <div
                        className={styles.resultsBarProgressSecond}
                        style={{
                            width: getVotePercentage(
                                option.second_times_selected,
                                totalVotes
                            )
                        }}
                    />
                    <div
                        className={styles.resultsBarProgressThird}
                        style={{
                            width: getVotePercentage(
                                option.third_times_selected,
                                totalVotes
                            )
                        }}
                    />
                </div>
                <div>
                    {getVotePercentage(option.times_selected, totalVotes)}{' '}
                    {option.times_selected} {t('common.votes')}
                </div>
            </div>
        </div>
    );
}

TopThreeResultsItem.propTypes = {
    option: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    selectedIndex: PropTypes.number
};

export { TopThreeResultsItem };
