import { getOptionById } from '../polls/poll.helpers';

const HTTP_RESPONSE_OK = 201;
const HTTP_RESPONSE_DUPLICATE = 400;

export const getVoteStatus = (response) => {
    switch (response.status) {
        case HTTP_RESPONSE_OK:
            return 'ACCEPTED';
        case HTTP_RESPONSE_DUPLICATE:
            return 'DUPLICATE';
        default:
            return 'ERROR';
    }
};

/**
 * Dispatch a custom event with poll details
 *
 * @param {object} pollData poll data
 * @param {Array} selectedOptions selected options
 * @param {object} submissionResponse response of poll request
 */
export const sendCustomEventVoted = (
    pollData,
    selectedOptions,
    submissionResponse = {}
) => {
    const voteStatus = getVoteStatus(submissionResponse);

    const event = new CustomEvent('PLAYBOOK_POLL_VOTE', {
        detail: {
            pollId: pollData.poll_id,
            question: {
                id: pollData.questions[0].question_id,
                text: pollData.questions[0].text
            },
            userVote: {
                id: selectedOptions[0].option_id,
                text: selectedOptions[0].text,
                voteAccepted: submissionResponse.status === HTTP_RESPONSE_OK,
                voteStatus
            }
        }
    });
    window.dispatchEvent(event);
};

/**
 * Dispatch a custom event with poll details
 *
 * @param {object} pollData poll data
 * @param {string} userAnswer option ID of user's selected answer
 */
export const sendCustomEventLoaded = (pollData, userAnswer) => {
    const eventDetail = {
        pollId: pollData.poll_id,
        status: pollData.state,
        question: {
            id: pollData.questions[0].question_id,
            text: pollData.questions[0].text
        },
        userVote: null
    };

    if (userAnswer) {
        eventDetail.userVote = {
            id: userAnswer,
            text: getOptionById(pollData, userAnswer)?.text
        };
    }

    const event = new CustomEvent('PLAYBOOK_POLL_LOADED', {
        detail: eventDetail
    });
    window.dispatchEvent(event);
};
