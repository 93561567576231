/* eslint-disable eqeqeq */
/* eslint-disable no-magic-numbers */
import { useContext } from 'preact/hooks';

import PredictionSelected from './predictionSelected';
import { PredictionContext } from './predictionContent';
import { useTranslation } from 'react-i18next';

import styleSheet from './styles/prediction.module.scss';

const styles = styleSheet.locals || {};

/**
 * Component which allows fan to vote for an option
 *
 * @returns {Function} <PredictionSubmittedScreen />
 */
function PredictionSubmittedScreen() {
    const { t } = useTranslation();
    const {
        predictionOptions,
        predictionOpen,
        selectedOptions,
        setIsSubmitted
    } = useContext(PredictionContext);

    return (
        <>
            <p className={styles.predictionSelectionText}>
                {t('predictions.selectionText')}
            </p>
            {selectedOptions.map((item, i) => {
                const selectedIndex = predictionOptions.findIndex(
                    (opt) =>
                        opt.option_id == item ||
                        opt.option_id === item.option_id
                );

                return (
                    <div key={i}>
                        <PredictionSelected
                            option={predictionOptions[selectedIndex]}
                            isOpen={predictionOpen}
                            onEdit={() => setIsSubmitted(false)}
                        />
                    </div>
                );
            })}

            <p className={styles.predictionSelectionFooterText}>
                {t('predictions.backLaterFooter')}
            </p>
        </>
    );
}

export default PredictionSubmittedScreen;
