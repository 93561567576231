import Polls from './polls';
import GuessTheGameweekGames from './guess-the-gameweek';
import DataCapture from './data-capture';
import Predictions from './predictions';

/**
 * Initialisation of the SDK
 *
 */
function init() {
    window.PlaybookForm = new DataCapture();
    window.PlaybookGtg = new GuessTheGameweekGames();
    window.PlaybookPoll = new Polls();
    window.PlaybookPrediction = new Predictions();

    window.dispatchEvent(new Event('PLAYBOOK_SDK_INIT'));
}

switch (document.readyState) {
    case 'interactive':
    case 'complete':
        init();
        break;
    default: {
        document.addEventListener('DOMContentLoaded', init);
    }
}
