/**
 * Dates helper to be used for Date input component
 **/

/**
 * Gets the minimun valid date for a specific age
 *
 * @param {number} age - years old
 * @returns {string} - date in format YYYY-MM-DD
 **/
export const getAgeInDateFormat = (age) => {
    const today = new Date();
    const minYear = today.getUTCFullYear() - age;
    return new Date(today.setFullYear(minYear)).toISOString().split('T')[0];
};

/**
 * Validates if date is in the min and max range values.
 *
 * @param {string} date - date value
 * @param {string} minDate - min valid value
 * @param {string} maxDate - max valid value
 * @returns {boolean} -
 */
export const isValidDateInRange = (date, minDate, maxDate) => {
    if (
        isNaN(new Date(date)) ||
        new Date(maxDate) > new Date(date) ||
        new Date(minDate) < new Date(date)
    ) {
        return false;
    }
    return true;
};
