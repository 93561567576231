const ENTERY_KEY = 13;

const handleEnterKey = (event, callback) => {
    if (event.key === 'Enter' || event.key === ENTERY_KEY) {
        event.preventDefault();
        callback(event);
        event.target.blur();
    }
};

/**
 * Sets listeners to listen when editable content has been changed
 *
 * @param {Element} elem - elem to listen to
 * @param {string} field - data field which will change
 * @param {Function} changeEditableData - callback to update field with new value
 */
export const enableEditableContent = (elem, field, changeEditableData) => {
    elem.addEventListener('blur', (event) => {
        changeEditableData(field, event.target.innerText);
    });
    elem.addEventListener('keydown', (event) =>
        handleEnterKey(event, (event) =>
            changeEditableData(field, event.target.innerText)
        )
    );
};
