const WinnerIcon = (props) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.33333 23.9996V21.333H10.6667V17.1996C9.57333 16.9596 8.6 16.493 7.74667 15.813C6.89333 15.133 6.26667 14.2796 5.86667 13.2663C4.2 13.0663 2.8 12.333 1.68 11.0796C0.56 9.8263 0 8.3463 0 6.6663V5.33297C0 4.59963 0.266667 3.97297 0.786667 3.45297C1.30667 2.93297 1.93333 2.6663 2.66667 2.6663H5.33333V-0.000366211H18.6667V2.6663H21.3333C22.0667 2.6663 22.6933 2.93297 23.2133 3.45297C23.7333 3.97297 24 4.59963 24 5.33297V6.6663C24 8.35963 23.44 9.8263 22.32 11.0796C21.2 12.333 19.8 13.0663 18.1333 13.2663C17.7333 14.293 17.1067 15.133 16.2533 15.813C15.4 16.493 14.4267 16.9463 13.3333 17.1996V21.333H18.6667V23.9996H5.33333ZM5.33333 10.3996V5.33297H2.66667V6.6663C2.66667 7.5063 2.90667 8.2663 3.4 8.9463C3.89333 9.6263 4.53333 10.1063 5.33333 10.3996ZM18.6667 10.3996C19.4667 10.1063 20.1067 9.6263 20.6 8.9463C21.0933 8.2663 21.3333 7.5063 21.3333 6.6663V5.33297H18.6667V10.3996Z"
            fill="#171D29"
        />
    </svg>
);

export default WinnerIcon;
