/* eslint-disable eqeqeq */
import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TopThreePollContext } from './TopThreePoll.component';

import styleSheet from './styles/TopThreeResults.module.scss';
import TopThreeSelection from './TopThreeSelection.component';
import {
    pollHasOptionImages,
    RESULTS_VISIBILITY
} from '../../polls/poll.helpers';
import PollTotalVotes from '../PollTotalVotes/pollTotalVotes.component';
import ShareUrlBox from '../Share/ShareUrlBox.component';
import Countdown from '../Countdown/Countdown.component';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component which display hidden results screen
 *
 * @param {object} props poll option props
 * @param {string} props.transitionState transition state
 * @param {string} props.resultsVisibility poll visibility
 * @returns {Function} <TopThreeResultsHiddenScreen />
 */
export function TopThreeResultsHiddenScreen({
    transitionState,
    resultsVisibility
}) {
    const {
        poll,
        selectedThreeOptions,
        transitionStyles,
        transitionDelay,
        totalVotes = 0,
        showVotesCounter,
        onCountdownEnd
    } = useContext(TopThreePollContext);
    const { t } = useTranslation();

    const hasImages = pollHasOptionImages(poll);

    const hideOptionsSelection =
        selectedThreeOptions?.every((option) => option === null) &&
        poll.state === 'closed';

    const RESULTS_CAST_TEXT = {
        [RESULTS_VISIBILITY.AFTER_CLOSING]: t(
            'polling.resultsRevealedAfterClosing'
        ),
        [RESULTS_VISIBILITY.NEVER]: t('polling.noResultsRevealed')
    };
    return (
        <>
            <style>{styleSheet.toString()}</style>
            {!hideOptionsSelection && (
                <TopThreeSelection hasImages={hasImages} isEditable={false} />
            )}
            <div
                className={classNames({
                    [styles.resultsContainerMargin]: hideOptionsSelection
                })}
            >
                <p className={styles.resultsMessage}>
                    {poll.submission_response_text?.length > 0
                        ? poll.submission_response_text
                        : RESULTS_CAST_TEXT[resultsVisibility]}
                </p>

                {poll.closes_at && (
                    <Countdown
                        text={t('polling.closesIn')}
                        datetime={poll.closes_at}
                        onCountdownEnd={onCountdownEnd}
                        closes={true}
                    />
                )}
            </div>

            {showVotesCounter && (
                <PollTotalVotes
                    votes={totalVotes}
                    style={{
                        ...transitionStyles[transitionState]
                    }}
                    margin
                />
            )}

            <ShareUrlBox
                pollId={poll.poll_id}
                style={{
                    ...transitionStyles[transitionState],
                    transitionDelay: `${transitionDelay}ms`
                }}
            />
        </>
    );
}

TopThreeResultsHiddenScreen.propTypes = {
    transitionState: PropTypes.string.isRequired,
    resultsVisibility: PropTypes.string.isRequired
};
