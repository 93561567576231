const ChevronIcon = (props) => (
    <svg
        width="16"
        height="9"
        viewBox="0 0 16 9"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M13.4818 0.487303L8.20628 5.76286L2.93476 0.491337C2.72627 0.282847 2.38807 0.278555 2.1766 0.490028L1.04505 1.62158C0.837279 1.82935 0.834155 2.16753 1.04636 2.37973L6.31788 7.65126C7.36401 8.69738 9.05127 8.7003 10.0938 7.65778L15.3731 2.37842C15.5809 2.17065 15.584 1.83247 15.3718 1.62027L14.2364 0.484799C14.0279 0.27631 13.6934 0.275734 13.4819 0.487207L13.4818 0.487303Z" />
    </svg>
);

export default ChevronIcon;
