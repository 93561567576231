import PropTypes from 'prop-types';

export const HelperTextPropTypes = {
    helperText: PropTypes.string,
    helperTextPosition: PropTypes.oneOf([
        'top-right',
        'bottom-left',
        'bottom-right'
    ])
};
