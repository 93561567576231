import styleSheet from './styles/gtg.module.scss';

import { useContext } from 'preact/hooks';
import { PollContext } from '../gtg.component';

import GTGMatch from './GTGMatch';
import GTGSubmitButton from './GTGSubmitButton';
import Countdown from '../../components/Countdown/Countdown.component';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for the GTG Vote Screen
 *
 * @returns {Function} <GTGVoteScreen />
 */
function GTGVoteScreen() {
    const { t } = useTranslation();
    const { poll, showSelectionError, onCountdownEnd } =
        useContext(PollContext);
    return (
        <div className={styles.gtgVoting}>
            <div className={styles.gtgVotingTitle}>{poll.text}</div>
            <ul className={styles.gtgVotingList}>
                {poll.questions.map((question) => (
                    <li
                        className={styles.gtgVotingListItem}
                        key={question.question_id}
                    >
                        <GTGMatch question={question} />
                    </li>
                ))}
            </ul>

            {showSelectionError && (
                <div className={styles.gtgVotingError}>
                    {t('gtg.predictAll')}
                </div>
            )}
            <GTGSubmitButton />

            {poll.closes_at && (
                <Countdown
                    text={t('gtg.closesIn')}
                    datetime={poll.closes_at}
                    onCountdownEnd={onCountdownEnd}
                    closes={true}
                    lightTheme={true}
                />
            )}
        </div>
    );
}

export default GTGVoteScreen;
