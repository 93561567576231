import { InputWrapper, InputWrapperPublicPropTypes } from '../input-wrapper';
import { InputPropTypes, useInput } from '../hooks/useInput';
import styleSheet from '../scss/input.module.scss';
import Countries from './Countries';

export const SelectDropdown = (props) => {
    const styles = styleSheet.locals || {};
    const { inputProps, inputWrapperProps } = useInput(props);

    return (
        <div>
            <InputWrapper {...inputWrapperProps}>
                <div className={styles.selectWrapper}>
                    <select
                        {...inputProps}
                        name={props.value}
                        id={props.value}
                        className={styles.select}
                    >
                        <option value="" selected disabled>
                            {props.placeholder}
                        </option>

                        {props.options
                            ? props.options.map((item, index) => (
                                  <option key={index} value={item.option_value}>
                                      {item.option_text}
                                  </option>
                              ))
                            : Countries.map((item, index) => (
                                  <option key={index} value={item}>
                                      {item}
                                  </option>
                              ))}
                    </select>
                </div>
            </InputWrapper>
        </div>
    );
};

SelectDropdown.propTypes = {
    ...InputPropTypes,
    ...InputWrapperPublicPropTypes
};
