import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';

import { noop } from '../../helpers/noop';

import Countdown from '../Countdown/Countdown.component';
import PollOptionsList from '../Polling/pollOptionsList.component';
import PollTotalVotes from '../PollTotalVotes/pollTotalVotes.component';
import SubmitButton from '../shared-components/button/SubmitButton';
import { PollContext } from '../../polls/poll.component';
import OptionAttributes from './OptionAttributes.component';
import SignInGate from '../shared-components/gate/SignInGate.component';
import { useTranslation } from 'react-i18next';

import styleSheet from './h2h.module.scss';

const styles = styleSheet.locals || {};

/**
 * Component which allows fan to vote for an option
 *
 * @param {object} props poll option props
 * @param {string} props.transitionState transition state
 * @returns {Function} <H2HVoteScreen />
 */
function H2HVoteScreen({ transitionState }) {
    const { t } = useTranslation();
    const {
        poll,
        selectedOptions,
        submittingVote = false,
        showVotesCounter = false,
        transitionStyles,
        transitionDelay,
        onVoteSubmit = noop,
        totalVotes = 0,
        onCountdownEnd,
        isSignedIn,
        isGated
    } = useContext(PollContext);

    return (
        <div className={styles.h2HWrapper}>
            {isGated && !isSignedIn && <SignInGate />}
            <PollOptionsList />

            <OptionAttributes options={poll.questions[0].options} />

            <div
                className={styles.h2HSubmitContainer}
                style={{
                    ...transitionStyles[transitionState],
                    transitionDelay: `${transitionDelay}ms`
                }}
            >
                <SubmitButton
                    onSubmit={onVoteSubmit}
                    disabled={selectedOptions.length === 0}
                    title={t('polling.submitTooltip')}
                    buttonText={t('polling.submit')}
                    loading={submittingVote}
                />
                {showVotesCounter && (
                    <PollTotalVotes
                        votes={totalVotes}
                        style={{
                            ...transitionStyles[transitionState]
                        }}
                    />
                )}
            </div>
            {poll.closes_at && (
                <Countdown
                    text={t('polling.closesIn')}
                    datetime={poll.closes_at}
                    onCountdownEnd={onCountdownEnd}
                    closes={true}
                    leftAligned={true}
                />
            )}
        </div>
    );
}

H2HVoteScreen.propTypes = {
    transitionState: PropTypes.string.isRequired
};

export default H2HVoteScreen;
