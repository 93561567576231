/* eslint-disable id-length */
import { useEffect, useState } from 'react';

export const useInputValidation = ({
    value,
    checked,
    isValid,
    onlyValidateAfterInputTouch,
    onValidationChange,
    touched
}) => {
    /**
     * The validity of the input as the user sees it. I.e. this powers whether
     * the input looks invalid to the user, despite it's actual validity. This
     * is important because sometimes we want invalid inputs to look valid. E.g.
     * on load when a field is required but empty.
     */
    const [isInputVisiblyValid, setIsInputVisiblyValid] = useState(
        onlyValidateAfterInputTouch === false && isValid
            ? isValid({ value, checked })
            : true
    );

    const [isInputActuallyValid, setIsInputActuallyValid] = useState(
        isValid?.({ value, checked }) ?? true
    );

    /**
     * 1. Validate the input.
     * 2. Set the validity.
     * 3. If the input has been touched, set the visible validity.
     */
    useEffect(() => {
        if (isValid) {
            // [1]
            const isValidResult = isValid({ value, checked });

            // [2]
            setIsInputActuallyValid(isValidResult);

            // [3]
            if (touched) {
                setIsInputVisiblyValid(isValidResult);
            }
        }
    }, [value, checked, isValid, onlyValidateAfterInputTouch, touched]);

    /**
     * Run the callback once the validation state has changed.
     *
     * We want to return the "actual" validity of the input to allow us to make
     * properly informed decisions. E.g. should the submit button be disabled?
     */
    useEffect(() => {
        onValidationChange?.(isInputActuallyValid !== false);
    }, [isInputActuallyValid, onValidationChange]);

    /**
     * Here we want to return the visible validity because it's what gets used
     * by the input wrapper to determine the styles.
     */
    return { isValid: isInputVisiblyValid !== false };
};
