import classNames from 'classnames';

import styleSheet from './styles/gtg.module.scss';

import { useContext } from 'preact/hooks';
import { PollContext } from '../gtg.component';

import ChevronIcon from '../../components/shared-components/icons/ChevronIcon';

import gtgBannerSvg from '../../assets/graphics/gtg-promo.svg';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for the GTG banner to open the model
 *
 * @returns {Function} <GTGBanner />
 */
function GTGBanner() {
    const { t } = useTranslation();
    const { poll, showGame, setShowGame, showPredictions } =
        useContext(PollContext);
    let buttonText = showGame ? t('gtg.hideButton') : t('gtg.predictButton');

    if (showPredictions && !showGame) {
        buttonText = t('gtg.showButton');
    }

    /* eslint-disable no-magic-numbers */
    return (
        <div
            className={classNames(styles.gtgBanner, {
                [styles.gtgBannerGameOpen]: showGame
            })}
            style={{ backgroundImage: `url(${gtgBannerSvg})` }}
        >
            <div className={styles.gtgBannerText}>
                <div className={styles.gtgBannerTitle}>
                    {t('gtg.premielLeagueTitle')}
                </div>
                <div className={styles.gtgBannerDescr}>
                    {poll.description || t('gtg.defaultDescription')}
                </div>
            </div>
            <button
                className={styles.gtgBannerCta}
                onClick={() => setShowGame(!showGame)}
            >
                {buttonText} <ChevronIcon className={styles.gtgBannerCtaIcon} />
            </button>
        </div>
    );
}

export default GTGBanner;
