import { InputWrapper, InputWrapperPublicPropTypes } from '../input-wrapper';
import { InputPropTypes, useInput } from '../hooks/useInput';

export const TextInput = (props) => {
    const { inputProps, inputWrapperProps } = useInput(props);
    return (
        <InputWrapper {...inputWrapperProps}>
            <input
                type="text"
                {...inputProps}
                data-cy="text-input"
                data-testid="text-input"
                className={inputProps.className}
            />
        </InputWrapper>
    );
};

TextInput.propTypes = {
    ...InputPropTypes,
    ...InputWrapperPublicPropTypes
};

TextInput.defaultProps = {
    /* eslint-disable-next-line no-magic-numbers */
    id: Math.floor(10000000 + Math.random() * 90000000)
};
