import { getClientHost } from '../helpers/client';

/**
 * GET /v1/fan/settings
 * Fetches Playbook settings data from Fan API
 *
 * @returns {Promise} -
 */
export const getSsoConfig = () => {
    const SETTINGS_HOST = `${getClientHost()}${process.env.API_PATH_SETTINGS}`;

    const endpoint = `https://${SETTINGS_HOST}/settings`;

    return new Promise((resolve, reject) => {
        fetch(endpoint)
            .then((response) => {
                if (!response.ok) {
                    console.warn('Playbook - Settings data unsuccessful');
                    return null;
                }

                return response.json();
            })
            .then((settingsData) => {
                resolve(settingsData);
            })
            .catch((error) => {
                console.error('Playbook - Settings data unsuccessful', error);
                reject(error);
            });
    });
};

/**
 * Retrieves the SSO (Single Sign-On) token based on the specified type.
 *
 * @param {string} type - The type of SSO provider. Supported types are 'keycloak' and 'okta'.
 * @returns {string|boolean} - Returns the token string for the specified type if recognized:
 *                             - 'kc_token' for 'keycloak'
 *                             - 'okta-token-storage' for 'okta'
 *                             Returns `false` if the type is not recognized.
 */
export const getSsoToken = (type) => {
    if (type === 'keycloak') {
        return 'kc_token';
    } else if (type === 'okta') {
        return 'okta-token-storage';
    }

    return false;
};

/**
 * Retrieves the authorization header based on the specified SSO (Single Sign-On) token type.
 *
 * @param {string} ssoTokenType - The type of SSO provider. Supported types are 'keycloak' and 'okta'.
 * @returns {string|boolean} - Returns the authorization header in the format `Bearer <token>`
 *                             if a valid token is found. Returns `false` if no valid token is available.
 */
export const getAuthHeader = (ssoTokenType) => {
    if (getSsoToken(ssoTokenType) === 'okta-token-storage') {
        const oktaValue = localStorage.getItem('okta-token-storage') || null;
        const parsedOktaValue = oktaValue ? JSON.parse(oktaValue) : null;

        const jwt = parsedOktaValue?.idToken?.idToken;

        if (jwt) {
            return `Bearer ${jwt}`;
        }

        return false;
    } else if (localStorage.getItem('kc_token')) {
        const jwt = localStorage.getItem('kc_token');
        return `Bearer ${jwt}`;
    }
    return false;
};

/**
 * Checks if the user is signed in based on the specified SSO (Single Sign-On) token type.
 *
 * @param {string} ssoTokenType - The type of SSO provider. Supported types are 'keycloak' and 'okta'.
 * @returns {boolean} - Returns `true` if a valid authorization header is found, indicating the user is signed in.
 *                      Returns `false` if no valid authorization header is available.
 */
export const checkForSignIn = (ssoTokenType) => {
    const authHeader = getAuthHeader(ssoTokenType);

    if (authHeader) {
        return true;
    }

    return false;
};
