import PropTypes from 'prop-types';
import ThumbIcon from '../../shared-components/icons/ThumbIcon';
import styleSheet from './styles/PositionLabel.module.scss';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

export const PositionLabel = ({ selectedIndex }) => {
    const { t } = useTranslation();

    const indexToOrdinalNumber = () => {
        switch (selectedIndex) {
            case 0:
                return t('common.firstAbbreviation');
            case 1:
                return t('common.secondAbbreviation');
            case 2:
                return t('common.thirdAbbreviation');
            default:
                return '';
        }
    };
    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div className={styles.positionLabel}>
                <ThumbIcon width="16" height="16" />
                <span>{indexToOrdinalNumber()}</span>
            </div>
        </>
    );
};

PositionLabel.propTypes = {
    selectedIndex: PropTypes.number
};
