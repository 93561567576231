import PropTypes from 'prop-types';

import styleSheet from './poll.module.scss';

import { getImageUrl } from '../../helpers/images';

const styles = styleSheet.locals || {};

/**
 * Component for the poll sponsor
 *
 * @param {object} props poll option props
 * @param {object} props.poll poll option data
 * @param {boolean} props.isVisible - footer sponsor visible, to decrease complexity of main components
 * @returns {Function} <PollSponsor />
 */
function PollSponsor({ poll, isVisible = true }) {
    if (!poll.sponsor_image_url || !isVisible) {
        return null;
    }
    /* eslint-disable no-magic-numbers */
    return (
        <div className={styles.pollSponsor}>
            {poll.sponsor_label ? (
                <div className={styles.pollSponsorLabel}>
                    {poll.sponsor_label}
                </div>
            ) : (
                ''
            )}
            <img
                src={getImageUrl(poll.sponsor_image_url, 160)}
                alt={poll.sponsor_image_text || ''}
                className={styles.pollSponsorImage}
            />
        </div>
    );
}

PollSponsor.propTypes = {
    poll: PropTypes.object.isRequired,
    isVisible: PropTypes.bool
};

export default PollSponsor;
