import PropTypes from 'prop-types';

import { noop } from '../../../helpers/noop';
import Loading from '../../Loading/Loading.component';

import styleSheet from './scss/submitButton.module.scss';

const styles = styleSheet.locals || {};

/**
 * Component which allows fan to vote for an option
 *
 * @param {object} props poll option props
 * @param {Function} props.onSubmit on submit callback
 * @param {boolean} props.disabled if button is disabled
 * @param {string} props.title button title attribute text
 * @param {string} props.buttonText button text
 * @param {boolean} props.loading if button should show loading state
 * @returns {Function} <SubmitButton />
 */
function SubmitButton({
    onSubmit = noop,
    disabled = false,
    title = '',
    buttonText = '',
    loading = false
}) {
    return (
        <>
            <style>{styleSheet.toString()}</style>
            <button
                className={styles.submitButton}
                onClick={onSubmit}
                disabled={disabled}
                title={title}
            >
                {loading ? <Loading size={14} /> : buttonText}
            </button>
        </>
    );
}

SubmitButton.propTypes = {
    onSubmit: PropTypes.func,
    disabled: PropTypes.bool,
    title: PropTypes.string,
    buttonText: PropTypes.string,
    loading: PropTypes.bool
};

export default SubmitButton;
