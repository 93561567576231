import { render } from 'preact';

import { namespace } from '../helpers/namespaces';
import { getClientHost, determineTenant } from '../helpers/client';
import { hexToRGB } from '../helpers/colours';
import { getSsoConfig } from '../helpers/authentication';
import Poll from './poll.component';
import TopThreePoll from '../components/TopThree/TopThreePoll.component';

import Form from '../data-capture';
import { sortPollOptions } from './poll.helpers';
import { POLL_TYPES } from '../helpers/constants';
import { I18nextProvider } from 'react-i18next';
import i18nInstance from '../i18n';

const ID_DATA_ATTRIBUTE = `data-${namespace}poll`;
const ID_DATA_CAPTURE_FORM = `data-${namespace}form`;
const ID_DATA_ATTRIBUTE_TRIVIA = `data-${namespace}trivia`;
const TENANT_DATA_ATTRIBUTE = `data-${namespace}tenant`;

const PREVIEW_DATA_ATTRIBUTE = `${ID_DATA_ATTRIBUTE}-preview`;
const INIT_ATTR = 'data-fek-initialised';
const NO_INIT_SELECTOR = `:not([${INIT_ATTR}])`;

/**
 * Class which goes through page and finds FEK polls to embed
 */
export default class Polls {
    constructor() {
        this.init();
        this.observeDOM();
    }

    init() {
        document
            .querySelectorAll(`[${ID_DATA_ATTRIBUTE}]${NO_INIT_SELECTOR}`)
            .forEach((item) => this.initNewPoll(item, false));

        document
            .querySelectorAll(
                `[${ID_DATA_ATTRIBUTE_TRIVIA}]${NO_INIT_SELECTOR}`
            )
            .forEach((item) => this.initNewPoll(item, true));
    }

    initNewPoll(pollEmbed, isTrivia = false) {
        const embedTenant = pollEmbed.getAttribute(TENANT_DATA_ATTRIBUTE);
        determineTenant(embedTenant);

        const pollId = isTrivia
            ? pollEmbed.getAttribute(ID_DATA_ATTRIBUTE_TRIVIA)
            : pollEmbed.getAttribute(ID_DATA_ATTRIBUTE);
        pollEmbed.setAttribute(INIT_ATTR, '');
        this.getPollData(pollEmbed, pollId, isTrivia);
    }

    initPreviews(onPreviewEditChange) {
        document
            .querySelectorAll(`[${PREVIEW_DATA_ATTRIBUTE}]`)

            .forEach((pollPreviewEmbed) => {
                const pollData = JSON.parse(
                    pollPreviewEmbed.getAttribute(PREVIEW_DATA_ATTRIBUTE)
                );
                this.renderEmbed(
                    pollPreviewEmbed,
                    pollData,
                    true,
                    onPreviewEditChange
                );
            });
    }

    /**
     * Sets up a MutationObserver to check the DOM
     * if new poll embeds have been dynamically added
     */
    observeDOM() {
        const observer = new MutationObserver((mutationList) => {
            mutationList.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        const triviaEmbed = node.querySelector(
                            `[${ID_DATA_ATTRIBUTE_TRIVIA}]${NO_INIT_SELECTOR}`
                        );

                        const pollEmbed = node.querySelector(
                            `[${ID_DATA_ATTRIBUTE}]${NO_INIT_SELECTOR}`
                        );
                        if (pollEmbed) {
                            this.initNewPoll(pollEmbed);
                        }

                        if (triviaEmbed) {
                            this.initNewPoll(triviaEmbed, true);
                        }
                    }
                });
            });
        });

        observer.observe(document.body, { subtree: true, childList: true });
    }

    /**
     * Fetches poll data from API
     *
     * @param {Element} container - embed container
     * @param {string} pollId - FEK poll ID
     * @param {boolean} isTrivia - flag whether trivia or poll activation
     */
    getPollData(container, pollId, isTrivia) {
        const pollEndpoint = `https://${getClientHost()}${
            process.env.API_PATH
        }/polls/${pollId}`;

        const triviaEndpoint = `https://${getClientHost()}${
            process.env.API_PATH_TRIVIA
        }/trivia/${pollId}`;

        const endpoint = isTrivia ? triviaEndpoint : pollEndpoint;

        fetch(endpoint)
            .then((response) => {
                if (!response.ok) {
                    console.warn('FEK poll embed unsuccessful', pollId);
                    return null;
                }

                return response.json();
            })
            .then((pollData) => {
                if (!pollData) {
                    return;
                }

                sortPollOptions(pollData);

                // Fetch settings data after prediction data is fetched
                getSsoConfig().then((settingsData) => {
                    this.renderEmbed(
                        container,
                        pollData,
                        false,
                        null,
                        settingsData
                    );
                });
            })
            .catch((error) => {
                console.warn('FEK poll embed unsuccessful', error);
            });
    }

    /**
     * Renders embeds to DOM
     *
     * @param {Element} container - embed container
     * @param {object} pollData - poll data
     * @param {boolean} isPreview - poll embed is a preview
     * @param {Function} onPreviewEditChange callback function when contenteditable data changes
     * @param {object} settingsData
     */
    async renderEmbed(
        container,
        pollData,
        isPreview,
        onPreviewEditChange,
        settingsData
    ) {
        /**
         * Used to fetch poll data to get latest vote data
         *
         * @returns {Promise} fetch promise
         */

        // Check if there is a linked form
        const embeddedFormId =
            container.firstElementChild?.getAttribute(ID_DATA_CAPTURE_FORM);
        let embeddedFormData;
        if (embeddedFormId) {
            embeddedFormData = await Form.initEmbeddableNewForm(embeddedFormId)
                .then((formData) => formData)
                .catch((rejectValue) => rejectValue);
        }

        const refetch = (pollData) => {
            const isTrivia =
                (pollData?.type === POLL_TYPES.TRIVIA ||
                    pollData?.type === POLL_TYPES.FREE_TEXT) ??
                false;
            return isTrivia
                ? fetch(
                      `https://${getClientHost()}${
                          process.env.API_PATH_TRIVIA
                      }/trivia/${pollData.trivia_id}`
                  ).then((response) => response.json())
                : fetch(
                      `https://${getClientHost()}${
                          process.env.API_PATH
                      }/polls/${pollData.poll_id}`
                  ).then((response) => response.json());
        };

        // Set BG colour and gradient overrides.
        const hexBgColour = pollData?.background_colour;
        const rgbBgColour = hexBgColour ? hexToRGB(hexBgColour) : null;

        const colourOverrides = {
            hex: hexBgColour,
            rgb: rgbBgColour
        };

        const expFeatureFlags = window.PLAYBOOK_CONFIG?.features || {};
        const shadow =
            container.shadowRoot || container.attachShadow({ mode: 'open' });

        render(
            <I18nextProvider i18n={i18nInstance}>
                {pollData.type === POLL_TYPES.TOP_THREE ? (
                    <TopThreePoll
                        pollData={pollData}
                        embeddedFormData={embeddedFormData}
                        container={container}
                        refetch={() => refetch(pollData)}
                        isPreview={isPreview}
                        onPreviewEditChange={onPreviewEditChange}
                        settingsData={settingsData}
                        colourOverrides={colourOverrides}
                    />
                ) : (
                    <Poll
                        pollData={pollData}
                        embeddedFormData={embeddedFormData}
                        container={container}
                        refetch={() => refetch(pollData)}
                        isPreview={isPreview}
                        onPreviewEditChange={onPreviewEditChange}
                        expFeatureFlags={expFeatureFlags}
                        settingsData={settingsData}
                        colourOverrides={colourOverrides}
                    />
                )}
            </I18nextProvider>,
            shadow
        );
    }
}
