// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host{all:initial;font-family:inherit;visibility:inherit;--fek-primary: #37003c;--fek-primary-rgb: 55, 0, 60;--fek-secondary: #ffffff;--fek-secondary-rgb: 255, 255, 255;--fek-text: var(--fek-secondary);--fek-text-dark: #171d29;--fek-option-opacity: 0.15;--fek-option-disabled-opacity: 0.5;--fek-button-opacity: 0.5;--fek-button-disabled-opacity: 0.5;--fek-error-opacity: 0.9}@keyframes WJwTji1VKX1rbmZqVa0k{from{transform:rotate(0)}to{transform:rotate(360deg)}}.NkYCH660yD3derJDuN2j{animation:WJwTji1VKX1rbmZqVa0k 1s ease infinite;display:inline-flex}`, "",{"version":3,"sources":["webpack://./src/scss/_global.scss","webpack://./src/components/Loading/loading.module.scss"],"names":[],"mappings":"AAAA,MACI,WAAA,CACA,mBAAA,CACA,kBAAA,CAEA,sBAAA,CACA,4BAAA,CAEA,wBAAA,CACA,kCAAA,CAEA,gCAAA,CACA,wBAAA,CAEA,0BAAA,CACA,kCAAA,CACA,yBAAA,CACA,kCAAA,CACA,wBAAA,CChBJ,gCACI,KACI,mBAAA,CAGJ,GACI,wBAAA,CAAA,CAIR,sBACI,+CAAA,CACA,mBAAA","sourcesContent":[":host {\n    all: initial;\n    font-family: inherit;\n    visibility: inherit;\n\n    --fek-primary: #37003c;\n    --fek-primary-rgb: 55, 0, 60;\n\n    --fek-secondary: #ffffff;\n    --fek-secondary-rgb: 255, 255, 255;\n\n    --fek-text: var(--fek-secondary);\n    --fek-text-dark: #171d29;\n\n    --fek-option-opacity: 0.15;\n    --fek-option-disabled-opacity: 0.5;\n    --fek-button-opacity: 0.5;\n    --fek-button-disabled-opacity: 0.5;\n    --fek-error-opacity: 0.9;\n}\n\n$font-light: 300;\n$font-regular: 400;\n$font-semibold: 600;\n$font-bold: 700;\n$font-extra-bold: 800;\n\n$red-100: #f8d6ce;\n$red-500: #de350d;\n$red-600: #b63012;\n$red-700: #8e2b17;\n\n$primary-500: #0059ff;\n","@import '../../scss/global';\n\n@keyframes loading-rotate {\n    from {\n        transform: rotate(0);\n    }\n\n    to {\n        transform: rotate(360deg);\n    }\n}\n\n.loading-component {\n    animation: loading-rotate 1s ease infinite;\n    display: inline-flex;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading-component": `NkYCH660yD3derJDuN2j`,
	"loadingComponent": `NkYCH660yD3derJDuN2j`,
	"loading-rotate": `WJwTji1VKX1rbmZqVa0k`,
	"loadingRotate": `WJwTji1VKX1rbmZqVa0k`
};
export default ___CSS_LOADER_EXPORT___;
