const TickIcon = (props) => (
    <svg
        width="22"
        height="16"
        viewBox="0 0 22 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.9189 12.9301L2.50072 8.51496C2.00415 8.01873 1.202 8.01873 0.705434 8.51496C0.208866 9.01118 0.208866 9.81277 0.705434 10.309L6.02763 15.6275C6.52419 16.1237 7.32634 16.1237 7.82291 15.6275L21.2939 2.16585C21.7905 1.66963 21.7905 0.868039 21.2939 0.371816C20.7973 -0.124407 19.9952 -0.124407 19.4986 0.371816L6.9189 12.9301Z"
            fill="black"
        />
    </svg>
);

export default TickIcon;
