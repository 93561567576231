const QuestionIcon = () => (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M7.1 10.2663c0-.9.0806-1.5473.2417-1.9417.1611-.3944.5028-.825 1.025-1.2917.4556-.4.8028-.7472 1.0417-1.0416.2388-.2945.3583-.6306.3583-1.0084 0-.4555-.1528-.8333-.4583-1.1333-.3056-.3-.7306-.45-1.275-.45-.5667 0-.9973.1722-1.2917.5167-.2944.3444-.5028.6944-.625 1.05L4.4 4.2329c.2334-.711.6611-1.3277 1.2834-1.85.6222-.5222 1.4055-.7833 2.35-.7833 1.1666 0 2.0638.325 2.6916.975.6278.65.9417 1.4306.9417 2.3417 0 .5555-.1195 1.0305-.3583 1.425-.2389.3944-.6139.8416-1.125 1.3416-.5445.5223-.875.9195-.9917 1.1917-.1167.2722-.175.7361-.175 1.3917H7.1Zm.9334 4c-.3667 0-.6806-.1306-.9417-.3917s-.3917-.575-.3917-.9417c0-.3666.1306-.6805.3917-.9416s.575-.3917.9417-.3917c.3666 0 .6805.1306.9416.3917s.3917.575.3917.9416c0 .3667-.1306.6806-.3917.9417-.261.2611-.575.3917-.9416.3917Z"
            fill="#fff"
        />
    </svg>
);

export default QuestionIcon;
