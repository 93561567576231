import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Countdown from '../Countdown/Countdown.component';
import PollOption from './PollOption/pollOption.component';
import PollOptionsList from './pollOptionsList.component';
import ShareUrlBox from '../Share/ShareUrlBox.component';
import OptionAttributes from '../H2H/OptionAttributes.component';

import { PollContext } from '../../polls/poll.component';
import { RESULTS_VISIBILITY } from '../../polls/poll.helpers';
import styleSheet from './poll.module.scss';
import { POLL_TYPES } from '../../helpers/constants';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for when the fan has cast a vote but results don't show yet
 *
 * @param {object} props poll option props
 * @param {number} props.transitionDelay transition delay
 * @param {string} props.transitionState transition state
 * @param {string} props.resultsVisibility results visibility
 * @returns {Function} <PollVoteCastScreen />
 */
function PollVoteCastScreen({
    transitionDelay,
    transitionState,
    resultsVisibility
}) {
    const { t } = useTranslation();
    const RESULTS_CAST_TEXT = {
        [RESULTS_VISIBILITY.AFTER_CLOSING]: t(
            'polling.resultsRevealedAfterClosing'
        ),
        [RESULTS_VISIBILITY.NEVER]: t('polling.noResultsRevealed')
    };

    const {
        poll,
        showResultsScreen = false,
        pollUserAnswer = null,
        transitionStyles,
        onCountdownEnd
    } = useContext(PollContext);

    const isH2H = poll.type === POLL_TYPES.H2H;

    const userAnswerOption = pollUserAnswer
        ? poll.questions[0]?.options.filter((opt) => {
              let answerIds;

              // Check if pollUserAnswer is an array
              if (Array.isArray(pollUserAnswer)) {
                  answerIds = pollUserAnswer.map(Number); // Convert to numbers if needed
              } else if (typeof pollUserAnswer === 'string') {
                  // Check if pollUserAnswer is a comma-separated string
                  if (pollUserAnswer.includes(',')) {
                      // Split the string into an array of IDs and convert each to a number
                      answerIds = pollUserAnswer.split(',').map(Number);
                  } else {
                      // Treat as a single ID
                      answerIds = [Number(pollUserAnswer)];
                  }
              } else {
                  // If it's neither an array nor a string, return false
                  return false;
              }

              // Check if the current option's ID matches any of the IDs in the array
              return answerIds.includes(Number(opt.option_id));
          })
        : null;

    const containerCls = classNames(styles.pollVoteCast, {
        [styles.pollH2HHiddenResults]:
            poll.state !== 'draft' &&
            poll.questions[0].results_visibility !==
                RESULTS_VISIBILITY.AFTER_SUBMISSION
    });

    return (
        <div className={containerCls}>
            <div className={styles.pollVoteCastContent}>
                <div className={styles.pollVoteCastTitle}>
                    {t('polling.votedFor').toUpperCase()}
                </div>
                {isH2H ? (
                    <>
                        <PollOptionsList />
                        <OptionAttributes options={poll.questions[0].options} />
                    </>
                ) : (
                    <div
                        className={
                            userAnswerOption?.length > 1 // this would mean there are more than one answer
                                ? styles.pollVoteCastOptionsMulti
                                : styles.pollVoteCastOption
                        }
                    >
                        {userAnswerOption?.map((option) => {
                            return (
                                <PollOption
                                    key={option?.option_id}
                                    option={option}
                                    optionCls={classNames(
                                        styles.pollOption,
                                        styles.pollOptionNoHover,
                                        styles.pollOptionSelected
                                    )}
                                    isAnswered={true}
                                    showingResults={true}
                                    transitionStyles={transitionStyles}
                                    optionsVisibleDelay={400}
                                    optionImageWidth={390}
                                />
                            );
                        })}
                    </div>
                )}
                <div className={styles.pollVoteCastText}>
                    {poll.submission_response_text?.length > 0
                        ? poll.submission_response_text
                        : RESULTS_CAST_TEXT[resultsVisibility]}
                </div>
            </div>
            {poll.closes_at && (
                <Countdown
                    text={t('polling.closesIn')}
                    datetime={poll.closes_at}
                    onCountdownEnd={onCountdownEnd}
                    closes={true}
                />
            )}
            {!showResultsScreen && (
                <ShareUrlBox
                    pollId={poll.poll_id}
                    style={{
                        ...transitionStyles[transitionState],
                        transitionDelay: `${transitionDelay}ms`
                    }}
                />
            )}
        </div>
    );
}

PollVoteCastScreen.propTypes = {
    transitionDelay: PropTypes.number.isRequired,
    transitionState: PropTypes.string.isRequired,
    resultsVisibility: PropTypes.string.isRequired
};

export default PollVoteCastScreen;
