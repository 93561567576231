/* eslint-disable id-length */
/* eslint-disable camelcase */
/* eslint-disable complexity */
import { useState, useEffect } from 'preact/hooks';
import { createContext } from 'preact';
import PropTypes from 'prop-types';
import { Transition } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';

import styleSheet from '../../components/Polling/poll.module.scss';

import PollHeader from '../../components/Polling/pollHeader.component';
import PollScheduledScreen from '../../components/Polling/pollScheduledScreen.component';
import PollClosedScreen from '../../components/Polling/pollClosedScreen.component';
import PollHero from '../../components/Polling/pollHero.component';
import PollSponsor from '../../components/Polling/pollSponsor.component';
import { DataCaptureFormComponent as Form } from 'playbook-data-capture-sdk-web';

import SubmitButton from '../../components/shared-components/button/SubmitButton';
import {
    isOpen,
    isScheduled,
    getUserVote,
    RESULTS_VISIBILITY,
    pollHasOptionImages
} from '../../polls/poll.helpers';
import { sendGTMEvent } from '../../helpers/gtm';
import { getAuthHeader, checkForSignIn } from '../../helpers/authentication';
import { TopThreeVoteScreen } from './TopThreeVoteScreen.component';
import {
    submitOptionsStanding,
    getOptionsStandingTallies,
    mapOptionsAndVoteResults
} from './apiRequests';
import { TopThreeResultsScreen } from './TopThreeResultsScreen.component';
import { TopThreeResultsHiddenScreen } from './TopThreeResultsHiddenScreen.component';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};
const SHOW_VOTES_THRESHOLD = 1000;
const THREE_SECONDS = 3000;

export const JITTER_TIMEOUT = 2000;
export const TopThreePollContext = createContext({});

/**
 * Component for showing a Top three poll
 *
 * @param {object} props poll props
 * @param {object} props.pollData polling data
 * @param {object} props.embeddedFormData form data to embed after polling submit
 * @param {object} props.container poll embed container
 * @param {Function} props.refetch function to refetch poll data
 * @param {boolean} props.isPreview poll embed is a preview
 * @param {Function} props.onPreviewEditChange callback function when contenteditable data changes
 * @param {object} props.settingsData settings object
 * @param {object} props.colourOverrides settings object
 * @returns {Function} <TopThreePoll />
 */
function TopThreePoll({
    pollData,
    embeddedFormData,
    container,
    refetch,
    isPreview = false,
    onPreviewEditChange,
    settingsData,
    colourOverrides
}) {
    const { t } = useTranslation();
    const [poll, setPoll] = useState(pollData);
    const [isSignedIn, setIsSignedIn] = useState(false);

    const ssoTokenType = settingsData?.token_name;
    const isGated = pollData?.gated;
    const authHeader = getAuthHeader(ssoTokenType);

    const [selectedThreeOptions, setSelectedThreeOptions] = useState(
        getUserVote(poll.poll_id)?.split(',') ?? [null, null, null]
    );

    const [submitError, setSubmitError] = useState(false);
    const [startEmbedAnimation, setStartEmbedAnimation] = useState(false);
    const [startBarsAnimation, setStartBarsAnimation] = useState(false);
    const [submittingVote, setSubmittingVote] = useState(
        !!getUserVote(poll.poll_id)
    );
    const [votingResults, setVotingResults] = useState(null);
    const [dataCaptureFormSubmitted, setDataCaptureFormSubmitted] =
        useState(false);
    const [goToPollResult, setGoToPollResult] = useState(false);

    const pollOpen = isOpen(poll);
    const isDraft = poll?.state === 'draft';
    const pollScheduled = isScheduled(poll);
    const [totalVotes, setTotalVotes] = useState(0);
    const showVotesCounter = totalVotes >= SHOW_VOTES_THRESHOLD;
    const hasOptionImages = pollHasOptionImages(poll);
    const hasEmptyOption = selectedThreeOptions?.includes(null);

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.5,
        onChange: (inView) => {
            if (inView) {
                setStartEmbedAnimation(true);
                setStartBarsAnimation(true);

                sendGTMEvent('playbook_view_event', poll, {
                    playbookUserVoted: !hasEmptyOption
                });
            }
        }
    });

    useEffect(() => {
        setIsSignedIn(checkForSignIn(ssoTokenType));
    }, []);

    useEffect(() => {
        applyPollSettings();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    useEffect(() => {
        if ((!hasEmptyOption || poll?.state === 'closed') && !isPreview) {
            // Get voting results
            getOptionsStandingTallies(
                pollData.poll_id,
                pollData.questions[0].question_id
            ).then((results) => {
                setTotalVotes(results.total_votes);
                setVotingResults(results);
                // Sets new poll data with top 3 positions votes to display results.
                const pollWithVotingResults = mapOptionsAndVoteResults(
                    pollData,
                    results
                );
                setPoll(pollWithVotingResults);
            });
        } else {
            setPoll(pollData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollData]);

    useEffect(() => {
        if (isPreview && (poll.previewVoted || poll.state === 'closed')) {
            // Sets default options for preview
            setSelectedThreeOptions([
                poll.questions[0].options[0],
                poll.questions[0].options[1],
                poll.questions[0].options[2]
            ]);
            setSubmittingVote(true);
            setVotingResults(true);
        } else if (isPreview) {
            setSelectedThreeOptions([null, null, null]);
            setSubmittingVote(false);
            setVotingResults(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPreview, poll]);

    const applyPollSettings = () => {
        const pollSettingsAttr =
            container.previousElementSibling?.getAttribute('data-fek-settings');
        if (pollSettingsAttr) {
            const settings = JSON.parse(pollSettingsAttr);

            if (settings.primaryColour) {
                container.style.setProperty(
                    '--fek-primary',
                    settings.primaryColour
                );
            }

            if (settings.primaryColourRgb) {
                container.style.setProperty(
                    '--fek-primary-rgb',
                    settings.primaryColourRgb
                );
            }
        } else if (colourOverrides?.hex && colourOverrides?.rgb) {
            container?.style?.setProperty('--fek-primary', colourOverrides.hex);

            container?.style?.setProperty(
                '--fek-primary-rgb',
                colourOverrides.rgb
            );
        }
    };

    if (poll.questions.length === 0) {
        return '';
    }

    const onVoteSubmit = async () => {
        if (isPreview) {
            return;
        }

        if (!selectedThreeOptions.includes(null)) {
            setStartBarsAnimation(false);

            const standingOptionsList = selectedThreeOptions.map(
                (option, index) => ({
                    option_id: option.option_id,
                    standing: index + 1
                })
            );

            submitOptionsStanding(
                poll.poll_id,
                poll.questions[0].question_id,
                standingOptionsList,
                isGated,
                authHeader
            )
                .then(() => {
                    setSubmittingVote(true);
                    // saveFormSubmitted(formData.form_id);
                    submitError && setSubmitError(false);

                    localStorage.setItem(
                        poll.poll_id,
                        standingOptionsList
                            .map((item) => item.option_id)
                            .toString()
                    );

                    // Get voting results
                    getOptionsStandingTallies(
                        poll.poll_id,
                        poll.questions[0].question_id
                    ).then(async (results) => {
                        setVotingResults(results);
                        setTotalVotes(results.total_votes);
                        // Get poll updated
                        const updatedPoll = await refetch();

                        // Match poll options and top 3 vote results
                        const pollWithVotingResults = mapOptionsAndVoteResults(
                            updatedPoll,
                            results
                        );
                        setPoll(pollWithVotingResults);
                    });

                    // TBC: custom and GTM events are required?
                    // sendCustomEventVoted(
                    //     updatedPoll,
                    //     selectedOptions,
                    //     submissionResponse
                    // );

                    // sendGTMEvent('playbook_vote_event', poll, {
                    //     playbookUserVoted: true,
                    //     playbookUserVoteStatus: getVoteStatus(response),
                    //     playbookUserAnswers: standingOptionsList.map(
                    //         (option) => option.text || option.option_id
                    //     )
                    // });
                })
                .catch((error) => {
                    setSubmitError(true);
                    console.error(error.detail ?? error.message);
                });
        } else {
            console.warn('NO OPTIONS SELECTED!');
        }
    };

    const onCountdownEnd = () => {
        setTimeout(async () => {
            const updatedPoll = await refetch();
            setPoll(updatedPoll);
        }, THREE_SECONDS);
    };

    const showPollOptions =
        isGated && !isSignedIn && !isDraft ? true : !submittingVote && pollOpen;

    const resultsVisibility =
        poll.questions[0]?.results_visibility ||
        RESULTS_VISIBILITY.AFTER_SUBMISSION;
    const showScheduledScreen = !pollOpen && pollScheduled;
    const showVotingClosedScreen = !pollOpen && !pollScheduled;

    // If the DC form is not in a status of 'open'  we treat the form as if there isn't one
    const hasDCForm = embeddedFormData?.status === 'open';

    const showDataCaptureForm =
        votingResults &&
        hasDCForm &&
        !dataCaptureFormSubmitted &&
        !showVotingClosedScreen;

    let showResultsScreen = false;
    let showVoteCastScreen = false;
    switch (resultsVisibility) {
        case RESULTS_VISIBILITY.AFTER_SUBMISSION:
            showVoteCastScreen = false;
            showResultsScreen =
                (pollOpen || showVotingClosedScreen) &&
                votingResults &&
                (!showDataCaptureForm || goToPollResult);
            break;
        case RESULTS_VISIBILITY.AFTER_CLOSING:
            showVoteCastScreen =
                votingResults &&
                pollOpen &&
                (!showDataCaptureForm || goToPollResult);
            showResultsScreen = votingResults && showVotingClosedScreen;
            break;
        case RESULTS_VISIBILITY.NEVER:
            showVoteCastScreen =
                (pollOpen || showVotingClosedScreen) &&
                votingResults &&
                (!showDataCaptureForm || goToPollResult);
            showResultsScreen = false;
            break;
        default:
    }

    // override - if a gated poll is gated and user is signed out
    // we never want to render the results (even below the sign in gate)
    if (isGated && !isSignedIn) {
        showResultsScreen = false;
        showVoteCastScreen = false;
    }

    const pollWidth = poll.settings?.width || '';
    const transitionStyles = {
        entered: { opacity: 1, transform: 'rotate(0deg)' }
    };
    const transitionDelay = 100;
    const transitionLength = 300;
    const submitTransitionDelay =
        (poll.questions?.[0]?.options?.length || 0) * transitionDelay +
        transitionLength;

    return (
        <TopThreePollContext.Provider
            value={{
                poll,
                selectedThreeOptions,
                setSelectedThreeOptions,
                pollOpen,
                startEmbedAnimation,
                submittingVote,
                showVotesCounter,
                transitionStyles,
                submitTransitionDelay,
                onVoteSubmit,
                totalVotes,
                onCountdownEnd,
                isPreview,
                onPreviewEditChange,
                hasOptionImages,
                startBarsAnimation,
                isGated,
                isSignedIn,
                authHeader
            }}
        >
            <style>{styleSheet.toString()}</style>
            <Transition nodeRef={ref} in={startEmbedAnimation}>
                {(transitionState) => (
                    <>
                        <div
                            ref={ref}
                            className={classNames(styles.poll, {
                                [styles.pollScheduled]: pollScheduled,
                                [styles.pollClosed]: showVotingClosedScreen
                            })}
                            style={{
                                maxWidth: pollWidth,
                                ...transitionStyles[transitionState]
                            }}
                        >
                            {poll.hero_image_url && <PollHero poll={poll} />}

                            <div className={styles.pollContent}>
                                <PollHeader
                                    poll={poll}
                                    pollScheduled={pollScheduled}
                                />

                                <div className={styles.pollBody}>
                                    {showScheduledScreen && (
                                        <PollScheduledScreen
                                            poll={poll}
                                            pollScheduled={pollScheduled}
                                            onCountdownEnd={onCountdownEnd}
                                        />
                                    )}

                                    {showPollOptions && (
                                        <TopThreeVoteScreen
                                            transitionState={transitionState}
                                        />
                                    )}

                                    {showDataCaptureForm && (
                                        <>
                                            <Form
                                                formData={embeddedFormData}
                                                submittedCallback={() =>
                                                    setDataCaptureFormSubmitted(
                                                        true
                                                    )
                                                }
                                                pollId={poll.poll_id}
                                            />

                                            {dataCaptureFormSubmitted && (
                                                <SubmitButton
                                                    onSubmit={() =>
                                                        setGoToPollResult(true)
                                                    }
                                                    buttonText={t(
                                                        'button.viewPollResults'
                                                    )}
                                                    title="View poll results"
                                                />
                                            )}
                                        </>
                                    )}

                                    {showResultsScreen && (
                                        <TopThreeResultsScreen
                                            transitionState={transitionState}
                                        />
                                    )}

                                    {showVoteCastScreen && (
                                        <TopThreeResultsHiddenScreen
                                            transitionState={transitionState}
                                            resultsVisibility={
                                                resultsVisibility
                                            }
                                        />
                                    )}

                                    {showVotingClosedScreen && (
                                        <PollClosedScreen
                                            resultsVisibility={
                                                resultsVisibility
                                            }
                                            transitionStyles={transitionStyles}
                                            transitionState={transitionState}
                                        />
                                    )}
                                </div>

                                {poll.sponsor_image_url && (
                                    <PollSponsor poll={poll} />
                                )}
                            </div>

                            {submitError && (
                                <span style={{ color: '#de350d' }}>
                                    {t('polling.submitError')}
                                </span>
                            )}
                        </div>
                    </>
                )}
            </Transition>
        </TopThreePollContext.Provider>
    );
}

TopThreePoll.propTypes = {
    pollData: PropTypes.object.isRequired,
    embeddedFormData: PropTypes.object,
    container: PropTypes.node.isRequired,
    refetch: PropTypes.func.isRequired,
    isPreview: PropTypes.bool,
    onPreviewEditChange: PropTypes.func,
    settingsData: PropTypes.object,
    colourOverrides: PropTypes.object
};

export default TopThreePoll;
