const WinnerIcon = (props) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M5.33333 24V21.3333H10.6667V17.2C9.57333 16.96 8.6 16.4933 7.74667 15.8133C6.89333 15.1333 6.26667 14.28 5.86667 13.2667C4.2 13.0667 2.8 12.3333 1.68 11.08C0.56 9.82667 0 8.34667 0 6.66667V5.33333C0 4.6 0.266667 3.97333 0.786667 3.45333C1.30667 2.93333 1.93333 2.66667 2.66667 2.66667H5.33333V0H18.6667V2.66667H21.3333C22.0667 2.66667 22.6933 2.93333 23.2133 3.45333C23.7333 3.97333 24 4.6 24 5.33333V6.66667C24 8.36 23.44 9.82667 22.32 11.08C21.2 12.3333 19.8 13.0667 18.1333 13.2667C17.7333 14.2933 17.1067 15.1333 16.2533 15.8133C15.4 16.4933 14.4267 16.9467 13.3333 17.2V21.3333H18.6667V24H5.33333ZM5.33333 10.4V5.33333H2.66667V6.66667C2.66667 7.50667 2.90667 8.26667 3.4 8.94667C3.89333 9.62667 4.53333 10.1067 5.33333 10.4ZM18.6667 10.4C19.4667 10.1067 20.1067 9.62667 20.6 8.94667C21.0933 8.26667 21.3333 7.50667 21.3333 6.66667V5.33333H18.6667V10.4Z" />
    </svg>
);

export default WinnerIcon;
