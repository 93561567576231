/* eslint-disable id-length */
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styleSheet from '../scss/input.module.scss';

import {
    LabelPropTypes,
    InputWrapperAbove,
    InputWrapperBelow
} from './components';
import { HelperTextPropTypes } from './components/helper-text/prop-types';

export const InputWrapper = function (props) {
    const styles = styleSheet.locals || {};

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div
                className={classNames(
                    styles.input,
                    {
                        [styles.inputReadOnly]: props.readOnly,
                        [styles.inputDisabled]: props.disabled,
                        [styles.inputValid]: props.isValid,
                        [styles.inputInvalid]: !props.isValid,
                        [styles.inputNoMaxWidth]: props.fullWidth
                    },
                    props.className
                )}
                data-unit-test="input-wrapper"
            >
                <InputWrapperAbove
                    id={props.id}
                    label={props.label}
                    hideLabel={props.hideLabel}
                    customElementTop={props.customElementTop}
                    helperText={props.helperText}
                    helperTextPosition={props.helperTextPosition}
                    required={props.required}
                />

                <div className={styles.inputWrapper}>
                    {props.children}

                    {props.customElementRight && (
                        <div
                            className={styles.inputWrapperHelperText}
                            data-cy="input-wrapper__custom-element--right"
                        >
                            {props.customElementRight}
                        </div>
                    )}
                </div>

                <InputWrapperBelow
                    data-cy="input-wrapper-below"
                    validationMessage={props.validationMessage}
                    isValid={props.isValid}
                    customElementBottom={props.customElementBottom}
                    helperText={props.helperText}
                    helperTextPosition={props.helperTextPosition}
                />
            </div>
        </>
    );
};

/**
 * The props can be used to control the InputWrapper through the usage of an
 * Input component.
 */
export const InputWrapperPublicPropTypes = {
    ...LabelPropTypes,

    customElementTop: PropTypes.node,
    customElementBottom: PropTypes.node,
    customElementRight: PropTypes.node,

    ...HelperTextPropTypes,

    // Validation message to show when input is invalid.
    validationMessage: PropTypes.string,

    // Optional property to make an input disabled.
    disabled: PropTypes.bool,

    // Optional property to make an input read-only.
    readOnly: PropTypes.bool
};

/**
 * These are internal props only, not ones ou can pass down to the InputWrapper
 * through the usage of an Input component.
 */
const InputWrapperPrivatePropTypes = {
    // CSS class(es) applied on component wrapper.
    className: PropTypes.string,

    // Validation state.
    isValid: PropTypes.bool,

    // The input element.
    children: PropTypes.node,

    // If true, don't restrict the width of the input.
    fullWidth: PropTypes.bool
};

export const InputWrapperPropTypes = {
    ...InputWrapperPublicPropTypes,
    ...InputWrapperPrivatePropTypes
};

InputWrapper.propTypes = InputWrapperPropTypes;

InputWrapper.defaultProps = {
    validationMessage: '',
    className: '',
    helperTextPosition: 'bottom-left'
};
