/* eslint-disable id-length */
import { useState, useEffect } from 'preact/hooks';
import { createContext } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styleSheet from './components/styles/gtg.module.scss';

import GTGBanner from './components/GTGBanner';
import GTGVoteScreen from './components/GTGVoteScreen';
import GTGResultsScreen from './components/GTGResultsScreen';

import {
    isOpen,
    isScheduled,
    getUserVote,
    getTotalVotes,
    RESULTS_VISIBILITY
} from '../polls/poll.helpers';
import { getOrCreateEncodedUUID } from '../helpers/user-meta';
import { getClientHost } from '../helpers/client';
import {
    // sendCustomEventVoted,
    sendCustomEventLoaded
} from '../helpers/customEvents';

const SHOW_VOTES_THRESHOLD = 1000;
const THREE_SECONDS = 3000;

export const JITTER_TIMEOUT = 2000;
export const PollContext = createContext({});
const styles = styleSheet.locals || {};

/**
 * Component for showing a poll
 *
 * @param {object} props poll props
 * @param {object} props.pollData polling data
 * @param {Function} props.refetch function to refetch poll data
 * @param {boolean} props.isPreview poll embed is a preview
 * @returns {Function} <GuessTheGameweek />
 */
function GuessTheGameweek({ pollData, refetch, isPreview = false }) {
    const [poll, setPoll] = useState(pollData);
    const [selectedOptions, setSelectedOptions] = useState({});
    const [pollUserAnswer, setPollUserAnswer] = useState(
        getUserVote(poll.poll_id)
    );
    const [submittingVote, setSubmittingVote] = useState(false);
    const [showGame, setShowGame] = useState(false);
    const [showSelectionError, setShowSelectionError] = useState(false);
    const pollOpen = isOpen(poll);
    const pollScheduled = isScheduled(poll);
    const totalVotes = getTotalVotes(poll);
    const showVotesCounter = totalVotes >= SHOW_VOTES_THRESHOLD;

    useEffect(() => {
        setPoll(pollData);
    }, [pollData]);

    useEffect(() => {
        sendCustomEventLoaded(poll, pollUserAnswer);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pollUserAnswer]);

    if (poll.questions.length === 0) {
        return '';
    }

    const submitPollAnswer = (pollId, answers) => {
        const userId = getOrCreateEncodedUUID();

        const endpoint = `https://${getClientHost()}${
            process.env.API_PATH
        }/polls/${pollId}/answers`;

        const data = {
            answers
        };

        const body = JSON.stringify(data);

        return fetch(endpoint, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-User-Id': userId
            },
            body
        });
    };

    const onVoteSubmit = async () => {
        if (isPreview) {
            return;
        }
        const votedQuestions = Object.keys(selectedOptions);

        if (votedQuestions.length === poll.questions.length) {
            setSubmittingVote(true);
            setShowSelectionError(false);
            localStorage.setItem(poll.poll_id, JSON.stringify(selectedOptions));

            const answers = [];

            votedQuestions.forEach((question) => {
                const questionId = parseInt(question);
                const optionId = selectedOptions[questionId]; // Get the corresponding option ID from selectedOptions

                // Check if there is an option selected for the question
                // eslint-disable-next-line no-undefined
                if (optionId !== undefined) {
                    const answer = {
                        question_id: questionId,
                        answer_options: [
                            {
                                option_id: optionId
                            }
                        ]
                    };

                    // Add the answer object to the answers array
                    answers.push(answer);
                }
            });

            submitPollAnswer(poll.poll_id, answers);

            setTimeout(async () => {
                const updatedPoll = await refetch();
                setPoll(updatedPoll);
                setPollUserAnswer(JSON.stringify(selectedOptions));

                // sendCustomEventVoted(updatedPoll, selectedOptions); // this functionality does not work
                // and has never worked for GTG so commenting for now.
            }, JITTER_TIMEOUT);
        } else {
            console.warn('NO OPTION SELECTED!');
            setShowSelectionError(true);
        }
    };

    const onCountdownEnd = () => {
        setTimeout(async () => {
            const updatedPoll = await refetch();
            setPoll(updatedPoll);
        }, THREE_SECONDS);
    };

    const showPollOptions = !pollUserAnswer && pollOpen;
    const resultsVisibility =
        poll.questions[0]?.results_visibility ||
        RESULTS_VISIBILITY.AFTER_SUBMISSION;
    const showResultsScreen =
        (pollOpen &&
            pollUserAnswer &&
            resultsVisibility === RESULTS_VISIBILITY.AFTER_SUBMISSION) ||
        (!pollOpen && !pollScheduled);

    return (
        <PollContext.Provider
            value={{
                poll,
                selectedOptions,
                setSelectedOptions,
                pollOpen,
                submittingVote,
                showVotesCounter,
                onVoteSubmit,
                totalVotes,
                onCountdownEnd,
                showGame,
                setShowGame,
                pollUserAnswer: JSON.parse(pollUserAnswer),
                showSelectionError,
                showPredictions: showResultsScreen
            }}
        >
            <style>{styleSheet.toString()}</style>

            <div
                className={classNames(styles.gtg, {
                    [styles.gtgGameOpen]: showGame
                })}
            >
                <GTGBanner />

                <div className={styles.gtgGame}>
                    <div className={styles.gtgContent}>
                        {showPollOptions && <GTGVoteScreen />}
                        {showResultsScreen && <GTGResultsScreen />}
                    </div>
                </div>
            </div>
        </PollContext.Provider>
    );
}

GuessTheGameweek.propTypes = {
    pollData: PropTypes.object.isRequired,
    refetch: PropTypes.func.isRequired,
    isPreview: PropTypes.bool
};

export default GuessTheGameweek;
