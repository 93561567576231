/* eslint-disable react/prop-types */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'preact/hooks';
import { Transition } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';

import styleSheet from './components/styles/prediction.module.scss';
import styleSheetPoll from '../components/Polling/poll.module.scss';
import PollSponsor from '../components/Polling/pollSponsor.component';

import PredictionHeader from './components/predictionHeader';
import PredictionContent from './components/predictionContent';

import { sendGTMEvent } from '../helpers/gtm';
import { getPredictionSubmittedOptions } from './predictions.helper';

const styles = styleSheet.locals || {};

/**
 * Component for showing a Prediction
 *
 * @param {object} props -
 * @param {object} props.predictionData - prediction data
 * @param {object} props.embeddedFormData form data to embed after polling submit
 * @param {HTMLElement} props.container - embed container
 * @param {boolean} props.isPreview embed is a preview in admin UI
 * @param {boolean} props.colourOverrides - colour overrides
 * @param props.settingsData
 * @returns {Element} <Prediction />
 */
function Prediction({
    predictionData,
    embeddedFormData,
    container,
    isPreview = false,
    settingsData,
    colourOverrides
}) {
    const [startEmbedAnimation, setStartEmbedAnimation] = useState(false);

    const transitionStyles = {
        entered: { opacity: 1, transform: 'rotate(0deg)' }
    };

    const { ref } = useInView({
        triggerOnce: true,
        threshold: 0.5,
        onChange: (inView) => {
            if (inView) {
                setStartEmbedAnimation(true);
                const submittedOptions = getPredictionSubmittedOptions(
                    predictionData.prediction_id
                );
                sendGTMEvent('playbook_view_event', predictionData, {
                    playbookUserVoted: submittedOptions !== null
                });
            }
        }
    });

    useEffect(() => {
        applyCustomSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const applyCustomSettings = () => {
        const customSettingsAttr =
            container.previousElementSibling?.getAttribute('data-fek-settings');
        if (customSettingsAttr) {
            const settings = JSON.parse(customSettingsAttr);

            if (settings.primaryColour) {
                container.style.setProperty(
                    '--fek-primary',
                    settings.primaryColour
                );
            }

            if (settings.primaryColourRgb) {
                container.style.setProperty(
                    '--fek-primary-rgb',
                    settings.primaryColourRgb
                );
            }
        } else if (colourOverrides?.hex && colourOverrides?.rgb) {
            container?.style?.setProperty('--fek-primary', colourOverrides.hex);

            container?.style?.setProperty(
                '--fek-primary-rgb',
                colourOverrides.rgb
            );
        }
    };

    return (
        <>
            <style>
                {styleSheet.toString()}
                {styleSheetPoll.toString()}
            </style>
            <Transition nodeRef={ref} in={startEmbedAnimation}>
                {(transitionState) => (
                    <>
                        <div
                            ref={ref}
                            className={styles.prediction}
                            style={{
                                maxWidth: predictionData.settings?.width || '',
                                ...transitionStyles[transitionState]
                            }}
                        >
                            <PredictionHeader prediction={predictionData} />

                            <div className={styles.predictionContent}>
                                <PredictionContent
                                    prediction={predictionData}
                                    transitionState={transitionState}
                                    isPreview={isPreview}
                                    settingsData={settingsData}
                                    embeddedFormData={embeddedFormData}
                                />

                                {predictionData.sponsor_image_url && (
                                    <PollSponsor poll={predictionData} />
                                )}
                            </div>
                        </div>
                    </>
                )}
            </Transition>
        </>
    );
}

Prediction.prototype = {
    predictionData: PropTypes.object.isRequired,
    embeddedFormData: PropTypes.object,
    container: PropTypes.node.isRequired,
    isPreview: PropTypes.bool
};

export default Prediction;
