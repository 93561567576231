import PropTypes from 'prop-types';
import styleSheet from './styles/gtg.module.scss';

import { useContext } from 'preact/hooks';
import { PollContext } from '../gtg.component';
import classNames from 'classnames';

const styles = styleSheet.locals || {};

/**
 * Component for the GTG button to open the model
 *
 * @param {object} props - component props
 * @param {object} props.question - question data
 * @param {boolean} [props.showResults] - show voting results
 * @param {number} [props.totalVotes] - total amount of votes
 * @returns {Function} <GTGMatch />
 */
function GTGMatch({ question, showResults = false, totalVotes = 0 }) {
    const { selectedOptions, setSelectedOptions, pollUserAnswer } =
        useContext(PollContext);

    const onSelect = (optionId) => {
        if (showResults) {
            return;
        }
        const newData = { ...selectedOptions };
        if (newData[question.question_id]) {
            if (newData[question.question_id] === optionId) {
                delete newData[question.question_id];
            } else {
                newData[question.question_id] = optionId;
            }
        } else {
            newData[question.question_id] = optionId;
        }
        setSelectedOptions(newData);
    };

    const getPercentage = (option) => {
        const ONE_HUNDRED = 100;
        if (totalVotes === 0 || option.times_selected === 0) {
            return '0%';
        }

        return `${((ONE_HUNDRED / totalVotes) * option.times_selected).toFixed(
            0
        )}%`;
    };

    return (
        <div className={styles.gtgMatch}>
            {question.options.map((option, index) => {
                const optaId = option.option_attributes.find(
                    (attr) => attr.name === 'optaId'
                )?.value;
                const abbr = option.option_attributes.find(
                    (attr) => attr.name === 'teamAbbreviation'
                )?.value;
                const badgeUrl = `https://resources.premierleague.com/premierleague/badges/100/${optaId}@x2.png`;
                const isSelected =
                    selectedOptions[question.question_id] === option.option_id;
                const percentage = getPercentage(option);
                const selectedByUser =
                    pollUserAnswer?.[question.question_id] === option.option_id;
                return (
                    <button
                        className={classNames(styles.gtgMatchOption, {
                            [styles.gtgMatchOptionDraw]: index === 1,
                            [styles.gtgMatchOptionSelected]:
                                isSelected || selectedByUser,
                            [styles.gtgMatchOptionResults]: showResults
                        })}
                        onClick={() => onSelect(option.option_id)}
                        key={option.option_id}
                    >
                        <div className={styles.gtgMatchTeam}>
                            {index !== 1 && (
                                <img
                                    src={badgeUrl}
                                    alt=""
                                    className={styles.gtgMatchBadge}
                                />
                            )}
                            <span className={styles.gtgMatchTeamDesktop}>
                                {option.text}
                            </span>
                            <span className={styles.gtgMatchTeamMobile}>
                                {abbr || option.text}
                            </span>
                        </div>
                        {showResults && (
                            <>
                                <div className={styles.gtgMatchResults}>
                                    {percentage}
                                </div>
                                <div
                                    className={styles.gtgMatchBar}
                                    style={{ width: percentage }}
                                />
                            </>
                        )}
                    </button>
                );
            })}
        </div>
    );
}

GTGMatch.propTypes = {
    question: PropTypes.object.isRequired,
    showResults: PropTypes.bool,
    totalVotes: PropTypes.Number
};

export default GTGMatch;
