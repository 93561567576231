/* eslint-disable eqeqeq */
/* eslint-disable no-magic-numbers */
/* eslint-disable react/prop-types */
import { useCallback, useContext } from 'preact/hooks';
import classNames from 'classnames';

import { TopThreePollContext } from './TopThreePoll.component';
import { getImageUrl } from '../../helpers/images';

import styleSheet from './styles/TopThreeSelection.module.scss';
import CrossIcon from '../../assets/icons/CrossIcon';
import AddIcon from '../../assets/icons/AddIcon';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for showing the top three selected items
 *
 * @param {object} props form props
 * @param {boolean} props.hasImages - poll has images
 * @param {boolean} props.isEditable - poll editable
 * @returns {Element} <TopThreeSelection />
 */
function TopThreeSelection({ hasImages, isEditable = true }) {
    const { poll, selectedThreeOptions, setSelectedThreeOptions } =
        useContext(TopThreePollContext);

    const { t } = useTranslation();

    const emptyPosition = (index) => {
        const currentOptionIndex = selectedThreeOptions.findIndex(
            (option) => option === null
        );

        return currentOptionIndex === index ? (
            <div className={styles.selectionSelectingItem}>
                <AddIcon />
                <span>{t('polling.selecting')}</span>
            </div>
        ) : (
            <div className={styles.selectionAwaitingItem}>
                <AddIcon />
            </div>
        );
    };

    const selectedPosition = useCallback(
        (index) => {
            let option;
            if (typeof selectedThreeOptions?.[index] === 'string') {
                // Get poll option object
                option = poll?.questions[0].options.find(
                    (item) => item.option_id == selectedThreeOptions[index]
                );
            } else {
                option = selectedThreeOptions?.[index];
            }
            const hasOptionImages =
                option && (option.image_url || option.image_data_preview);

            const onRemoveOption = () => {
                const newState = [...selectedThreeOptions];
                newState[index] = null;
                setSelectedThreeOptions(newState);
            };

            return (
                <div
                    className={classNames(styles.selectionSelectedItem, {
                        [styles.selectionSelectedItemImages]: hasOptionImages
                    })}
                >
                    {hasOptionImages && (
                        <div className={styles.selectionItemImg}>
                            <img src={getImageUrl(option.image_url, 840)} />
                        </div>
                    )}
                    {isEditable && (
                        <button
                            className={styles.selectionRemoveButton}
                            onClick={onRemoveOption}
                        >
                            <CrossIcon width="8" height="8" fillOpacity={0.5} />
                        </button>
                    )}

                    <p
                        className={
                            hasOptionImages ? styles.selectionItemText : ''
                        }
                    >
                        {option.text}
                    </p>
                </div>
            );
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedThreeOptions, poll]
    );

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <ul className={styles.selectionList}>
                <li
                    className={classNames(styles.selectionItem, {
                        [styles.selectionItemSmall]: !hasImages
                    })}
                >
                    <span className={styles.selectionItemHeader}>
                        {t('common.firstAbbreviation')}
                    </span>

                    {selectedThreeOptions?.[0] === null
                        ? emptyPosition(0)
                        : selectedPosition(0)}
                </li>
                <li
                    className={classNames(styles.selectionItem, {
                        [styles.selectionItemSmall]: !hasImages
                    })}
                >
                    <span className={styles.selectionItemHeader}>
                        {t('common.secondAbbreviation')}
                    </span>
                    {selectedThreeOptions[1] === null
                        ? emptyPosition(1)
                        : selectedPosition(1)}
                </li>
                <li
                    className={classNames(styles.selectionItem, {
                        [styles.selectionItemSmall]: !hasImages
                    })}
                >
                    <span className={styles.selectionItemHeader}>
                        {t('common.thirdAbbreviation')}
                    </span>
                    {selectedThreeOptions[2] === null
                        ? emptyPosition(2)
                        : selectedPosition(2)}
                </li>
            </ul>
        </>
    );
}

export default TopThreeSelection;
