const LOCALSTORAGE_FORM_KEY = 'fek-prediction-id';

/**
 * Save local storage when user has sent prediction data
 *
 * @param {number} predictionId ID of the prediction sent
 * @param {Array} options predicted options
 */
export const savePredictionSubmitted = (predictionId, options) => {
    localStorage.setItem(`${LOCALSTORAGE_FORM_KEY}-${predictionId}`, options);
};

/**
 * Checks localstorage whether user has submitted the prediction
 *
 * @param {string} predictionId form ID to check in localstorage
 * @returns {string} prediction options submited
 */
// eslint-disable-next-line id-length
export const getPredictionSubmittedOptions = (predictionId) => {
    return localStorage.getItem(`${LOCALSTORAGE_FORM_KEY}-${predictionId}`);
};

/**
 * Gets the percentage of how many people predicted the results correctly
 *
 * @param {object} prediction prediction data
 * @returns {number} percentage
 */
export const getCorrectPercentage = (prediction) => {
    const correctVotes = prediction.questions[0].correct_options.reduce(
        (a, b) => a + b.times_selected,
        0
    );
    const totalVotes = prediction.questions[0].options.reduce(
        (a, b) => a + b.times_selected,
        0
    );
    // eslint-disable-next-line
    return Math.round((correctVotes / totalVotes) * 100);
};

/**
 * Gets the percentage number to string
 *
 * @param {object} number prediction data
 * @returns {string} percentage
 */
export const getPercentageAsString = (number) => {
    const isRTL = document.documentElement.lang === 'ar';

    return isRTL ? `%${number}` : `${number}%`;
};
