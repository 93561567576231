/* eslint-disable no-magic-numbers */
import { createRef } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { getImageUrl } from '../../helpers/images';

import styleSheet from './styles/prediction.module.scss';
import styleSheetPoll from '../../components/Polling/poll.module.scss';
import { useTranslation } from 'react-i18next';
import { getPercentageAsString } from '../predictions.helper';

const styles = styleSheet.locals || {};
const stylesPoll = styleSheetPoll.locals || {};

/**
 * Component for a single prediction option
 *
 * @param {object} props prediction option props
 * @param {object} props.option prediction option data
 * @param {string} props.transitionState transition state
 * @param {number} props.optionsVisibleDelay delay for visibility transition
 * @param {boolean} props.isSelected if option is selected by user
 * @param {Function} props.onSelect callback when option has been selected by user
 * @returns {Function} <PredictionOption />
 */
function PredictionOption({
    option,
    transitionState,
    optionsVisibleDelay,
    isSelected,
    onSelect
}) {
    const { t } = useTranslation();
    const optionTextRef = createRef();
    const hasOptionImages = option.image_url || option.image_data_preview;
    const barTransitionStyles = {
        entered: { maxWidth: '100%' }
    };

    // const changeEditableData = (field, value) => {
    //     onPreviewEditChange?.({
    //         questions: [
    //             {
    //                 options: [{ ...option, [field]: value }]
    //             }
    //         ]
    //     });
    // };

    // useEffect(() => {
    //     if (isPreview) {
    //         enableEditableContent(
    //             optionTextRef.current,
    //             'text',
    //             changeEditableData
    //         );
    //     }
    // }, []);

    if (!option) {
        return '';
    }

    return (
        <button
            className={classNames(
                stylesPoll.pollOption,
                styles.predictionOption,
                {
                    [stylesPoll.pollOptionSelected]: isSelected
                }
            )}
            title={option.text}
            onClick={() => onSelect(option)}
        >
            <div className={styles.predictionOptionContent}>
                {hasOptionImages && (
                    <div className={styles.predictionOptionImg}>
                        <img src={getImageUrl(option.image_url, 840)} />
                    </div>
                )}

                <div
                    className={styles.predictionOptionTitle}
                    // {...editableAttr}
                    ref={optionTextRef}
                >
                    {option.text}
                </div>
            </div>

            <div className={styles.predictionOptionPercentage}>
                <div
                    className={stylesPoll.pollOptionBar}
                    style={{
                        width: `${option.percentage}%`,
                        ...barTransitionStyles[transitionState],
                        transitionDelay: `${optionsVisibleDelay}ms`
                    }}
                />
                <div className={styles.predictionOptionPercentage}>
                    <span>{getPercentageAsString(option.percentage)}</span>
                    &nbsp;
                    {t('predictions.percentagePicked')}
                </div>
            </div>
        </button>
    );
}

PredictionOption.propTypes = {
    option: PropTypes.object.isRequired,
    optionCls: PropTypes.object,
    showingResults: PropTypes.bool,
    transitionStyles: PropTypes.object,
    transitionState: PropTypes.string,
    optionsVisibleDelay: PropTypes.number,
    isSelected: PropTypes.bool,
    isAnswered: PropTypes.bool,
    onSelect: PropTypes.func,
    optionImageWidth: PropTypes.number.isRequired
};

export default PredictionOption;
