/* eslint-disable id-length */
import { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styleSheet from '../scss/input.module.scss';

import { useInputValidation } from './useInputValidation';

/**
 * @typedef {object} UseInputPropsArgument
 * @property {string} name -
 * @property {string|number|any[]} value -
 * @property {boolean} updateValueWithProp -
 * @property {boolean} checked -
 * @property {boolean} initialChecked -
 * @property {string} placeholder -
 * @property {Function} onBlur -
 * @property {Function} onFocus -
 * @property {Function} onKeyDown -
 * @property {string} id -
 * @property {string|number} min -
 * @property {string|number} max -
 * @property {string|number} rows -
 * @property {string|number} cols -
 * @property {Function} onChange -
 * @property {string} wrapperClassName -
 * @property {string} inputClassName -
 * @property {boolean} fullWidth -
 * @property {Function} isValid -
 * @property {Function} isValidBuiltIn - A built-in validation function for the
 *    input.
 * @property {Function} onlyValidateAfterInputTouch -
 * @property {Function} onValidationChange -
 * @property {string} validationMessage -
 * @property {boolean} disabled -
 * @property {boolean} readOnly -
 * @property {string} label -
 * @property {boolean} hideLabel -
 * @property {any} customElementTop -
 * @property {any} customElementBottom -
 * @property {any} customElementRight -
 * @property {string} helperText -
 * @property {string} helperTextPosition -
 * @property {boolean} autoComplete -
 * @property {boolean} required -
 * @property {Function} onReset - callback if value has been reset
 * @property {boolean} touched - Provides a way to override the default touch
 *    detection. Useful for composite inputs (i.e. inputs which contain other
 *    inputs, like range inputs) where we want the user to interact with
 *    multiple components before considering the input to have been touched by
 *    the user.
 */

/**
 * @typedef {object} UseInputReturnObject
 * @property {object} inputProps - Props to be used on the input element.
 * @property {object} inputWrapperProps - Props to be passed to the InputWrapper
 *    component.
 * @property {Function} reset - Reset the component value and consider it
 *    untouched.
 */

/**
 * This hook is designed to be used in the top-level an input component. It
 * provides a way to get all the props you need for an input/textarea/select
 * element and the InputWrapper that surrounds it.
 *
 * @example
const { inputProps, inputWrapperProps } = useInput( props );
 
return (
    <InputWrapper { ...inputWrapperProps }>
        <input type="text" { ...inputProps } />
    </InputWrapper>
);
 * @param {UseInputPropsArgument} initialProps - The props passed to the component.
 * @param {'value'|'checked'} type - Whether the input is going to be using the
 *    `checked` or `value` prop (i.e. is it a checkbox/radio button or a text-
 *    based input).
 * @returns {UseInputReturnObject} -
 */
export const useInput = (
    {
        name,
        value,
        updateValueWithProp,
        checked,
        initialChecked,
        placeholder,
        onBlur,
        onFocus,
        onKeyDown,
        id,
        min,
        max,
        rows,
        cols,
        onChange,
        wrapperClassName,
        inputClassName,
        fullWidth,
        isValid,
        isValidBuiltIn,
        onlyValidateAfterInputTouch,
        onValidationChange,
        validationMessage,
        disabled,
        readOnly,
        label,
        hideLabel,
        customElementTop,
        customElementBottom,
        customElementRight,
        helperText,
        helperTextPosition,
        autoComplete,
        required,
        touched,
        onReset
    },
    type = 'value'
) => {
    const styles = styleSheet.locals || {};

    const [state, setState] = useState({
        value: type === 'value' ? value ?? '' : null,
        checked: type === 'checked' ? initialChecked ?? checked : null
    });

    /**
     * False whilst the user has yet to interact with the input. Else true.
     */
    const touchedRef = useRef(false);

    /**
     * If the value of the input changes as a result of user input, execute the
     * onChange callback.
     */
    useEffect(() => {
        if ((touched || touchedRef.current) && onChange) {
            switch (type) {
                case 'checked':
                    onChange(state.checked, name);
                    break;
                case 'value':
                default:
                    onChange(state.value, name);
                    break;
            }
        }
        // NOTE: Adding onChange to the useEffect dependencies causes issues
        //         when the callback is not memoised.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name, state]);

    /**
     * If the `value` value has changed from the parent, update the state with
     * the new `value` value.
     */
    useEffect(() => {
        if (
            value !== null &&
            typeof value !== 'undefined' &&
            updateValueWithProp
        ) {
            setState((currentState) => ({
                ...currentState,
                value
            }));
        }
    }, [value, updateValueWithProp]);

    /**
     * If the `checked` value has changed from the parent, update the state with
     * the new `checked` value.
     */
    useEffect(() => {
        if (checked === !!checked) {
            setState((currentState) => ({
                ...currentState,
                checked
            }));
        }
    }, [checked]);

    /**
     * Create the props to be passed to the input element.
     *
     * 1. Whenever we receive a new change, update the state. The state change
     *    effect is handled above, triggering the onChange callback. Sometimes
     *    we don't receive a real event, which is why the `customValue` takes
     *    care of the situations when we want to manually assign a value.
     * 2. This is an interesting case where we need to exclude the
     *    `inputClassName` and defer it to the input component(s) nested within
     *    this input component. See the `DateRangeInput` for an example.
     */
    const inputProps = {
        name,
        // [1]
        onChange: (event, customValue) => {
            event?.persist && event.persist();

            const newStatePartialForType = {
                checked: {
                    checked: customValue?.checked ?? event?.target?.checked
                },
                value: {
                    value: customValue?.value ?? event?.target?.value
                }
            }[type];

            setState((currentState) => ({
                ...currentState,
                ...newStatePartialForType
            }));

            touchedRef.current = true;
        },
        onBlur,
        onFocus,
        onKeyDown,
        placeholder,
        // [2]
        className: classNames(styles.inputField, inputClassName),
        /* eslint-disable-next-line no-magic-numbers */
        id: id ?? Math.floor(10000000 + Math.random() * 90000000),
        disabled,
        readOnly,
        min,
        max,
        rows,
        cols,
        autoComplete,
        required,
        ...state
    };

    // ==== Validation ====================================================== //

    /**
     * Setup validation for the input if needed.
     *
     * 1. If we have an `isValid` function provided to this input, we'll use it
     *    and provide the built-in validator (if there is one) as the second
     *    argument. This allows us to decide whether we want to make use of the
     *    built-in validator or ignore it on a case-by-case basis. The fallback
     *    is to just use the built-in validator (if there is one).
     */
    // eslint-disable-next-line no-undefined
    const inputTouched = touched !== undefined ? touched : touchedRef.current;

    const validationResult = useInputValidation({
        ...state,
        // [1]
        isValid: isValid
            ? (data) => isValid(data, isValidBuiltIn, inputTouched)
            : isValidBuiltIn,
        onlyValidateAfterInputTouch,
        onValidationChange,
        touched: inputTouched
    });

    /**
     * Create the props to be passed to the InputWrapper component.
     */
    const inputWrapperProps = {
        isValid: validationResult.isValid,
        id: inputProps.id,
        validationMessage,
        label,
        hideLabel,
        customElementTop,
        customElementBottom,
        customElementRight,
        helperText,
        helperTextPosition,
        className: wrapperClassName,
        disabled,
        readOnly,
        fullWidth,
        required
    };

    /**
     *
     */
    function reset() {
        setState((currentState) => ({
            ...currentState,
            value: ''
        }));
        onReset?.();
        touchedRef.current = false;
    }

    return { inputProps, inputWrapperProps, reset };
};

/**
 * Base prop types for any input.
 */
export const InputPropTypes = {
    // Standard input props.
    name: PropTypes.string,
    id: PropTypes.string,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    disabled: PropTypes.bool,
    readOnly: PropTypes.bool,

    // Class name(s) for the input wrapper.
    wrapperClassName: PropTypes.string,

    // Class name(s) for the input element(s).
    inputClassName: PropTypes.string,

    // If true, don't restrict the width of the input.
    fullWidth: PropTypes.bool,

    // A callback executed when the value of the input changes.
    // Unlike a standard onChange callback this does not receive an event as the
    // argument. Instead it receives useInput's state.
    // Usage: ( { value, checked } ) => any
    onChange: PropTypes.func,

    // A callback executed when the value of the input has been reset to ''.
    onReset: PropTypes.func,

    // Sets the value of the input.
    // The input will update when the value of this prop changes.
    value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.object
    ]),

    // Updates the value of the text if the prop value changes.
    updateValueWithProp: PropTypes.bool,

    // Sets the checked state of the input for radio and checkbox inputs.
    // The input will update when the value of this prop changes.
    checked: PropTypes.bool,

    // Sets the checked state of the input for radio and checkbox inputs.
    // The input will not update when the value of this prop changes.
    initialChecked: PropTypes.bool,

    // A validation callback that overrides any default/internal validation.
    // Usage: ( isValid ) => boolean
    isValid: PropTypes.func,

    // If set to false, this will cause the isValid callback to be executed
    // immediately instead of waiting for the user to make a change to the
    // input.
    onlyValidateAfterInputTouch: PropTypes.bool,

    // A callback executed whenever the input changes validation state.
    // Usage: ( { value, checked } ) => boolean
    onValidationChange: PropTypes.func,
    /**
     * The minimum allowed value.
     */
    min: PropTypes.string,
    /**
     * The maximum allowed value.
     */
    max: PropTypes.string
};
