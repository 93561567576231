/* eslint-disable eqeqeq */
import { useContext, useRef, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { TopThreePollContext } from './TopThreePoll.component';

import styleSheet from './styles/TopThreeResults.module.scss';
import styleSheetButton from '../shared-components/button/scss/submitButton.module.scss';
import pollStyleSheet from '../Polling/poll.module.scss';
const pollStyles = pollStyleSheet.locals || {};

import TopThreeSelection from './TopThreeSelection.component';
import { pollHasOptionImages } from '../../polls/poll.helpers';
import { TopThreeResultsItem } from './TopThreeResultsItem.component';
import PollTotalVotes from '../PollTotalVotes/pollTotalVotes.component';
import ShareUrlBox from '../Share/ShareUrlBox.component';

import SignInGate from '../shared-components/gate/SignInGate.component';

import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};
const stylesButton = styleSheetButton.locals || {};

/**
 * Component which allows fan to vote for an option
 *
 * @param {object} props poll option props
 * @param {string} props.transitionState transition state
 * @returns {Function} <TopThreeResults />
 */
export function TopThreeResultsScreen({ transitionState }) {
    const {
        poll,
        selectedThreeOptions,
        transitionStyles,
        transitionDelay,
        totalVotes = 0,
        showVotesCounter,
        isSignedIn,
        isGated
    } = useContext(TopThreePollContext);
    const { t } = useTranslation();
    const elementRef = useRef(null);

    const [expandResults, setExpandResults] = useState(false);

    const pollOptions = poll?.questions[0].options;

    const hasImages = pollHasOptionImages(poll);

    const hideOptionsSelection =
        selectedThreeOptions?.every((option) => option === null) &&
        poll.state === 'closed';

    return (
        <>
            <div className={pollStyles.pollWrapper}>
                {isGated && !isSignedIn && <SignInGate />}
                {!hideOptionsSelection && (
                    <TopThreeSelection
                        hasImages={hasImages}
                        isEditable={false}
                    />
                )}
                <div
                    className={classNames({
                        [styles.resultsContainerMargin]: hideOptionsSelection
                    })}
                >
                    {t('polling.resultsLegend')}
                    <ul className={styles.resultsPositionsLegend}>
                        <li className={styles.resultsHeader}>
                            {t('common.first')}{' '}
                            <div
                                className={classNames(
                                    styles.resultsLegendItem,
                                    styles.resultsLegendItemFirst
                                )}
                            />
                        </li>
                        <li className={styles.resultsHeader}>
                            {t('common.second')}{' '}
                            <div
                                className={classNames(
                                    styles.resultsLegendItem,
                                    styles.resultsLegendItemSecond
                                )}
                            />
                        </li>
                        <li className={styles.resultsHeader}>
                            {t('common.third')}{' '}
                            <div
                                className={classNames(
                                    styles.resultsLegendItem,
                                    styles.resultsLegendItemThird
                                )}
                            />
                        </li>
                    </ul>
                </div>

                <div className={styles.resultsExpandableContainer}>
                    <div
                        className={classNames({
                            [styles.resultsContainerHidden]: !expandResults,
                            [styles.resultsContainerHiddenLarger]:
                                hideOptionsSelection && !expandResults
                        })}
                        ref={elementRef}
                    >
                        {pollOptions.map((item, i) => {
                            let selectedIndex;
                            const isSelected = selectedThreeOptions.find(
                                (selectedOpt) =>
                                    selectedOpt &&
                                    (selectedOpt == item.option_id ||
                                        selectedOpt.option_id ===
                                            item.option_id ||
                                        // eslint-disable-next-line eqeqeq
                                        selectedOpt == item.option_id)
                            );

                            if (isSelected) {
                                selectedIndex = selectedThreeOptions.findIndex(
                                    (opt) =>
                                        opt?.option_id
                                            ? opt?.option_id ===
                                              isSelected.option_id
                                            : opt == isSelected
                                );
                            }
                            return (
                                <TopThreeResultsItem
                                    key={i}
                                    option={item}
                                    transitionState={transitionState}
                                    transitionStyles={transitionStyles}
                                    selectedIndex={selectedIndex}
                                    index={i}
                                />
                            );
                        })}
                    </div>
                    {!expandResults && (
                        <div className={styles.resultsButton}>
                            <style>{styleSheetButton.toString()}</style>
                            <button
                                className={classNames(
                                    stylesButton.submitButton
                                )}
                                onClick={() => setExpandResults(true)}
                                title="Expand full results"
                            >
                                {t('polling.expandButton')}
                            </button>
                        </div>
                    )}
                </div>
            </div>

            {showVotesCounter && (
                <PollTotalVotes
                    votes={totalVotes}
                    style={{
                        ...transitionStyles[transitionState]
                    }}
                    margin
                />
            )}

            <ShareUrlBox
                pollId={poll.poll_id}
                style={{
                    ...transitionStyles[transitionState],
                    transitionDelay: `${transitionDelay}ms`
                }}
            />
        </>
    );
}

TopThreeResultsScreen.propTypes = {
    transitionState: PropTypes.string.isRequired
};
