import { render } from 'preact';

import { namespace } from '../helpers/namespaces';
import { hexToRGB } from '../helpers/colours';
import { getPredictionData } from './apiRequests';
import Prediction from './prediction.component';
import { determineTenant } from '../helpers/client';
import { getSsoConfig } from '../helpers/authentication';
import { I18nextProvider } from 'react-i18next';
import { DataCaptureForm as Form } from 'playbook-data-capture-sdk-web';
import i18nInstance from '../i18n';

const ID_DATA_ATTRIBUTE = `data-${namespace}prediction`;
const ID_DATA_CAPTURE_FORM = `data-${namespace}form`;
const PREVIEW_DATA_ATTRIBUTE = `${ID_DATA_ATTRIBUTE}-preview`;
const TENANT_DATA_ATTRIBUTE = `data-${namespace}tenant`;
const INIT_ATTR = 'data-fek-initialised';
const NO_INIT_SELECTOR = `:not([${INIT_ATTR}])`;

/**
 * Class which goes through page and finds FEK predictions to embed
 */
export default class Predictions {
    constructor() {
        this.init();
        this.observeDOM();
    }

    init() {
        document
            .querySelectorAll(`[${ID_DATA_ATTRIBUTE}]${NO_INIT_SELECTOR}`)
            .forEach(this.initNewPrediction.bind(this));
    }

    initNewPrediction(predictionEmbed) {
        const embedTenant = predictionEmbed.getAttribute(TENANT_DATA_ATTRIBUTE);
        determineTenant(embedTenant);
        const formId = predictionEmbed.getAttribute(ID_DATA_ATTRIBUTE);
        predictionEmbed.setAttribute(INIT_ATTR, '');

        getPredictionData(formId).then((predictionData) => {
            if (!predictionData) {
                return;
            }
            // Fetch settings data after prediction data is fetched
            getSsoConfig().then((settingsData) => {
                this.renderEmbed(
                    predictionEmbed,
                    predictionData,
                    false,
                    null,
                    settingsData
                );
            });
        });
    }

    initPreviews(onPreviewEditChange) {
        document
            .querySelectorAll(`[${PREVIEW_DATA_ATTRIBUTE}]`)
            .forEach((previewEmbed) => {
                const data = JSON.parse(
                    previewEmbed.getAttribute(PREVIEW_DATA_ATTRIBUTE)
                );
                if (data?.prediction_id) {
                    this.renderEmbed(
                        previewEmbed,
                        data,
                        true,
                        onPreviewEditChange
                    );
                }
            });
    }

    /**
     * Sets up a MutationObserver to check the DOM
     * if new form embeds have been dynamically added
     */
    observeDOM() {
        const observer = new MutationObserver((mutationList) => {
            mutationList.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        const predictionEmbed = node.querySelector(
                            `[${ID_DATA_ATTRIBUTE}]${NO_INIT_SELECTOR}`
                        );
                        if (predictionEmbed) {
                            this.initNewPrediction(predictionEmbed);
                        }
                    }
                });
            });
        });

        observer.observe(document.body, { subtree: true, childList: true });
    }

    /**
     * Renders embeds to DOM
     *
     * @param {Element} container - embed container
     * @param {object} predictionData - prediction data
     * @param {boolean} isPreview - poll embed is a preview
     * @param {Function} onPreviewEditChange callback function when contenteditable data changes
     * @param {object} settingsData
     */
    async renderEmbed(
        container,
        predictionData,
        isPreview,
        onPreviewEditChange,
        settingsData
    ) {
        // Check if there is a linked form
        const embeddedFormId =
            container.firstElementChild?.getAttribute(ID_DATA_CAPTURE_FORM);

        let embeddedFormData;

        if (embeddedFormId) {
            embeddedFormData = await Form.initEmbeddableNewForm(embeddedFormId)
                .then((formData) => formData)
                .catch((rejectValue) => rejectValue);
        }

        // Set BG colour and gradient overrides.
        const hexBgColour = predictionData?.background_colour;
        const rgbBgColour = hexBgColour ? hexToRGB(hexBgColour) : null;

        const colourOverrides = {
            hex: hexBgColour,
            rgb: rgbBgColour
        };

        const shadow =
            container.shadowRoot || container.attachShadow({ mode: 'open' });
        render(
            <I18nextProvider i18n={i18nInstance}>
                <Prediction
                    predictionData={predictionData}
                    embeddedFormData={embeddedFormData}
                    isPreview={isPreview}
                    container={container}
                    onPreviewEditChange={onPreviewEditChange}
                    settingsData={settingsData}
                    colourOverrides={colourOverrides}
                />
            </I18nextProvider>,
            shadow
        );
    }
}
