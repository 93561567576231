const SignInIcon = (props) => (
    <svg
        width="24"
        height="24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <g clipPath="url(#a)">
            <path d="M12 21v-2h7V5h-7V3h7c.55 0 1.0208.1958 1.4125.5875C20.8042 3.9792 21 4.45 21 5v14c0 .55-.1958 1.0208-.5875 1.4125C20.0208 20.8042 19.55 21 19 21h-7Zm-2-4-1.375-1.45 2.55-2.55H3v-2h8.175l-2.55-2.55L10 7l5 5-5 5Z" />
        </g>
        <defs>
            <clipPath id="a">
                <path d="M0 0h24v24H0z" />
            </clipPath>
        </defs>
    </svg>
);
export default SignInIcon;
