const CrossIcon = () => (
    <svg width="12" height="12" xmlns="http://www.w3.org/2000/svg">
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M.251.251a.8572.8572 0 0 1 1.2122 0L6 4.7878 10.5368.251a.8572.8572 0 0 1 1.2122 1.2122L7.2122 6l4.5368 4.5368a.8572.8572 0 0 1-1.2122 1.2122L6 7.2122 1.4632 11.749A.8572.8572 0 0 1 .251 10.5368L4.7878 6 .251 1.4632a.8572.8572 0 0 1 0-1.2122Z"
        />
    </svg>
);

export default CrossIcon;
