import { HelperTextPropTypes } from './prop-types';
import styleSheet from '../../../scss/input.module.scss';

/**
 * @param {object} props -
 * @returns {Element} - <HelperTextBottom />
 */
export function HelperTextBottom(props) {
    const styles = styleSheet.locals || {};
    if (
        !props.helperTextPosition?.startsWith('bottom-') ||
        props.customElementBottom
    ) {
        return null;
    }

    const positionClassName =
        props.helperTextPosition === 'bottom-left'
            ? styles.inputHelperTextWrapperBottomLeft
            : styles.inputHelperTextWrapperBottomRight;

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div
                className={`${styles.inputHelperTextWrapper} ${positionClassName}`}
                data-cy={`input-wrapper__helper-text-position--${props.helperTextPosition}`}
            >
                <span
                    className={styles.inputHelperText}
                    data-cy="input-wrapper__helper-text"
                >
                    {props.helperText}
                </span>
            </div>
        </>
    );
}

HelperTextBottom.propTypes = HelperTextPropTypes;
