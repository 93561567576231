import PropTypes from 'prop-types';
import { useState } from 'react';
import classNames from 'classnames';
import styleSheet from './freeText.module.scss';
import { useTranslation } from 'react-i18next';

const FreeTextTriviaInput = ({ onChange, value }) => {
    const styles = styleSheet.locals || {};
    const { t } = useTranslation();

    const [isFocused, setIsFocused] = useState(false);

    const handleFocus = () => {
        setIsFocused(true);
    };

    const handleBlur = () => {
        setIsFocused(false);
    };
    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div className={styles.freetextWrapper}>
                <label
                    htmlFor="freeTextTriviaInput"
                    className={styles.freetextLabel}
                >
                    {t('trivia.answerLabel')}
                </label>
                <input
                    id="freeTextTriviaInput"
                    className={classNames(styles.freetextInput, {
                        [styles.freetextTransparent]: !isFocused
                    })}
                    type="text"
                    onChange={onChange}
                    placeholder={t('trivia.answerPlaceholder')}
                    value={value}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                />
            </div>
        </>
    );
};

FreeTextTriviaInput.propTypes = {
    onChange: PropTypes.func,
    value: PropTypes.string
};

export default FreeTextTriviaInput;
