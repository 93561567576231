import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styleSheet from '../button/scss/button.module.scss';

// helpers
import { getClasses, requireWhiteAccent } from './utils';

const Button = React.forwardRef((props, ref) => {
    const { text, theme, disabled, children, ...otherProps } = props;

    const styles = styleSheet.locals || {};

    // accent colour
    const whiteAccent = requireWhiteAccent(theme);
    // text
    const buttonText = text || '';
    //classes
    const classes = getClasses(props, whiteAccent);

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <button
                ref={ref}
                disabled={disabled}
                {...otherProps}
                className={classNames(
                    styles.btn,
                    classes.className,
                    classes.buttonTheme,
                    classes.buttonSize,
                    classes.loading,
                    classes.noText,
                    classes.displayFlex,
                    classes.buttonIcon
                )}
            >
                {buttonText && (
                    <span className={styles.btnText}>{buttonText}</span>
                )}
                {children}
            </button>
        </>
    );
});

// type validation
Button.propTypes = {
    /**
     * Optional button text. Defaults to ''.
     */
    text: PropTypes.string,

    /**
     * Optional button theme. Defaults to `primary`.
     */
    theme: PropTypes.oneOf(['primary', 'secondary']),

    /**
     * Optional Button size. Defaults to `large`.
     */
    size: PropTypes.oneOf(['large', 'medium']),

    /**
     *  enables/disables Button disabled state
     */
    disabled: PropTypes.bool,

    /**
     *  Optional class name applied to root element
     */
    className: PropTypes.string,

    /**
     * Optional children to allow rendering of nested content
     */
    children: PropTypes.any
};

Button.defaultProps = {
    size: 'large'
};

export default Button;
