/* eslint-disable eqeqeq */
/* eslint-disable no-magic-numbers */
import { useEffect, useState } from 'preact/hooks';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import styleSheet from '../scss/carouselControls.module.scss';
import ChevronLeftIcon from '../../../assets/icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../assets/icons/ChevronRigthIcon';

const styles = styleSheet.locals || {};

/**
 * Component which displays controller of the slider
 *
 * @param {object} props option props
 * @param {object} props.elementRef reference of slider
 * @returns {Function} <CarouselControls />
 */
function CarouselControls({ elementRef }) {
    const isRTL = document.documentElement.lang === 'ar';
    const [leftArrowDisable, setLeftArrowDisable] = useState(true);
    const [rightArrowDisable, setRightArrowDisable] = useState(false);
    const [indexPos, setIndexPos] = useState(0);
    const [indicatorActiveIndex, setIndicatorActiveIndex] = useState(
        isRTL ? 0 : null
    );

    useEffect(() => {
        const elem = elementRef.current;
        if (isRTL) {
            // Initialize arrow status for RTL
            setRightArrowDisable(elem.scrollLeft === 0);
            setLeftArrowDisable(
                elem.offsetWidth + elem.scrollLeft >= elem.scrollWidth
            );
        } else {
            // Initialize arrow status
            setLeftArrowDisable(elem.scrollLeft === 0);
            setRightArrowDisable(
                elem.offsetWidth + elem.scrollLeft >= elem.scrollWidth
            );
        }
        const onScrollEnd = function () {
            // update buttons when scrolling on mobile
            if (!isRTL) {
                // Disabled due to performance errors for RTL
                setLeftArrowDisable(elem.scrollLeft === 0);
                setRightArrowDisable(
                    elem.offsetWidth + elem.scrollLeft >= elem.scrollWidth
                );
            }
        };
        elem.addEventListener('scrollend', onScrollEnd);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef, elementRef.current]);

    /**
     * Calculates how many child items fit into one slide
     *
     * @param {Element} element carousel container element
     * @returns {number} items per slide
     */
    const getItemsPerSlide = (element) => {
        return Math.floor(
            element.offsetWidth / element.childNodes[0].offsetWidth
        );
    };

    /**
     * Depending on scroll direction it calculates the distance
     * it needs to scroll until the next appropriate child item
     *
     * @param {Element} element carousel container element
     * @param {number} direction scroll direction, either 1 or -1
     * @returns {number} scroll distance
     */
    const getScrollDistance = (element, direction) => {
        let distance = 0;
        const itemsPerRow = getItemsPerSlide(element);
        const elemRect = element.getBoundingClientRect();

        if (!isRTL && direction > 0) {
            const limit = element.clientWidth + elemRect.left;
            for (let i = 0; i < element.childNodes.length; i++) {
                const child = element.childNodes[i];
                const rect = child.getBoundingClientRect();
                if (direction > 0 && rect.right > limit) {
                    distance = rect.left - elemRect.left - 4;
                    setIndexPos(i);
                    break;
                }
            }
        } else {
            let target;
            if (element.childNodes[indexPos - itemsPerRow]) {
                target = element.childNodes[indexPos - itemsPerRow];
                setIndexPos(indexPos - itemsPerRow);
            } else {
                target = element.childNodes[0];
                setIndexPos(0);
            }
            if (isRTL && direction > 0) {
                const targetRect = element.getBoundingClientRect();
                distance = Math.abs(targetRect.left - elemRect.right);
            } else {
                const targetRect = target.getBoundingClientRect();
                distance = Math.abs(targetRect.left - elemRect.left);
            }
        }
        return distance * direction;
    };

    const handleHorizantalScroll = (element, direction) => {
        if (!element) {
            return;
        }

        if (indicatorActiveIndex !== null) {
            setIndicatorActiveIndex(indicatorActiveIndex - direction);
        }

        const distance = getScrollDistance(element, direction);

        element.scrollBy({ left: distance, behavior: 'smooth' });

        if (isRTL) {
            const maxScrollActions = Math.ceil(
                element.scrollWidth / element.clientWidth
            );
            setLeftArrowDisable(
                direction < 0 && indicatorActiveIndex == maxScrollActions - 2
            );
            setRightArrowDisable(direction > 0 && indicatorActiveIndex == 1);
        } else {
            setLeftArrowDisable(element.scrollLeft + distance <= 0);
            setRightArrowDisable(
                element.scrollLeft + distance + element.clientWidth >=
                    element.scrollWidth
            );
        }
    };

    const getSliderIndicators = (element) => {
        const sliderItems = [];
        const itemsPerRow = element && getItemsPerSlide(element);
        const numItems = element
            ? Math.ceil(element.scrollWidth / element.clientWidth)
            : null;
        for (let index = 0; index < numItems; index++) {
            sliderItems[index] = itemsPerRow * index;
        }
        return sliderItems;
    };

    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div className={styles.carouselControlsHeader}>
                {/* <p className={styles.carouselControlsText}>
                Swipe or use the buttons to see more options
            </p> */}

                <ol className={styles.carouselControlsSlider}>
                    {getSliderIndicators(elementRef.current)?.map(
                        (item, index) => (
                            <li
                                key={index}
                                className={classNames(
                                    styles.carouselControlsSliderIndicator,
                                    {
                                        [styles.carouselControlsSliderIndicatorActive]:
                                            isRTL
                                                ? index === indicatorActiveIndex
                                                : item === indexPos
                                    }
                                )}
                            />
                        )
                    )}
                </ol>
                <div dir="ltr" className={styles.carouselControlsContent}>
                    <button
                        className={styles.carouselControlsButton}
                        onClick={() => {
                            handleHorizantalScroll(elementRef.current, -1);
                        }}
                        disabled={leftArrowDisable}
                    >
                        <ChevronLeftIcon />
                    </button>
                    <button
                        className={styles.carouselControlsButton}
                        onClick={() => {
                            handleHorizantalScroll(elementRef.current, 1);
                        }}
                        disabled={rightArrowDisable}
                    >
                        <ChevronRightIcon />
                    </button>
                </div>
            </div>
        </>
    );
}

CarouselControls.propTypes = {
    elementRef: PropTypes.object.isRequired
};

export { CarouselControls };
