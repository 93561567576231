import styleSheet from './styles/gtg.module.scss';

import { useContext } from 'preact/hooks';
import { PollContext } from '../gtg.component';

import Loading from '../../components/Loading/Loading.component';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for the GTG button to open the model
 *
 * @returns {Function} <GTGSubmitButton />
 */
function GTGSubmitButton() {
    const { t } = useTranslation();
    const { onVoteSubmit, submittingVote } = useContext(PollContext);
    return (
        <button className={styles.gtgSubmit} onClick={onVoteSubmit}>
            {submittingVote ? <Loading size={14} /> : t('gtg.saveButton')}
        </button>
    );
}

export default GTGSubmitButton;
