import { useContext, useEffect } from 'preact/hooks';
import { createRef } from 'preact';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import { POLL_TYPES } from '../../helpers/constants';
import ShareButton from '../Share/ShareButton.component';

import styleSheet from './poll.module.scss';

import { enableEditableContent } from '../../helpers/editable-content';
import { PollContext } from '../../polls/poll.component';

import QuestionIcon from '../shared-components/icons/QuestionIcon';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for the poll header
 *
 * @param {object} props poll option props
 * @param {object} props.poll poll option data
 * @param {boolean} props.pollScheduled if poll is scheduled
 * @returns {Function} <PollHeader />
 */
function PollHeader({ poll, pollScheduled = false }) {
    const { t } = useTranslation();
    const pollContext = useContext(PollContext);
    const question = poll.questions[0];
    const editableAttr = pollContext.isPreview ? { contentEditable: true } : {};
    const titleRef = createRef();
    const descrRef = createRef();

    const isTrivia =
        (poll?.type === POLL_TYPES.TRIVIA ||
            poll?.type === POLL_TYPES.FREE_TEXT) ??
        false;

    const changeEditableData = (field, value) => {
        pollContext.onPreviewEditChange?.({
            [field]: value,
            questions: [
                {
                    [field]: value,
                    options: []
                }
            ]
        });
    };

    useEffect(() => {
        if (pollContext.isPreview) {
            enableEditableContent(titleRef.current, 'text', changeEditableData);
            enableEditableContent(
                descrRef.current,
                'description',
                changeEditableData
            );
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className={classNames(styles.pollHeader, {
                [styles.pollHeaderScheduled]: pollScheduled && !isTrivia
            })}
        >
            <div
                className={classNames(styles.pollPrompt, {
                    [styles.pollPromptTrivia]: pollScheduled && isTrivia
                })}
            >
                {isTrivia && (
                    <div className={styles.pollProductLabel}>
                        <span data-cy="QuestionIcon">
                            <QuestionIcon />
                        </span>

                        <span className={styles.pollProduct}>
                            {t('trivia.productName').toUpperCase()}
                        </span>
                    </div>
                )}

                <span
                    className={classNames(styles.pollQuestion, {
                        [styles.pollQuestionTrivia]: pollScheduled && isTrivia
                    })}
                    ref={titleRef}
                    data-fek-preview="text"
                    {...editableAttr}
                >
                    {question.text}
                </span>
                <div
                    className={classNames(styles.pollDescription, {
                        [styles.pollDescriptionTrivia]:
                            pollScheduled && isTrivia
                    })}
                    ref={descrRef}
                    data-fek-preview="description"
                    {...editableAttr}
                >
                    {question.description}
                </div>
            </div>

            {!pollScheduled && (
                <ShareButton
                    pollId={isTrivia ? poll.trivia_id : poll.poll_id}
                />
            )}
        </div>
    );
}

PollHeader.propTypes = {
    poll: PropTypes.object.isRequired,
    pollScheduled: PropTypes.bool
};

export default PollHeader;
