import { InputWrapper, InputWrapperPublicPropTypes } from '../input-wrapper';
import { InputPropTypes, useInput } from '../hooks/useInput';

export const NumberInput = (props) => {
    const { inputProps, inputWrapperProps } = useInput(props);
    return (
        <InputWrapper {...inputWrapperProps}>
            <input
                type="tel"
                inputMode="numeric"
                {...inputProps}
                data-cy="number-input"
                data-testid="number-input"
                className={inputProps.className}
                maxLength={15}
            />
        </InputWrapper>
    );
};

NumberInput.propTypes = {
    ...InputPropTypes,
    ...InputWrapperPublicPropTypes
};
