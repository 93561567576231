const LoadingIcon = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="5.33 5.33 13.34 13.33"
        {...props}
    >
        <g fill="none" fillRule="evenodd">
            <path
                d="M0 0H24V24H0z"
                transform="translate(-1050 -511) translate(981 503) translate(69 8)"
            />
            <path
                fill="currentColor"
                d="M18.64 11.367c.387 4.173-3.093 7.653-7.267 7.266-2.953-.273-5.413-2.566-5.933-5.486-.173-.947-.127-1.854.08-2.694.093-.386.507-.6.873-.466.314.113.5.446.42.773-.133.547-.18 1.133-.126 1.74.246 2.693 2.593 4.833 5.3 4.84 3.193.007 5.74-2.807 5.293-6.08-.34-2.467-2.44-4.413-4.927-4.573-.553-.04-1.086.013-1.586.133-.327.073-.66-.107-.774-.427-.133-.373.08-.78.46-.873 1.194-.287 2.5-.273 3.854.247 2.373.9 4.1 3.08 4.333 5.6z"
                transform="translate(-1050 -511) translate(981 503) translate(69 8)"
            />
        </g>
    </svg>
);

export default LoadingIcon;
