/**
 * given a base class and a key param return a string
 * used as a key to access the styles object
 *
 * @param {object} styles styles object
 * @param {object} map prefix map
 * @param {object} key map key
 * @param {string} defaultKey map key
 * @param {string} baseSelector class to append modifier. lg, md and sm are supported
 * @returns {string} hashed class
 */
export const getMappedClass = (
    styles,
    map,
    key,
    defaultKey,
    baseSelector = ''
) => {
    const prefix = map[key];
    const defaultPrefix = map[defaultKey];
    const selector = `${baseSelector}${prefix}`;
    const defaultSelector = `${baseSelector}${defaultPrefix}`;
    return getClass(styles, selector, defaultSelector);
};

/**
 * given a class name return the hashed class from the styles object
 *
 * @param {object} styles styles object
 * @param {string} selector to get from the styles object
 * @param {string} defaultSelector optional default selector
 * @returns {string} hashed class
 */
export const getClass = (styles, selector, defaultSelector) => {
    return selector && styles[selector]
        ? styles[selector]
        : styles[defaultSelector] || '';
};
