import FingerprintJS from '@fingerprintjs/fingerprintjs';

const componentsToString = (components) => {
    let result = '';
    for (const componentKey of Object.keys(components).sort()) {
        const component = components[componentKey];
        const value = component.error
            ? 'error'
            : JSON.stringify(component.value);
        result += `${result ? '|' : ''}${componentKey.replace(
            /([:|\\])/g,
            '\\$1'
        )}:${value}`;
    }
    return result;
};

/**
 * Fingerprint as Hash
 *
 * Generates the user's fingerprint and then hashes it using murmur hash 3 (x64)
 * algo.
 *
 * Warning: This function should not be run immediately at page load. The
 * fingerprinting lib looks at browser attributes to generate the fingerprint.
 * Running this fn too soon will create inconsistent fingerprints. Ideally this
 * fn is run when the user's browser is idle.
 *
 * @returns {Promise<string>} - the user's fingerprint as a hash.
 */
export async function fingerprintAsHash() {
    const fpPromise = FingerprintJS.load();
    const fp = await fpPromise;
    const result = await fp.get();

    const components = componentsToString(result.components);
    const fingerprint = result.visitorId;

    return {
        fingerprint,
        entropyComponents: btoa(components)
    };
}
