import styleSheet from './styles/gtg.module.scss';

import { useContext } from 'preact/hooks';
import { PollContext } from '../gtg.component';

import GTGMatch from './GTGMatch';
import Countdown from '../../components/Countdown/Countdown.component';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for the GTG Vote Screen
 *
 * @returns {Function} <GTGResultsScreen />
 */
function GTGResultsScreen() {
    const { t } = useTranslation();
    const { poll, pollOpen, onCountdownEnd } = useContext(PollContext);

    return (
        <div className={styles.gtgVoting}>
            <div className={styles.gtgVotingTitle}>{poll.text}</div>
            <ul className={styles.gtgVotingList}>
                {poll.questions.map((question) => {
                    const totalVotes = question.options.reduce((prev, next) => {
                        return prev + next.times_selected;
                    }, 0);
                    return (
                        <li
                            className={styles.gtgVotingListItem}
                            key={question.question_id}
                        >
                            <GTGMatch
                                question={question}
                                showResults={true}
                                totalVotes={totalVotes}
                            />
                        </li>
                    );
                })}
            </ul>

            {poll.state === 'closed' && (
                <p className={styles.gtgVotingClosed}>{t('gtg.closed')}</p>
            )}

            {pollOpen && poll.closes_at && (
                <Countdown
                    text={t('gtg.closesIn')}
                    datetime={poll.closes_at}
                    onCountdownEnd={onCountdownEnd}
                    closes={true}
                    lightTheme={true}
                />
            )}
        </div>
    );
}

export default GTGResultsScreen;
