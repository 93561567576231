/**
 * Converts an index into a corresponding letter (A, B, C, etc.).
 *
 * @param {number} index - The index to convert into a letter.
 * @returns {string} - The letter corresponding to the index.
 */
export const getLetterForOption = (index) => {
    const isArabic = document.documentElement.lang === 'ar';
    // eslint-disable-next-line no-magic-numbers
    const firstAlphabetLetter = isArabic ? 0x0627 : 65;
    // ASCII code for 'A' is 65. Adding the index shifts the ASCII value to the next letter.
    return String.fromCodePoint(firstAlphabetLetter + index).toUpperCase();
};
