import PropTypes from 'prop-types';
import classNames from 'classnames';

import { RESULTS_VISIBILITY } from '../../polls/poll.helpers';
import styleSheet from './poll.module.scss';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for a single poll option
 *
 * @param {object} props poll option props
 * @param {string} props.resultsVisibility poll results visibility settings
 * @param {object} props.transitionStyles transition style states
 * @param {string} props.transitionState transition state
 * @param {boolean} props.isTrivia trivia mode
 * @returns {Function} <PollClosedScreen />
 */
function PollClosedScreen({
    resultsVisibility,
    transitionStyles,
    transitionState,
    isTrivia
}) {
    const { t } = useTranslation();
    return (
        <div
            className={classNames(styles.pollClosedScreen, {
                [styles.pollClosedNoResults]:
                    resultsVisibility === RESULTS_VISIBILITY.NEVER
            })}
            style={{
                ...transitionStyles[transitionState]
            }}
        >
            <p>{t(`${isTrivia ? 'trivia' : 'polling'}.nowClosed`)}</p>
        </div>
    );
}

PollClosedScreen.propTypes = {
    resultsVisibility: PropTypes.string.isRequired,
    transitionStyles: PropTypes.object.isRequired,
    transitionState: PropTypes.string.isRequired,
    isTrivia: PropTypes.bool
};

export default PollClosedScreen;
