/* eslint-disable no-empty-function */

/**
 * Noop (No Operation)
 *
 * Empty function that can be used when we need to do no operation
 */
const noop = () => {};

export { noop };
