const ExternalLinkIcon = (props) => (
    <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M16 16.6667H8C7.63333 16.6667 7.33333 16.3667 7.33333 16V8C7.33333 7.63333 7.63333 7.33333 8 7.33333H11.3333C11.7 7.33333 12 7.03333 12 6.66667C12 6.3 11.7 6 11.3333 6H7.33333C6.59333 6 6 6.6 6 7.33333V16.6667C6 17.4 6.6 18 7.33333 18H16.6667C17.4 18 18 17.4 18 16.6667V12.6667C18 12.3 17.7 12 17.3333 12C16.9667 12 16.6667 12.3 16.6667 12.6667V16C16.6667 16.3667 16.3667 16.6667 16 16.6667ZM13.3333 6.66667C13.3333 7.03333 13.6333 7.33333 14 7.33333H15.7267L9.64 13.42C9.38 13.68 9.38 14.1 9.64 14.36C9.9 14.62 10.32 14.62 10.58 14.36L16.6667 8.27333V10C16.6667 10.3667 16.9667 10.6667 17.3333 10.6667C17.7 10.6667 18 10.3667 18 10V6H14C13.6333 6 13.3333 6.3 13.3333 6.66667Z" />
    </svg>
);

export default ExternalLinkIcon;
