import PropTypes from 'prop-types';

import Countdown from '../Countdown/Countdown.component';
import { POLL_TYPES } from '../../helpers/constants';
import styleSheet from './poll.module.scss';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for an unopened/scheduled poll
 *
 * @param {object} props poll option props
 * @param {object} props.poll poll option data
 * @param {boolean} props.pollScheduled if poll is scheduled
 * @param {Function} props.onCountdownEnd callback when counter reaches 0
 * @returns {Function} <PollScheduledScreen />
 */
function PollScheduledScreen({ poll, pollScheduled = false, onCountdownEnd }) {
    const { t } = useTranslation();
    const isTrivia =
        (poll?.type === POLL_TYPES.TRIVIA ||
            poll?.type === POLL_TYPES.FREE_TEXT) ??
        false;

    return (
        <div className={styles.pollScheduledText}>
            {pollScheduled && poll.opens_at ? (
                <Countdown
                    text={t(`${isTrivia ? 'trivia' : 'polling'}.opensIn`)}
                    datetime={poll.opens_at}
                    onCountdownEnd={onCountdownEnd}
                />
            ) : (
                t(`${isTrivia ? 'trivia' : 'polling'}.notOpen`)
            )}
        </div>
    );
}

PollScheduledScreen.propTypes = {
    poll: PropTypes.object.isRequired,
    pollScheduled: PropTypes.bool,
    onCountdownEnd: PropTypes.func.isRequired
};

export default PollScheduledScreen;
