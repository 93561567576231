import { render } from 'preact';

import { namespace } from '../helpers/namespaces';
import { determineTenant, getClientHost } from '../helpers/client';
import GuessTheGameweek from './gtg.component';
import { I18nextProvider } from 'react-i18next';
import i18nInstance from '../i18n';

const ID_DATA_ATTRIBUTE = `data-${namespace}predictor`;
const INIT_ATTR = 'data-fek-initialised';
const TENANT_DATA_ATTRIBUTE = `data-${namespace}tenant`;
const NO_INIT_SELECTOR = `:not([${INIT_ATTR}])`;

/**
 * Class which goes through page and finds Playbook predictors to embed
 */
export default class GuessTheGameweekGames {
    constructor() {
        this.init();
        this.observeDOM();
    }

    init() {
        document
            .querySelectorAll(`[${ID_DATA_ATTRIBUTE}]${NO_INIT_SELECTOR}`)
            .forEach(this.initNewEmbed.bind(this));
    }

    initNewEmbed(embed) {
        const embedTenant = embed.getAttribute(TENANT_DATA_ATTRIBUTE);

        determineTenant(embedTenant);
        const embedId = embed.getAttribute(ID_DATA_ATTRIBUTE);
        embed.setAttribute(INIT_ATTR, '');
        this.getPollData(embed, embedId);
    }

    /**
     * Sets up a MutationObserver to check the DOM
     * if new poll embeds have been dynamically added
     */
    observeDOM() {
        const observer = new MutationObserver((mutationList) => {
            mutationList.forEach((mutation) => {
                mutation.addedNodes.forEach((node) => {
                    if (node.nodeType === Node.ELEMENT_NODE) {
                        const embed = node.querySelector(
                            `[${ID_DATA_ATTRIBUTE}]${NO_INIT_SELECTOR}`
                        );
                        if (embed) {
                            this.initNewPoll(embed);
                        }
                    }
                });
            });
        });

        observer.observe(document.body, { subtree: true, childList: true });
    }

    /**
     * Fetches poll data from API
     *
     * @param {Element} container - embed container
     * @param {string} pollId - Playbook poll ID
     */
    getPollData(container, pollId) {
        const endpoint = `https://${getClientHost()}${
            process.env.API_PATH
        }/polls/${pollId}`;

        fetch(endpoint)
            .then((response) => {
                if (!response.ok) {
                    console.warn('Playbook GTG embed unsuccessful', pollId);
                    return null;
                }

                return response.json();
            })
            .then((data) => {
                if (!data) {
                    return;
                }
                this.renderEmbed(container, data, false);
            })
            .catch((error) => {
                console.warn('FEK poll embed unsuccessful', error);
            });
    }

    /**
     * Renders embeds to DOM
     *
     * @param {Element} container - embed container
     * @param {object} data - poll data
     */
    async renderEmbed(container, data) {
        if (data.state === 'draft' || data.state === 'scheduled') {
            return;
        }
        /**
         * Used to fetch poll data to get latest vote data
         *
         * @returns {Promise} fetch promise
         */
        const refetch = () => {
            return fetch(
                `https://${getClientHost()}${process.env.API_PATH}/polls/${
                    data.poll_id
                }`
            ).then((response) => response.json());
        };
        const expFeatureFlags = window.PLAYBOOK_CONFIG?.features || {};
        const shadow =
            container.shadowRoot || container.attachShadow({ mode: 'open' });
        render(
            <I18nextProvider i18n={i18nInstance}>
                <GuessTheGameweek
                    pollData={data}
                    container={container}
                    refetch={refetch}
                    isPreview={false}
                    expFeatureFlags={expFeatureFlags}
                />
            </I18nextProvider>,
            shadow
        );
    }
}
