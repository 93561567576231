import PropTypes from 'prop-types';
import classNames from 'classnames';

import styleSheet from './pollTotalVotes.module.scss';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for showing a poll
 *
 * @param {object} props props
 * @param {object} props.votes number of total votes
 * @param {object} props.margin if a styling margin should be added
 * @param {object} props.isTrivia is it a trivia
 * @returns {Function} <PollTotalVotes />
 */
const PollTotalVotes = function ({
    votes,
    margin = false,
    isTrivia = false,
    ...props
}) {
    const { t } = useTranslation();
    const cls = classNames(styles.totalVotes, {
        [styles.totalVotesMargin]: margin
    });
    return (
        <div className={cls} style={props.style || {}}>
            <style>{styleSheet.toString()}</style>
            <div
                data-cy="poll-total-votes"
                className={styles.totalVotesCounter}
                {...props}
            >
                {t(isTrivia ? 'trivia.totalVotes' : 'polling.totalVotes')}
                {': '}
                {votes}
            </div>
        </div>
    );
};

PollTotalVotes.propTypes = {
    votes: PropTypes.number.isRequired,
    margin: PropTypes.bool,
    style: PropTypes.object,
    isTrivia: PropTypes.bool
};

export default PollTotalVotes;
