/* eslint-disable id-length */
import PropTypes from 'prop-types';
import styleSheet from '../../scss/input.module.scss';
import { HelperTextTop, Label } from '.';
import { HelperTextPropTypes } from './helper-text/prop-types';

/**
 * @param {object} props -
 * @returns {Element} -
 */
export function InputWrapperAbove(props) {
    const styles = styleSheet.locals || {};
    return (
        <>
            <style>{styleSheet.toString()}</style>
            <div className={styles.inputHelperTextWrapper}>
                <Label {...props} />

                {props.customElementTop ? (
                    props.customElementTop
                ) : (
                    <HelperTextTop
                        helperText={props.helperText}
                        helperTextPosition={props.helperTextPosition}
                    />
                )}
            </div>
        </>
    );
}

InputWrapperAbove.propTypes = {
    // ...LabelPropTypes,
    customElementTop: PropTypes.node,
    ...HelperTextPropTypes
};
