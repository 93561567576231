import { getClientHost } from '../helpers/client';

/**
 * GET /predictions/{prediction_id}
 * Fetches Playbook Prediction data from Fan API
 *
 * @param {string} predictionId - Playbook Prediction ID
 * @returns {Promise} -
 */
export const getPredictionData = (predictionId) => {
    const PREDICTIONS_HOST = `${getClientHost()}${
        process.env.API_PATH_PREDICTIONS
    }`;

    const endpoint = `https://${PREDICTIONS_HOST}/predictions/${predictionId}`;

    return new Promise((resolve, reject) => {
        fetch(endpoint)
            .then((response) => {
                if (!response.ok) {
                    console.warn(
                        'Playbook - Prediction embed unsuccessful',
                        predictionId
                    );
                    return null;
                }

                return response.json();
            })
            .then((predictionData) => {
                resolve(predictionData);
            })
            .catch((error) => {
                console.error(
                    'Playbook - Prediction embed unsuccessful',
                    error
                );
                reject(error);
            });
    });
};

/**
 * POST /predictions/{prediction_id}/questions/{question_id}/answers
 * Submit an answer to a prediction
 *
 * @param {string} predictionId - prediction
 * @param {string} questionId - prediction
 * @param {string} payload - answer
 * @param {string} isGated - should it be gated
 * @param {string} authHeader - auth header
 * @returns {Promise} -
 */
export const sendAnswer = (
    predictionId,
    questionId,
    payload,
    isGated,
    authHeader
) => {
    const PREDICTIONS_HOST = `${getClientHost()}${
        process.env.API_PATH_PREDICTIONS
    }`;

    const endpoint = isGated
        ? `https://${PREDICTIONS_HOST}/gated/predictions/${predictionId}/questions/${questionId}/answers`
        : `https://${PREDICTIONS_HOST}/predictions/${predictionId}/questions/${questionId}/answers`;

    const headers = {
        'Content-Type': 'application/json'
    };

    if (isGated && authHeader) {
        headers.Authorization = authHeader;
    }

    return new Promise((resolve, reject) => {
        fetch(endpoint, {
            method: 'POST',
            headers,
            body: JSON.stringify(payload)
        })
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (
                    response.status &&
                    !response.status.toString().match(/2\d{2}/g)
                ) {
                    // Non sucessful response
                    reject(response);
                    return;
                }
                resolve(response);
            })
            .catch((error) => {
                console.error(
                    'Playbook - Prediction submission answer unsuccessful.',
                    error
                );
                reject(error);
            });
    });
};
