import { useContext } from 'preact/hooks';
import PropTypes from 'prop-types';

import ThumbIcon from '../shared-components/icons/ThumbIcon';
import WinnerIcon from '../shared-components/icons/WinnerIcon';

import styleSheet from '../Polling/poll.module.scss';
import { PollContext } from '../../polls/poll.component';
import { useTranslation } from 'react-i18next';

const styles = styleSheet.locals || {};

/**
 * Component for a single poll option
 *
 * @param {object} props poll option props
 * @param {object} props.option poll option data
 * @param {boolean} props.showingResults poll is showing results
 * @param {object} props.transitionStyles transition styles
 * @param {string} props.transitionState transition state
 * @param {number} props.optionsVisibleDelay delay for visibility transition
 * @param {boolean} props.isSelected if option is selected by user
 * @param {boolean} props.isAnswered if option has been chosen as an answer by user
 * @param {object} props.optionTextRef option text node reference
 * @returns {Function} <PollH2HTextOption />
 */
function PollH2HTextOption({
    option,
    showingResults = false,
    transitionStyles,
    transitionState,
    optionsVisibleDelay,
    isSelected = false,
    isAnswered = false,
    optionTextRef
}) {
    const { t } = useTranslation();
    const { poll, isPreview, showVotesCounter } = useContext(PollContext);
    const editableAttr = isPreview ? { contentEditable: true } : {};
    const barTransitionStyles = {
        entered: { maxWidth: '100%' }
    };
    const winnerTransitionStyles = {
        entered: { opacity: 1, transform: 'scale(1)' }
    };

    return (
        <div className={styles.pollOptionText}>
            {isSelected || isAnswered ? (
                <ThumbIcon className={styles.pollThumbIcon} />
            ) : (
                <div />
            )}
            {showingResults && option.times_selected !== null && (
                <div
                    className={styles.pollOptionBar}
                    style={{
                        width: `${option.percentage}%`,
                        ...barTransitionStyles[transitionState],
                        transitionDelay: `${optionsVisibleDelay}ms`
                    }}
                />
            )}
            <div className={styles.pollOptionLabelContainer}>
                <span
                    className={styles.pollOptionLabel}
                    {...editableAttr}
                    ref={optionTextRef}
                >
                    {option.text}
                </span>
                {showingResults && option.times_selected !== null && (
                    <div
                        className={styles.pollOptionData}
                        style={{
                            ...transitionStyles[transitionState],
                            transitionDelay: `${optionsVisibleDelay}ms`
                        }}
                    >
                        {option.winner && poll?.state === 'closed' && (
                            <WinnerIcon
                                className={styles.pollOptionWinnerIcon}
                                style={winnerTransitionStyles[transitionState]}
                            />
                        )}
                        <div className={styles.pollOptionDataText}>
                            <div className={styles.pollOptionPercentage}>
                                {option.percentage}%
                            </div>
                            {showVotesCounter && (
                                <div className={styles.pollOptionCounter}>
                                    {option.times_selected} {t('common.votes')}
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

PollH2HTextOption.propTypes = {
    option: PropTypes.object.isRequired,
    showingResults: PropTypes.bool,
    transitionStyles: PropTypes.object,
    transitionState: PropTypes.string,
    optionsVisibleDelay: PropTypes.number,
    isSelected: PropTypes.bool,
    isAnswered: PropTypes.bool,
    optionTextRef: PropTypes.object
};

export default PollH2HTextOption;
