/* eslint-disable complexity */

const isProdTenantLinkedToTestExp = () => {
    if (window.location.host.includes('ecb.test')) {
        return true;
    }

    return false;
};

export const getClientHost = () => {
    return `${window.PLAYBOOK_CONFIG.tenant}${
        isProdTenantLinkedToTestExp() ? '' : process.env.ENV_SUBDOMAIN
    }.fek.pulselive.com`;
};

export const determineTenant = (embedTenant = false) => {
    if (window.PLAYBOOK_CONFIG?.tenant) {
        return;
    }

    if (!window.PLAYBOOK_CONFIG) {
        window.PLAYBOOK_CONFIG = {};
    }

    if (
        window.location.host.includes('.dev') ||
        window.location.host.includes('dev.wta') ||
        window.location.host.includes('localhost')
    ) {
        window.PLAYBOOK_CONFIG.tenant = 'pulselive-dev';
        return;
    }

    // special case for wta while they update embed codes
    if (window.location.host.includes('wta')) {
        window.PLAYBOOK_CONFIG.tenant = 'wta';
        return;
    }

    if (embedTenant) {
        window.PLAYBOOK_CONFIG.tenant = embedTenant;
        return;
    }

    if (
        window.location.host.includes('widget-showcase') ||
        window.location.host.includes('web2.demo.pulselive') ||
        window.location.host.includes('web1.demo.pulselive')
    ) {
        window.PLAYBOOK_CONFIG.tenant = 'pulselive-prod';
        return;
    }
};
