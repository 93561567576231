/* eslint-disable id-length */
/* eslint-disable no-param-reassign */
/* eslint-disable no-magic-numbers */

/**
 * Converts a hexadecimal color code to an RGB string.
 *
 * @param {string} hex - The hexadecimal color code, starting with a '#' followed by six hexadecimal digits.
 * @returns {string} The RGB representation of the color in the format "r, g, b".
 *
 * @example
 * // Returns "255, 0, 0"
 * hexToRGB("#FF0000");
 */
export function hexToRGB(hex) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return `${r}, ${g}, ${b}`;
}
