/**
 * Gets the ODIR image URL with the correct
 * width & tenant params
 *
 * @param {string} imgUrl image base URL
 * @param {number} [width] image width
 * @returns {string} odir image URL with width and tenant
 */
export const getImageUrl = (imgUrl, width = '') => {
    if (imgUrl?.includes('blob:')) {
        return imgUrl;
    }

    const tenant = window?.PLAYBOOK_CONFIG?.tenant
        ? window.PLAYBOOK_CONFIG.tenant
        : window?.PLAYBOOK_TENANT;

    return `${imgUrl}?width=${width}&tenant=${tenant}`;
};
