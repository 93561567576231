import PropTypes from 'prop-types';

import styleSheet from './h2h.module.scss';

const styles = styleSheet.locals || {};

/**
 * Component which allows fan to vote for an option
 *
 * @param {object} props poll option props
 * @param {Array} props.options poll options
 * @returns {any} <OptionAttributes />
 */
function OptionAttributes({ options }) {
    return (
        <div className={styles.h2HStats}>
            {options[0].option_attributes?.map((attribute, index) => {
                // Check if the attribute.internal is true, if so return null to render nothing
                if (attribute.internal) {
                    return null;
                }

                return (
                    <div
                        className={styles.h2HStatsRow}
                        key={`attribute-${index}`}
                    >
                        <div className={styles.h2HStatsValue}>
                            {options[0].option_attributes[index]?.value || ''}
                        </div>
                        <div className={styles.h2HStatsLabel}>
                            {attribute.name}
                        </div>
                        <div className={styles.h2HStatsValue}>
                            {options[1].option_attributes[index]?.value || ''}
                        </div>
                    </div>
                );
            })}
        </div>
    );
}

OptionAttributes.propTypes = {
    options: PropTypes.array.isRequired
};

export default OptionAttributes;
